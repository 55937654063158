import { zodResolver } from "@hookform/resolvers/zod";
import { Fingerprint, Mail } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Layout } from "../../pages-v2/";
import { Button } from "../../ui-components/Button";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { LucideIcon } from "../../ui-components/LucideIcon";
import supabase from "../../utils/supabase";

const resetPasswordSchema = z.object({
  email: z.string().email(),
});

export default function ForgotPassword() {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const form = useForm<z.infer<typeof resetPasswordSchema>>({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = form;

  const { email } = form.watch();

  const onSubmit = async (values: z.infer<typeof resetPasswordSchema>) => {
    supabase.auth.resetPasswordForEmail(values?.email);

    setIsEmailSent(true);
  };

  if (isEmailSent) {
    return (
      <Layout>
        <div className="h-full grid place-items-center">
          <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
            <Button
              className="pointer-events-none h-12 w-12 p-0"
              variant="outline"
            >
              <Fingerprint size={20} />
            </Button>
            <p className="w-[200px] md:w-[350px] text-sm">
              We've sent you an email with instructions on how to reset your
              password. Kindly check your inbox.
            </p>
            <DropdownMenuSeparator />
            <Button onClick={() => setIsEmailSent(false)} className="gap-2">
              <Mail size={14} />
              Try another email
            </Button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full grid place-items-center"
      >
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-4">
            <Button
              className="pointer-events-none h-12 w-12 p-0"
              variant="outline"
            >
              <Fingerprint size={20} />
            </Button>
            <div className="flex flex-col gap-y-1">
              <h2 className="font-medium">Reset password</h2>
              <p className="text-sm max-w-[420px]">
                No worries! We'll send you instructions to reset your password
                in an email
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="Your email"
              value={email}
              name="email"
              {...register("email", { required: true })}
              size={36}
            />
          </div>
          <DropdownMenuSeparator />
          <Button type="submit" className="gap-2" loading={isSubmitting}>
            <LucideIcon icon={Mail} size={14} className="stroke-white" />
            Send an email
          </Button>
        </div>
      </form>
    </Layout>
  );
}
