import { useHistory } from "react-router-dom";
import supabase from "../../utils/supabase";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { AvatarSelect } from "../../components/visual/AvatarSelect";
import { Layout } from "../../pages-v2";
import { useSupabaseSession } from "../../pages-v2/hooks/queries/useSupabaseSession";
import { Button } from "../../ui-components/Button";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { DefaultAvatar } from "../../utils/constants";

// the list of all animal and bird emojis
export const avatarEmojis = [
  "🐶",
  "🐱",
  "🐭",
  "🐹",
  "🐰",
  "🦊",
  "🐻",
  "🐼",
  "🐻‍❄️",
  "🐨",
  "🐯",
  "🦁",
  "🐮",
  "🐷",
  "🐽",
  "🐸",
  "🐵",
  "🙈",
  "🙉",
  "🙊",
  "🐒",
  "🐔",
  "🐧",
  "🐦",
  "🐤",
  "🐣",
  "🐥",
  "🦆",
  "🦅",
  "🦉",
  "🦇",
  "🐺",
  "🐗",
  "🐴",
  "🦄",
  "🐝",
  "🪱",
  "🐛",
  "🦋",
  "🐌",
  "🐞",
  "🐜",
  "🪰",
  "🪲",
  "🪳",
  "🦟",
];

const createProfileSchema = z.object({
  username: z.string().min(3).max(50),
  name: z.string().min(3).max(50),
  avatar: z.string().default(DefaultAvatar),
});

const CreateProfile = () => {
  const history = useHistory();

  const { data: session, isLoading: isLoadingSession } = useSupabaseSession();

  const form = useForm<z.infer<typeof createProfileSchema>>({
    resolver: zodResolver(createProfileSchema),
    defaultValues: {
      username: session?.session?.user?.user_metadata?.username || ``,
      name: "",
      avatar: DefaultAvatar,
    },
  });

  const { handleSubmit, register, setValue } = form;

  const { username, name, avatar } = form.watch();

  if (isLoadingSession) {
    return (
      <Layout>
        <div className="h-full grid place-items-center">
          <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-1">
              <h2 className="font-medium">Loading your profile</h2>
              <p className="text-sm">
                We're almost there, just a few more details 👋
              </p>
            </div>
            <ProfileLoader />
          </div>
        </div>
      </Layout>
    );
  }

  const onSubmit = async (values: z.infer<typeof createProfileSchema>) => {
    const { username, name, avatar } = values;

    const { data: usersWithUsername, error: userNameCheckError } =
      await supabase.from("users").select().eq("username", username);

    if (userNameCheckError) {
      toast.error(
        `Sorry, a username with this name already exists, please try another one`,
        {
          duration: 5000,
        }
      );

      return;
    }

    if (usersWithUsername.length > 0) {
      toast.error(
        `Sorry, a username with this name already exists, please try another one`,
        {
          duration: 5000,
        }
      );

      return;
    }

    // insert into users table and then push to login.
    const { error: insertError } = await supabase.from("users").insert({
      id: session?.session?.user?.id,
      username,
      name,
      avatar,
    });

    if (insertError) {
      toast.error(
        `We're uable to process the request at the moment, please try again later`,
        {
          duration: 5000,
        }
      );

      return;
    }

    toast.success(
      `Your profile has been created successfully, please login to continue`,
      {
        duration: 5000,
      }
    );

    history.push("/login");
  };

  if (isLoadingSession) {
    return (
      <Layout>
        <div className="h-full grid place-items-center">
          <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
            <div className="flex flex-col gap-y-1">
              <h2 className="font-medium">Loading your profile</h2>
              <p className="text-sm">
                We're almost there, just a few more details 👋
              </p>
            </div>
            <ProfileLoader />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full grid place-items-center"
      >
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium">Create your profile</h2>
            <p className="text-sm">
              We're almost there. just a few more details 👋
            </p>
          </div>
          <div className="flex flex-col gap-3 max-w-96">
            <div className="flex flex-row gap-x-4 items-center">
              <Label htmlFor="username">Select an avatar</Label>
            </div>
            <AvatarSelect
              value={avatar}
              onSelect={(avatar) => setValue("avatar", avatar)}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="email">Email</Label>
            <span>{session?.session?.user?.email}</span>
          </div>

          <div className="flex flex-col gap-y-4">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              placeholder="Your name"
              value={name}
              type="text"
              name="name"
              className="max-w-full"
              {...register("name", { required: true })}
              size={36}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              placeholder="Your username"
              value={username}
              type="text"
              name="username"
              className="max-w-full"
              {...register("username", { required: true })}
              size={40}
            />
          </div>
          <DropdownMenuSeparator />
          <div className="flex items-center gap-4 w-full justify-between">
            <Button type="submit" className="w-full">
              Finish
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default CreateProfile;

const ProfileLoader = () => {
  return (
    <div className="flex flex-col gap-y-4 pb-24">
      {Array(4)
        .fill(0)
        .map((_, i) => {
          return (
            <div className="animate-pulse" key={i}>
              <div className="flex flex-row gap-x-6 rounded-sm items-center">
                <div className="bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-7 h-6 rounded-full"></div>
                <div className="flex flex-row justify-between w-full">
                  <div className="h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-full rounded-sm"></div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
