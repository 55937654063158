import { Database } from "../../utils/database.types";
import supabase from "../../utils/supabase";

export async function updateIntegration(
  payload: Partial<Database["public"]["Tables"]["integrations"]["Row"]> & {
    id: number;
  }
) {
  const { data, error } = await supabase
    .from("integrations")
    .update({
      ...payload,
    })
    .eq("id", payload.id)
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}
