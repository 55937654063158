import { AlignLeft, ChevronDownIcon } from "lucide-react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/auth";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui-components/Accordion";
import { BlockItem } from "../../ui-components/BlockItem";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { TemplateEnum } from "../../utils/types";
import { cn } from "../../utils/utils";
import { NoteSidebarContextProvider } from "../context/NoteSidebarItem.context";
import {
  TNotesResponseWithChildren,
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { useUser } from "../hooks/queries/useUser";
import { NoteCreator } from "./NoteCreator";
import { NoteWithActions } from "./NoteWithActions";
import {
  constructUrlForNote,
  getAccordionNoteValue,
  getActiveNotesFromUrl,
  getNotesAtDepth,
} from "./services";

export const NotesList = ({ onSelect }: { onSelect?: () => void }) => {
  const { userId } = useAuthStore();

  const { data: [user] = [] } = useUser({ userId });

  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useNotesList({ userId });

  const location = useLocation();

  const activeNoteId =
    location.pathname.includes("/notes/") && location.pathname.split("/").pop();

  const parentNotesFromUrl = getActiveNotesFromUrl({
    location,
  });

  const allNotesAtCurrentDepth = getNotesAtDepth({
    notes,
    depth: 1,
  });

  const allNotes = transformHeirarchicalNotesInFlatList({
    notesResponse: notes,
  });

  if (isLoadingNotes)
    return (
      <div className="flex flex-col gap-y-2 px-2">
        <NotesListLoader />
      </div>
    );

  if (isErrorNotes) return null;

  if (!notes.length) {
    return (
      <div className="flex flex-col gap-y-2">
        <div className="px-4 flex flex-row items-center justify-between text-textColors-muted-light dark:text-textColors-muted-dark sticky top-0 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark z-5">
          <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
            Notes
          </span>
          <NoteCreator planType={user?.plan} createdNotes={allNotes.length} />
        </div>
        <div className="px-3">
          <NotesListLoader animate={false} lines={1} />
          <NotesListLoader animate={false} lines={1} className="w-3/5" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-2">
      <div className="px-4 flex flex-row items-center justify-between text-textColors-muted-light dark:text-textColors-muted-dark sticky top-0 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark z-5">
        <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
          Notes
        </span>
        <NoteCreator planType={user?.plan} createdNotes={allNotes.length} />
      </div>
      <div className="flex flex-col gap-y-0.5">
        <Accordion
          type="single"
          collapsible
          className="w-full flex flex-col gap-y-0.5 px-1"
          value={getAccordionNoteValue({
            activeNoteIds: parentNotesFromUrl.concat([activeNoteId]),
            notes: allNotesAtCurrentDepth,
          })}
        >
          {notes.map((note) => {
            if (
              note.status === "archived" ||
              note.template === TemplateEnum.SNIPPET
            )
              return null;

            return (
              <NoteSidebarSubItem
                key={note.id}
                note={note}
                activeNoteId={activeNoteId}
                onSelect={() => {
                  onSelect && onSelect();
                }}
              />
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

const NoteSidebarSubItem = ({
  note,
  activeNoteId,
  style = {},
  onSelect,
}: {
  note: TNotesResponseWithChildren;
  activeNoteId: string;
  className?: string;
  style?: React.CSSProperties;
  onSelect?: ({ noteId }: { noteId: string }) => void;
}) => {
  const router = useHistory();

  const { data: notes = [] } = useNotesList({ userId: note.users.id });

  const parentNotesFromUrl = getActiveNotesFromUrl({
    location: router.location,
  });

  const notesAtDepth = getNotesAtDepth({
    notes: notes,
    depth: note.depth + 1,
  });

  if (!note.children.length) {
    return (
      <BlockItem
        key={note.id}
        onClick={() => {
          router.push(constructUrlForNote({ note }));
          onSelect && onSelect({ noteId: note.id });
        }}
        variant={
          note.id === activeNoteId ? "interactive" : "interactiveDefault"
        }
        className="flex flex-row items-center justify-between gap-x-2 group"
      >
        <NoteWithActions
          note={note}
          style={{ ...style, width: "100%" }}
          isActive={note.id === activeNoteId}
        />
      </BlockItem>
    );
  }

  if (note.status === "archived") return null;

  return (
    <AccordionItem value={note.id} className="border-0">
      <AccordionTrigger
        asChild
        className="p-0 justify-normal font-normal"
        renderIcon={<></>}
      >
        <BlockItem
          key={note.id}
          onClick={() => {
            router.push(constructUrlForNote({ note }));
            onSelect && onSelect({ noteId: note.id });
          }}
          variant={
            note.id === activeNoteId ? "interactive" : "interactiveDefault"
          }
          className={cn(
            "flex flex-row items-center justify-between gap-x-3 group px-4 py-1.5"
          )}
        >
          <NoteSidebarContextProvider parentNote={note}>
            <NoteWithActions
              note={note}
              style={style}
              isActive={note.id === activeNoteId}
            />
          </NoteSidebarContextProvider>
          <ChevronDownIcon
            size={12}
            className="transform text-textColors-muted-light dark:text-textColors-muted-dark flex-none"
          />
        </BlockItem>
      </AccordionTrigger>
      <AccordionContent className="pb-0">
        {Boolean(note.children.length) && (
          <Accordion
            type="single"
            collapsible
            value={getAccordionNoteValue({
              activeNoteIds: parentNotesFromUrl.concat(activeNoteId),
              notes: notesAtDepth,
            })}
          >
            <div className="pt-0.5 flex flex-col gap-y-0.5">
              {note.children.map((subNote: TNotesResponseWithChildren) => {
                const padding = note.depth * 1;

                if (
                  subNote.status === "archived" ||
                  subNote.template === TemplateEnum.SNIPPET
                )
                  return null;

                return (
                  <NoteSidebarContextProvider
                    parentNote={note}
                    key={subNote.id}
                  >
                    <NoteSidebarSubItem
                      note={subNote}
                      activeNoteId={activeNoteId}
                      style={{
                        paddingLeft: `${padding}em`,
                      }}
                    />
                  </NoteSidebarContextProvider>
                );
              })}
            </div>
          </Accordion>
        )}
      </AccordionContent>
    </AccordionItem>
  );
};

const NotesListLoader = ({ lines = 3, className = "", animate = true }) => {
  return (
    <>
      {Array(lines)
        .fill(0)
        .map((_, i) => {
          return (
            <div
              key={i}
              className={cn(
                "flex flex-row gap-x-2 px-1 rounded-sm items-center",
                animate ? "animate-pulse" : ""
              )}
            >
              <LucideIcon icon={AlignLeft} size={14} />
              <div className="flex flex-col gap-y-1.5 w-full mt-1">
                <div
                  className={cn(
                    "h-4 bg-bg-tertiary-light dark:bg-bg-tertiary-dark w-full rounded-md",
                    className
                  )}
                ></div>
              </div>
            </div>
          );
        })}
    </>
  );
};
