import { BrandIcon } from "../business/icons/Icons";
import { Button } from "../ui-components/Button";
import { cn } from "./utils";

export default function Logo({
  className = "",
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      variant="ghost"
      className={cn(
        "hover:bg-transparent dark:hover:bg-transparent !px-0 !justify-start pr-1",
        className
      )}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <BrandIcon className="transition-colors w-6 h-6" />
    </Button>
  );
}
