import { MoreVertical, X } from "lucide-react";
import { FC, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "sonner";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { Info } from "../../ui-components/Info";
import { Sheet, SheetContent } from "../../ui-components/Sheet";
import { Database } from "../../utils/database.types";
import { cn } from "../../utils/utils";
import { AppLoader } from "../global-helpers";
import { AIProviders } from "../hooks/ai/useFetchAiCompletions";
import { useCreateIntegration } from "../hooks/mutations/useCreateIntegration";
import { useDeleteIntegration } from "../hooks/mutations/useDeleteIntegration";
import { useUpdateIntegration } from "../hooks/mutations/useUpdateIntegration";
import { useGetIntegrations } from "../hooks/queries/useGetIntegration";
import { getIntegrationsQueryKey } from "../hooks/query-keys";
import { GptIcon } from "./gpt.svg";
import { GptIntegrationForm } from "./GptIntegrationForm";

export const integrationsMap = [
  {
    id: AIProviders.openai,
    label: "Open AI",
    description:
      "Integrate with Open AI's APIs to ease your note taking workflows",
    icon: <GptIcon className="w-5 h-5" />,
  },
] as const;

export const Integrations: FC = () => {
  const [openIntegrationsModal, setOpenIntegrationsModal] =
    useState<
      Omit<Database["public"]["Tables"]["integrations"]["Row"], "created_at">
    >(null);

  const { userId } = useAuthStore();

  const { mutate: deleteIntegration } = useDeleteIntegration();
  const { mutate: updateIntegration } = useUpdateIntegration();

  const { data: integrations, isLoading: isLoadingIntegrations } =
    useGetIntegrations({
      userId,
    });

  if (isLoadingIntegrations) {
    return <AppLoader />;
  }

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row items-center gap-x-1">
        <div className="flex flex-col">
          <div className="text-xl font-medium">Integrations</div>
          <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
            Integrate with third-party services
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        <div className={cn("grid grid-cols-1 md:grid-cols-3")}>
          {integrationsMap.map((integration) => {
            const connectedIntegration = integrations?.find(
              (intg) =>
                (intg.metadata as any)?.integrationType === integration.id
            );

            return (
              <div
                key={integration.id}
                className="p-4 relative cursor-pointer rounded border border-border-primary-light dark:border-border-primary-dark flex flex-col gap-y-4 hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
                onClick={() => {
                  setOpenIntegrationsModal({
                    metadata: {
                      integrationType: integration.id,
                      model: (connectedIntegration?.metadata as any)?.model,
                    },
                    user_id: userId,
                    secret_key: connectedIntegration?.secret_key || "",
                    id: connectedIntegration?.id,
                    active: connectedIntegration?.active,
                  });
                }}
              >
                <div className="flex flex-row items-center justify-between">
                  <div className="text-sm font-medium truncate flex flex-row items-center gap-1">
                    {integration.icon}
                    {integration.label}
                  </div>
                  {connectedIntegration?.id && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="spirit" size="xs" className="w-5">
                          <MoreVertical size={14} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="mr-2">
                        <DropdownMenuItem
                          onClick={(e) => {
                            e.stopPropagation();

                            deleteIntegration({
                              integrationId: connectedIntegration.id,
                            });
                          }}
                        >
                          Disconnect
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={(e) => {
                            e.stopPropagation();

                            updateIntegration(
                              {
                                id: connectedIntegration.id,
                                active: !connectedIntegration.active,
                              },
                              {
                                onSuccess: () => {
                                  toast.success("Integration disabled");
                                },
                              }
                            );
                          }}
                        >
                          {connectedIntegration.active ? "Disable" : "Enable"}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
                <p className="text-sm line-clamp-2 h-11 text-textColors-muted-light dark:text-textColors-muted-dark">
                  {integration.description}
                </p>
                <div className="flex flex-row gap-4">
                  <span
                    className={cn(
                      "capitalize rounded-md text-xs px-2 py-0.5 w-fit flex items-center gap-1",
                      "border border-border-primary-light dark:border-border-primary-dark"
                    )}
                  >
                    {Boolean(connectedIntegration) ? (
                      <div className="w-2 h-2 rounded-full bg-bg-success-light dark:bg-bg-success-dark"></div>
                    ) : (
                      <div></div>
                    )}
                    {Boolean(connectedIntegration)
                      ? `Connected`
                      : `Not connected `}
                  </span>
                  <span
                    className={cn(
                      "capitalize rounded-md text-xs px-2 py-0.5 w-fit flex items-center gap-1",
                      "border border-border-primary-light dark:border-border-primary-dark"
                    )}
                  >
                    {Boolean(connectedIntegration?.active) ? (
                      <div className="w-2 h-2 rounded-full bg-bg-success-light dark:bg-bg-success-dark"></div>
                    ) : (
                      <div></div>
                    )}
                    {Boolean(connectedIntegration?.active)
                      ? `Active`
                      : `Inactive `}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <Sheet
          open={Boolean(openIntegrationsModal)}
          onOpenChange={() => {
            if (Boolean(openIntegrationsModal)) setOpenIntegrationsModal(null);
          }}
        >
          <SheetContent
            side="right"
            className="pr-0 border h-[95%] rounded-md my-auto mr-4"
            autoClose={false}
          >
            <>
              <Button
                variant="ghost"
                size="icon"
                className="h-6 w-6 absolute right-4 top-6"
                onClick={() => {
                  setOpenIntegrationsModal(null);
                }}
              >
                <X className="h-4 w-4" />
              </Button>
              <IntegrationPreview
                integration={{
                  id: openIntegrationsModal?.id,
                  user_id: userId,
                  metadata: openIntegrationsModal?.metadata,
                  secret_key: openIntegrationsModal?.secret_key,
                  active: openIntegrationsModal?.active,
                }}
                onClose={() => setOpenIntegrationsModal(null)}
              />
            </>
          </SheetContent>
        </Sheet>
      </div>
    </div>
  );
};

const IntegrationPreview = ({
  integration,
  onClose,
}: {
  integration: Omit<
    Database["public"]["Tables"]["integrations"]["Row"],
    "created_at"
  >;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { userId } = useAuthStore();
  const { mutate: createIntegration } = useCreateIntegration();
  const { mutate: updateIntegration } = useUpdateIntegration();

  const isUpdate = Boolean(integration.id);

  if (!integration) return null;

  if ((integration.metadata as any)?.integrationType === AIProviders.openai) {
    return (
      <div className="flex flex-col gap-y-4 h-full">
        <div className="flex flex-row items-center gap-x-1">
          <div className="flex flex-col">
            <div className="text-xl font-medium flex flex-row gap-1 items-center">
              {
                integrationsMap.find(
                  (intg) =>
                    intg.id === (integration.metadata as any)?.integrationType
                )?.icon
              }
              Open AI
            </div>
            <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm pr-6">
              Integrate with Open AI's APIs to ease your note taking workflows.
            </div>
          </div>
        </div>
        <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm mr-4">
          <Info>
            The OpenAI API uses API keys for authentication. Visit the{" "}
            <a
              href="https://platform.openai.com/docs/api-reference/authentication"
              target="_blank"
              rel="noreferrer"
              className="underline dark:text-textColors-foreground-dark hover:text-textColors-foreground-light/70 dark:hover:text-textColors-foreground-dark/70 underline-offset-2 cursor-ne-resize"
            >
              API reference
            </a>{" "}
            section for more details.
          </Info>
        </div>
        <GptIntegrationForm
          defaultValues={{
            apiKey: integration.secret_key,
            model: (integration.metadata as any)?.model,
          }}
          isUpdate={isUpdate}
          onSubmit={({ integrationType, metadata }) => {
            const payload: any = {
              metadata: {
                integrationType,
                model: metadata?.model,
              },
              secret_key: metadata.apiKey,
            };
            if (isUpdate) {
              updateIntegration(
                { ...payload, id: integration.id },
                {
                  onSuccess: () => {
                    toast.success("Integration updated successfully");
                    onClose();

                    queryClient.invalidateQueries(
                      getIntegrationsQueryKey({
                        userId,
                      })
                    );
                  },
                }
              );
            } else {
              createIntegration(payload, {
                onSuccess: () => {
                  toast.success("Integration created successfully");
                  onClose();

                  queryClient.invalidateQueries(
                    getIntegrationsQueryKey({
                      userId,
                    })
                  );
                },
              });
            }
          }}
        />
      </div>
    );
  }

  return null;
};
