import dayjs from "dayjs";
import { Clock, User } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDebounce } from "../../../hooks/utility/useDebounce";
import { TNotesList } from "../../../pages-v2/api/fetchNotes";
import { convertLabelToId } from "../../../pages-v2/global-helpers";
import { useUpdateNote } from "../../../pages-v2/hooks/mutations/useUpdateNote";
import { getNoteDetailsQueryKey } from "../../../pages-v2/hooks/queries/useNoteDetails";
import { useAuthStore } from "../../../store/auth";
import { AppExtensions, TextEditor } from "../../../text-editor/TextEditor";
import { AccessType } from "../../../utils/constants";
import { Copy } from "../../../utils/copy";
import { cn, DAYJS_DEFAULT_FORMAT } from "../../../utils/utils";
import { Button } from "../../Button";
import { LucideIcon } from "../../LucideIcon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Tooltip";
import { Prose } from "../Prose";
import { Revise } from "../revise/Revise";
import { TOC } from "./TOC";

export const BasicTemplate = ({ note }: { note: TNotesList[0] }) => {
  const { userId } = useAuthStore();
  const accessType =
    userId === note.users.id ? AccessType.EDIT : AccessType.READ;

  const [noteContentState, setNoteContentState] = useState(note?.body);
  const newNoteState = useDebounce(noteContentState, 500);

  const queryClient = useQueryClient();

  const { mutate: updateNote } = useUpdateNote({
    showToast: false,
    onSuccess: false,
  });

  const editorRef = useRef();

  useEffect(() => {
    if (newNoteState && typeof newNoteState === "string") {
      updateNote(
        {
          body: newNoteState,
          id: note.id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              getNoteDetailsQueryKey({
                noteId: note.id,
              })
            );
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNoteState]);

  // const noteReadingTime = readingTime(note.body).minutes || 0

  return (
    <div className="lg:grid grid-cols-9 px-2 md:p-10">
      <Prose classNames="flex flex-col gap-y-2 col-span-7 min-w-full lg:px-8 2xl:px-24 pb-8">
        <div className="flex justify-start gap-2 items-center border-border-secondary-light dark:border-border-secondary-dark -mx-1.5">
          {accessType === AccessType.EDIT && (
            <Revise
              note={note}
              editor={(editorRef?.current as any)?.getEditorInstance()}
            />
          )}
          <Copy
            content={window.location.href}
            variant="ghost"
            classNames="!border-0 h-8 w-8 p-1"
            size={14}
            key={note.id}
          />
          {note.users.name && (
            <TooltipProvider>
              <Tooltip defaultOpen={false}>
                <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="cursor-default"
                  >
                    <LucideIcon icon={User} size={16} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent
                  className={cn(
                    "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                    "text-textColors-background-light dark:text-textColors-background-dark",
                    "border border-border-primary-light/80 dark:border-border-primary-dark/80"
                  )}
                >
                  {note.users.name}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {note.created_at && (
            <TooltipProvider>
              <Tooltip defaultOpen={false}>
                <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="cursor-default"
                  >
                    <LucideIcon icon={Clock} size={16} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent
                  className={cn(
                    "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                    "text-textColors-background-light dark:text-textColors-background-dark",
                    "border border-border-primary-light/80 dark:border-border-primary-dark/80"
                  )}
                >
                  {dayjs(note.created_at).format(DAYJS_DEFAULT_FORMAT)}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>

        <div className="flex flex-col mb-6">
          <h2
            className="tracking-tight m-0 items-center gap-x-1 flex flex-wrap font-bold !text-2xl"
            id={convertLabelToId({ label: note.title })}
          >
            {note.title}{" "}
          </h2>
          <span>{note.description}</span>
        </div>

        {/* Actions */}

        <TextEditor
          // HACK: This is a hack to make the editor re-render when the note changes
          key={note.id}
          initialData={note?.body}
          onUpdate={({ data }: { data: string }) => setNoteContentState(data)}
          readOnly={accessType === AccessType.READ}
          appExtensions={[
            AppExtensions.REVISE_MARK,
            AppExtensions.HYPERLINK,
            AppExtensions.DRAG_AND_DROP,
          ]}
          ref={editorRef}
        />
      </Prose>
      <div className="hidden lg:block col-span-2">
        <TOC body={note.body} top={note.title} />
      </div>
    </div>
  );
};
