import { ReactRenderer } from '@tiptap/react'
import {
  SuggestionKeyDownProps,
  SuggestionOptions,
  SuggestionProps,
} from '@tiptap/suggestion'
import tippy, { Instance, Props } from 'tippy.js'
import { NoteHyperlink, NoteHyperlinkList } from './NoteHyperlinkList'

export const getNoteHyperlinkSuggestions = ({
  queryResults,
}: {
  queryResults: NoteHyperlink[]
}): Omit<SuggestionOptions<NoteHyperlink>, 'editor'> => {
  return {
    items: ({ query }: { query: string }) => {
      return queryResults.filter((item) =>
        item.title.toLowerCase().startsWith(query.toLowerCase())
      )
    },

    render: () => {
      let component: ReactRenderer
      let popup: Instance<Props>[]

      return {
        onStart: (props: SuggestionProps) => {
          component = new ReactRenderer(NoteHyperlinkList, {
            props,
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy('body', {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: 'manual',
            placement: 'bottom-start',
          })
        },

        onUpdate(props: SuggestionProps) {
          component.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props: SuggestionKeyDownProps) {
          if (props.event.key === 'Escape') {
            popup[0].hide()

            return true
          }

          if (component.ref) return (component.ref as any).onKeyDown(props)
          else return true
        },

        onExit() {
          popup[0].destroy()
          component.destroy()
        },
      }
    },
  }
}
