import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronsUpDown } from "lucide-react";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { NoteIconSelect } from "../../components/visual/NoteIconSelect";
import { Button } from "../../ui-components/Button";
import { DialogFooter } from "../../ui-components/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { Textarea } from "../../ui-components/Textarea";
import { TEMPLATES } from "../../utils/constants";
import { TemplateEnum } from "../../utils/types";

export const noteCreationFormSchema = z.object({
  title: z.string().min(1).max(100),
  description: z.string().max(1000).optional().default(null),
  layout: z.nativeEnum(TemplateEnum).optional().default(TemplateEnum.STANDARD),
  icon: z.string().optional().nullable(),
});

export const NoteCreationForm = ({
  defaultValues,
  onSubmit,
  customSubmitButton,
  showLoader,
  isLayoutSelectionEnabled = true,
  isDescriptionEnabled = true,
  isIconEnabled = true,
  additionalFields,
  onCancel,
}: {
  onSubmit: (values: z.infer<typeof noteCreationFormSchema>) => void;
  customSubmitButton?: React.ReactNode;
  showLoader: boolean;
  defaultValues?: z.infer<typeof noteCreationFormSchema>;
  isDescriptionEnabled?: boolean;
  isLayoutSelectionEnabled?: boolean;
  isIconEnabled?: boolean;
  additionalFields?: React.ReactNode;
  onCancel: () => void;
}) => {
  const form = useForm<z.infer<typeof noteCreationFormSchema>>({
    resolver: zodResolver(noteCreationFormSchema),
    defaultValues: defaultValues ?? {
      title: "",
      description: "",
      layout: TemplateEnum.STANDARD,
      icon: `filetext`,
    },
  });
  const { title, description, layout, icon } = form.watch();
  const {
    setValue,
    handleSubmit,
    register,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid gap-4 py-4">
        {isIconEnabled && (
          <div className="grid grid-cols-4 items-center">
            <Label htmlFor="title">Icon</Label>
            <NoteIconSelect
              readOnly={false}
              selectedIcon={icon}
              setSelectedIcon={(icon) => {
                setValue("icon", icon);
              }}
            />
          </div>
        )}
        <div className="grid grid-cols-4 items-center">
          <Label htmlFor="title">Title</Label>
          <Input
            autoFocus
            id="title"
            placeholder="Your title"
            className="col-span-3"
            value={title}
            name="title"
            {...register("title", { required: true })}
          />
        </div>
        {isDescriptionEnabled && (
          <div className="grid grid-cols-4 items-center">
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              placeholder="Your description"
              className="col-span-3 h-[150px]"
              value={description}
              name="description"
              {...register("description")}
            />
          </div>
        )}
        {isLayoutSelectionEnabled && (
          <div className="grid grid-cols-4 items-center">
            <Label>Layout</Label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm">
                  <div className="flex flex-row items-center gap-x-2">
                    <span className="capitalize">{layout}</span>
                    <ChevronsUpDown size={12} />
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                defaultValue={layout}
                className="flex flex-col gap-y-0.5"
              >
                {TEMPLATES.map((template) => {
                  return (
                    <DropdownMenuItem
                      className={twMerge(
                        "capitalize",
                        template.template_name === layout
                          ? "bg-bg-secondary-light dark:bg-bg-secondary-dark"
                          : ""
                      )}
                      key={template.template_name}
                      onClick={() => {
                        setValue("layout", template.template_name);
                      }}
                    >
                      {template.template_name}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        {additionalFields}
      </div>
      {customSubmitButton ?? (
        <DialogFooter className="pt-4 md:justify-between">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={showLoader}>
            Create Note
          </Button>
        </DialogFooter>
      )}
    </form>
  );
};
