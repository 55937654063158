import { Info } from "lucide-react";
import { FC } from "react";
import { cn } from "../utils/utils";
import { Button } from "./Button";

const BannerStatusIcon = ({
  status,
}: {
  status: Parameters<typeof Banner>[0]["status"];
}) => {
  switch (status) {
    case "error":
      return (
        <div className="bg-red-400 dark:bg-red-200 rounded-md size-6 grid place-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 20"
            className="size-4 fill-current text-white"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.15137 1.95117C9.30615 -0.0488281 12.1943 -0.0488281 13.3481 1.95117L20.7031 14.6992C21.8574 16.6992 20.4131 19.1992 18.104 19.1992H3.39502C1.08594 19.1992 -0.356933 16.6992 0.797364 14.6992L8.15137 1.95117ZM11.7666 16.0083C11.4971 16.2778 11.1313 16.4292 10.75 16.4292C10.3687 16.4292 10.0029 16.2778 9.7334 16.0083C9.46387 15.7388 9.3125 15.373 9.3125 14.9917C9.3125 14.9307 9.31641 14.8706 9.32373 14.811C9.33545 14.7197 9.35547 14.6304 9.38379 14.5439L9.41406 14.4609C9.48584 14.2803 9.59375 14.1147 9.7334 13.9751C10.0029 13.7056 10.3687 13.5542 10.75 13.5542C11.1313 13.5542 11.4971 13.7056 11.7666 13.9751C12.0361 14.2446 12.1875 14.6104 12.1875 14.9917C12.1875 15.373 12.0361 15.7388 11.7666 16.0083ZM10.75 4.69971C11.0317 4.69971 11.3022 4.81152 11.5015 5.01074C11.7007 5.20996 11.8125 5.48047 11.8125 5.76221V11.0747C11.8125 11.3564 11.7007 11.627 11.5015 11.8262C11.3022 12.0254 11.0317 12.1372 10.75 12.1372C10.4683 12.1372 10.1978 12.0254 9.99854 11.8262C9.79932 11.627 9.6875 11.3564 9.6875 11.0747V5.76221C9.6875 5.48047 9.79932 5.20996 9.99854 5.01074C10.1978 4.81152 10.4683 4.69971 10.75 4.69971Z"
            ></path>
          </svg>
        </div>
      );

    case "info":
      return <Info className="size-4" />;

    default:
      return (
        <div className="bg-red-400 dark:bg-red-200 rounded-md size-6 grid place-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 20"
            className="size-4 fill-current text-white"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.15137 1.95117C9.30615 -0.0488281 12.1943 -0.0488281 13.3481 1.95117L20.7031 14.6992C21.8574 16.6992 20.4131 19.1992 18.104 19.1992H3.39502C1.08594 19.1992 -0.356933 16.6992 0.797364 14.6992L8.15137 1.95117ZM11.7666 16.0083C11.4971 16.2778 11.1313 16.4292 10.75 16.4292C10.3687 16.4292 10.0029 16.2778 9.7334 16.0083C9.46387 15.7388 9.3125 15.373 9.3125 14.9917C9.3125 14.9307 9.31641 14.8706 9.32373 14.811C9.33545 14.7197 9.35547 14.6304 9.38379 14.5439L9.41406 14.4609C9.48584 14.2803 9.59375 14.1147 9.7334 13.9751C10.0029 13.7056 10.3687 13.5542 10.75 13.5542C11.1313 13.5542 11.4971 13.7056 11.7666 13.9751C12.0361 14.2446 12.1875 14.6104 12.1875 14.9917C12.1875 15.373 12.0361 15.7388 11.7666 16.0083ZM10.75 4.69971C11.0317 4.69971 11.3022 4.81152 11.5015 5.01074C11.7007 5.20996 11.8125 5.48047 11.8125 5.76221V11.0747C11.8125 11.3564 11.7007 11.627 11.5015 11.8262C11.3022 12.0254 11.0317 12.1372 10.75 12.1372C10.4683 12.1372 10.1978 12.0254 9.99854 11.8262C9.79932 11.627 9.6875 11.3564 9.6875 11.0747V5.76221C9.6875 5.48047 9.79932 5.20996 9.99854 5.01074C10.1978 4.81152 10.4683 4.69971 10.75 4.69971Z"
            ></path>
          </svg>
        </div>
      );
  }
};

export const Banner: FC<{
  status: "error" | "warning" | "info";
  label?: string;
  description?: string;
  action?: {
    fn: () => void;
    label: string;
  };
  className?: string;
}> = ({
  label = "Something went wrong",
  description = `We're experiencing an issue at the moment. Please try again later.`,
  status,
  action,
  className = "",
}) => {
  return (
    <div
      className={cn(
        "border border-border-primary-light dark:border-border-primary-dark rounded-lg p-4 w-10/12 relative",
        className
      )}
      data-testid="error-boundary"
    >
      <div className="absolute size-6 top-4 left-4 grid place-items-center shrink-0 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark">
        <BannerStatusIcon status={status} />
      </div>
      <div className="flex flex-col pl-10 -mt-0.5">
        <span className="label">{label}</span>
        <div
          className={cn(
            "text-text-textColors-muted-light dark:text-text-textColors-muted-dark description",
            label ? "" : "mt-1"
          )}
        >
          {description}
        </div>
        {Boolean(action) && (
          <Button
            onClick={() => {
              if (action.fn && typeof action.fn === "function") {
                action.fn();
              }
            }}
            className="mt-4"
            size="sm"
            variant="outline"
          >
            {action.label}
          </Button>
        )}
      </div>
    </div>
  );
};
