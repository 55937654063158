import { Editor } from '@tiptap/core'
import { Extension, ReactRenderer } from '@tiptap/react'
import { Suggestion } from '@tiptap/suggestion'
import tippy, { GetReferenceClientRect, Instance } from 'tippy.js'
import { TextEditorCommandsView } from './TextEditorCommandsView'

export const TextEditorCommandsPlugin = Extension.create({
  name: 'insertMenu',
  addProseMirrorPlugins() {
    let component: ReactRenderer<TextEditorCommandsView, any>,
      popup: Instance<any>

    return [
      Suggestion({
        editor: this.editor,
        char: '/',
        command: ({ editor, range, props }) => {
          props.command({ editor, range, props })
        },
        items: ({ query }) => {
          return [
            {
              title: 'Heading',
              attrs: {
                'data-test-id': 'insert-heading2',
              },
              command: ({ editor }: { editor: Editor }) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .setNode('heading', { level: 4 })
                  .run()
              },
            },
            {
              title: 'Quote',
              attrs: {
                'data-test-id': 'insert-quote',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .setBlockquote()
                  .run()
              },
            },
            {
              title: 'Bullet List',
              attrs: {
                'data-test-id': 'insert-bullet-list',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .toggleBulletList()
                  .run()
              },
            },
            {
              title: 'Numbered List',
              attrs: {
                'data-test-id': 'insert-ordered-list',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .toggleOrderedList()
                  .run()
              },
            },
            {
              title: 'Task List',
              attrs: {
                'data-test-id': 'insert-task-list',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .toggleTaskList()
                  .run()
              },
            },
            {
              title: 'Separator',
              attrs: {
                'data-test-id': 'insert-rule',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .setHorizontalRule()
                  .run()
              },
            },
            {
              title: 'Code Block',
              attrs: {
                'data-test-id': 'insert-code',
              },
              command: ({ editor, range }: any) => {
                const selection = editor.view.state.selection
                const from = selection.$from.posAtIndex(0)
                const to = selection.$from.posAtIndex(1)
                editor
                  .chain()
                  .focus()
                  .deleteRange({ from, to })
                  .setCodeBlock()
                  .run()
              },
            },
            {
              title: 'Snippet',
              command: ({ editor, range }: { editor: Editor; range: any }) => {
                // close the popup
                if (popup) {
                  popup.hide()
                }
              },
            },
            // {
            //   title: 'Empty Flashcard',
            //   command: ({ editor, range }: { editor: Editor; range: any }) => {
            //     const selection = editor.view.state.selection
            //     const from = selection.$from.posAtIndex(0)
            //     const to = selection.$from.posAtIndex(1)
            //     editor
            //       .chain()
            //       .focus()
            //       .deleteRange({ from, to })
            //       .setNode('flashcard')
            //       .splitListItem('flashcard')
            //       .run()
            //   },
            // },
          ]
            .filter((item) => {
              return item.title.toLowerCase().startsWith(query.toLowerCase())
            })
            .slice(0, 10)
        },
        startOfLine: true,
        allow: ({ state, range, editor }) => {
          const node = state.selection.$from.node()
          if (!node) return false
          return node.textBetween(0, 1) === '/'
        },
        render: () => {
          return {
            onStart: (props) => {
              component = new ReactRenderer(TextEditorCommandsView, {
                props,
                editor: props.editor,
              })
              popup = tippy(props.editor.options.element, {
                getReferenceClientRect:
                  props.clientRect as GetReferenceClientRect,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                zIndex: 25000,
              })
            },
            onUpdate: (props) => {
              component.updateProps(props)
              popup.setProps({
                getReferenceClientRect: props.clientRect,
              })
            },
            onKeyDown: ({ event }) => {
              if (event.key === 'Escape') {
                popup.hide()
                return true
              }
              if (component.ref)
                return component.ref.onKeyDown(event as KeyboardEvent)
              else return true
            },
            onExit: () => {
              component.destroy()
              popup.destroy()
            },
          }
        },
      }),
    ]
  },
})
