import {
  Box,
  Center,
  Spinner,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { BiCheckDouble, BiLink } from "react-icons/bi";
import {
  FiArrowLeft,
  FiChevronLeft,
  FiFileText,
  FiGlobe,
  FiGrid,
  FiLock,
} from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { MoreActions } from "../business/icons/Icons";
import { IconComponentBordered } from "./Icons";
import { TemplateEnum, Visibility } from "./types";
import { urlConverter } from "./urls";

import { clsx, type ClassValue } from "clsx";
import { ArrowBigUp, ChevronUp, Command, Option } from "lucide-react";
import { twMerge } from "tailwind-merge";
import { ShortcutKey } from "../ui-components/Shortcut";

export const isEmptyObject = (obj: any = {}) => {
  if (Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};

export const capitalizeFirstLetter = (s: string) => {
  if (!s) return "";
  return s.slice(0, 1).toUpperCase() + s.slice(1);
};

export const BrandSquare = () => {
  const brandColor = useColorModeValue("light.brand", "dark.brand");

  return (
    <Box bg={brandColor} height={3} w={3} rounded="sm" opacity={0.9}></Box>
  );
};

export const MoreActionsToggle = ({ fontSize = "sm" }) => {
  const iconToggleBg = useColorModeValue("gray.300", "dark.secondary");

  return (
    <Box bg={iconToggleBg} px={1} rounded="sm" fontSize={fontSize}>
      <MoreActions height={16} width={16} />
    </Box>
  );
};

export const DAYJS_DEFAULT_FORMAT = "MMMM D ddd, YYYY";
export const DAYJS_MINI_FORMAT = "MMM D, YYYY";

export const CopyTopicLinkButton = ({
  topicName,
  topicId,
}: {
  topicName: string;
  topicId: string;
}) => {
  const iconToggleBg = useColorModeValue("#fff", "dark.tertiary");
  const borderColor = useColorModeValue("light.border", "dark.border");
  const [isCopied, setIsCopied] = useState(false);

  const url = useMemo(
    () =>
      `${window.location.origin}` +
      `/topic/${urlConverter(encodeURI(topicName))}-${topicId.replace(
        /-/g,
        ""
      )}`,
    [topicId, topicName]
  );

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(url);
  }, [url]);

  if (isCopied) {
    return (
      <Box
        fontSize="xs"
        className="tertiaryText"
        w="fit-content"
        px={1.5}
        py="4px"
        rounded="md"
        display="inline-flex"
        alignItems="center"
        gridGap={1}
        bg={iconToggleBg}
        borderColor={borderColor}
        borderWidth={1}
      >
        <BiCheckDouble fontSize="1rem" />
        Copied!
      </Box>
    );
  }

  return (
    <Tooltip label="Copy link">
      <Box
        fontSize="xs"
        className="tertiaryText"
        w="fit-content"
        px={1.5}
        py="5px"
        rounded="md"
        display="inline-flex"
        alignItems="center"
        gridGap={1}
        bg={iconToggleBg}
        cursor="pointer"
        borderColor={borderColor}
        borderWidth={1}
        onClick={() => {
          copyToClipboard();

          setIsCopied(true);

          setTimeout(() => {
            setIsCopied(false);
          }, 6000);
        }}
      >
        <BiLink fontSize="1rem" />
      </Box>
    </Tooltip>
  );
};

export const CustomIconButton = ({
  onClick,
  Icon,
}: {
  onClick: () => void;
  Icon: JSX.Element;
}) => {
  const iconToggleBg = useColorModeValue("#fff", "dark.tertiary");
  const borderColor = useColorModeValue("light.border", "dark.border");

  return (
    <Box
      fontSize="xs"
      className="tertiaryText"
      w="fit-content"
      rounded="md"
      display="inline-flex"
      alignItems="center"
      gridGap={1}
      bg={iconToggleBg}
      cursor="pointer"
      borderColor={borderColor}
      borderWidth={1}
      onClick={() => {
        onClick();
      }}
    >
      {Icon}
    </Box>
  );
};

export const BackButton = () => {
  const history = useHistory();

  return (
    <Box
      className="secondaryText"
      py={{ base: 1, md: 3 }}
      px={{ base: 2, md: 2 }}
      fontSize={{ base: "md", lg: "lg" }}
      fontWeight={500}
      display="flex"
      alignItems="center"
      gridGap={2}
      onClick={() => {
        history.goBack();
      }}
    >
      <IconComponentBordered Icon={<FiArrowLeft size={12} />} />
    </Box>
  );
};

export const isActivePath = (appPathName: string, route: string) => {
  if (appPathName === route) {
    return true;
  }

  return false;
};

export const BackButtonChevron = () => {
  const history = useHistory();

  return (
    <Box
      className="secondaryText"
      py={{ base: 1, md: 3 }}
      px={{ base: 2, md: 2 }}
      fontSize={{ base: "md", lg: "lg" }}
      fontWeight={500}
      display="flex"
      alignItems="center"
      gridGap={2}
      onClick={() => {
        history.goBack();
      }}
    >
      <IconComponentBordered Icon={<FiChevronLeft size={12} />} />
    </Box>
  );
};

export const getVisibilityIcon = (visibility: Visibility) => {
  switch (visibility) {
    case Visibility.PUBLIC:
      return <FiGlobe />;
    case Visibility.PRIVATE:
      return <FiLock />;
    default:
      return null;
  }
};

export const getInfiniteQueryFlatMap = <T,>({
  data,
}: {
  data: {
    data: T[];
    count: number;
  }[];
}) => {
  return data?.flatMap((page) => page.data) ?? [];
};

export const transformEditorCodeblock = (content: any) => {
  return content?.content?.[0]?.text || ``;
};

export const getTemplateIcon = (templateType: TemplateEnum) => {
  switch (templateType) {
    case TemplateEnum.CHEATSHEET:
      return <FiGrid size={16} />;
    case TemplateEnum.STANDARD:
      return <FiFileText size={16} />;
    default:
      return null;
  }
};

export const getSingularValue = (value: string) => {
  if (!value) return "";

  return pluralize(value, 1);
};

export const Loader = ({ cta = "Fetching, this might take a while..." }) => {
  return (
    <Center>
      <Box
        mt={{ base: "20vh", lg: "30vh" }}
        py={2}
        px={{ base: 2, lg: "15vw" }}
      >
        <Box textAlign="center">
          <Spinner />
        </Box>
        <Box textAlign="center">{cta}</Box>
      </Box>
    </Center>
  );
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getSizeValueFromSizeString(props: string) {
  switch (props) {
    case "sm":
      return 14;
    case "xs":
      return 12;
    case "lg":
      return 18;
    default:
      return 18;
  }
}

export const getShortcutIcon = (shortcutKey: string) => {
  switch (shortcutKey) {
    case "Ctrl":
      return (
        <ShortcutKey tooltipLabel={shortcutKey}>
          <ChevronUp size={14} className="stroke-[#999999]" />
        </ShortcutKey>
      );
    case "Shift":
      return (
        <ShortcutKey tooltipLabel={shortcutKey}>
          <ArrowBigUp size={14} className="stroke-[#999999]" />
        </ShortcutKey>
      );
    case "option":
      return (
        <ShortcutKey tooltipLabel={shortcutKey}>
          <Option size={14} className="stroke-[#999999]" />
        </ShortcutKey>
      );
    case "Cmd":
      return (
        <ShortcutKey tooltipLabel={shortcutKey}>
          <Command size={14} className="stroke-[#999999]" />
        </ShortcutKey>
      );
    default:
      return (
        <ShortcutKey tooltipLabel={shortcutKey}>{shortcutKey}</ShortcutKey>
      );
  }
};
