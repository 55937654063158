import { Loader } from "lucide-react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { z } from "zod";
import {
  getIsActiveIntegration,
  getIsOpenAIIntegration,
} from "../../../pages-v2/helpers";
import { useFetchAICompletions } from "../../../pages-v2/hooks/ai/useFetchAiCompletions";
import { useGetIntegrations } from "../../../pages-v2/hooks/queries/useGetIntegration";
import { useAuthStore } from "../../../store/auth";
import { Button } from "../../../ui-components/Button";
import { AiLottie } from "./AiLottie";
import { AiMarkerForm } from "./AiMarkerForm";

type AIMarkerGeneratorProps = {
  selectedText: string;
  onDelete: () => void;
  onSubmit: (values: { question: string; answer: string }[]) => void;
};

const generateRevisionMarkerPrompt = ({
  selectedText,
}: {
  selectedText: string;
}) => {
  return `You're an AI memory retention specialist designed to help individuals grasp and memorize textual content effectively. Your task is to generate one question-answer pair from a given paragraph to aid in retention. Here is the paragraph for which you'll create the pairs:
  [Paragraph: ${selectedText}]
  Remember to frame the questions such that they cover key points or details from the paragraph. Ensure that the answers are concise and directly related to the questions asked. Your goal is to help individuals retain and recall information efficiently.`;
};

export const OpenaiMarkerGenerator: FC<AIMarkerGeneratorProps> = ({
  selectedText,
  onDelete,
  onSubmit,
}) => {
  const { userId } = useAuthStore();
  const {
    data: integrations = [],
    isLoading: isLoadingIntegrations,
    isError: isErrorIntegrations,
  } = useGetIntegrations({ userId });

  const activeIntegration = getIsActiveIntegration({ integrations });

  const {
    data: openAIResponse,
    isLoading: isLoadingOpenAIResponse,
    isError: isErrorOpenAIResponse,
    isFetching: isFetchingOpenAIResponse,
    refetch: refetchOpenAIResponse,
    error,
  } = useFetchAICompletions({
    // TODO: Type this properly.
    provider: (activeIntegration.metadata as any)?.integrationType,
    params: {
      model: (activeIntegration.metadata as any)?.model?.id,
      prompt: generateRevisionMarkerPrompt({ selectedText }),
      schema: z.object({
        results: z
          .object({
            question: z.string(),
            answer: z.string(),
          })
          .array(),
      }),
    },
  });

  if (isLoadingIntegrations) {
    return null;
  }

  if (isLoadingOpenAIResponse || isFetchingOpenAIResponse) {
    return (
      <div className="my-2 flex flex-col items-center">
        <AiLottie />
        <div className="flex gap-1 items-baseline text-textColors-muted-light dark:text-textColors-muted-dark">
          <span className="font-medium text-textColors-muted-light dark:text-textColors-muted-dark -mt-4">
            Thinking
          </span>
          <div className="flex flex-row gap-1">
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce"></div>
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.3s]"></div>
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.5s]"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!activeIntegration) {
    return (
      <div className="my-2 flex flex-col gap-2 items-center">
        <AiLottie />
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-md text-center">
            You need to connect an AI integration to generate markers.
          </div>
          <Button
            onClick={() => {
              if (activeIntegration) {
                refetchOpenAIResponse();
              }
            }}
            variant="outline"
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (isErrorOpenAIResponse || isErrorIntegrations) {
    return (
      <div className="mb-4">
        <AIErrorMessage
          customMessage={
            (error as any)?.message || "Failed to fetch AI response"
          }
        />
      </div>
    );
  }

  if (!openAIResponse) {
    return (
      <div className="my-2 flex flex-col gap-2 items-center">
        <AiLottie />
        <div className="flex flex-col items-center gap-2 w-3/5">
          <div className="rounded-md text-center">
            There was an error with the response from AI. Please try again
            later.
          </div>
          <Button
            onClick={() => {
              if (activeIntegration) {
                refetchOpenAIResponse();
              }
            }}
            variant="outline"
          >
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="my-2">
      <AiMarkerForm
        aiRevisionMarkers={
          openAIResponse
            ? (
                openAIResponse.object as {
                  results: Array<{
                    question: string;
                    answer: string;
                  }>;
                }
              )?.results || []
            : []
        }
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRetry={refetchOpenAIResponse}
      />
    </div>
  );
};

export const AIMarkerGeneratorWrapper = ({
  selectedText,
  onSubmit,
  onDelete,
}: {
  selectedText: string;
  onSubmit: (values: { question: string; answer: string }[]) => void;
  onDelete: () => void;
}) => {
  const router = useHistory();
  const { userId } = useAuthStore();
  const {
    data: integrations = [],
    isLoading: isLoadingIntegrations,
    isError: isErrorIntegrations,
  } = useGetIntegrations({ userId });

  if (isLoadingIntegrations) {
    return (
      <div className={`grid place-items-center justify-center h-48`}>
        <div className="flex flex-row items-center gap-x-2 text-md text-textColors-secondary-light dark:text-textColors-secondary-dark">
          <Loader
            size={16}
            className="animate-spin transition-all duration-1200"
          />
        </div>
      </div>
    );
  }

  const activeIntegration = getIsActiveIntegration({ integrations });
  const isGptIntegration = getIsOpenAIIntegration({
    integration: activeIntegration,
  });

  if (!activeIntegration) {
    return (
      <div className="my-2 flex flex-col gap-2 items-center">
        <AiLottie />
        <div className="flex flex-col items-center gap-2">
          <div className="rounded-md text-center">
            You need to connect an AI integration to generate markers.
          </div>
          <Button
            onClick={() => {
              router.push("/integrations");
            }}
            variant="link"
          >
            Create new
          </Button>
        </div>
      </div>
    );
  }

  if (isGptIntegration && !isErrorIntegrations) {
    return (
      <OpenaiMarkerGenerator
        selectedText={selectedText}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <div className="mb-4">
      <AIErrorMessage />
    </div>
  );
};

const AIErrorMessage = ({ customMessage }: { customMessage?: string }) => {
  return (
    <div className="my-2 flex flex-col gap-2 items-center">
      <AiLottie />
      <div className="flex flex-col items-center gap-2">
        <div className="rounded-md text-center">
          There was an error with the response from AI. Please add the
          reflection manually or retry.
        </div>
      </div>
    </div>
  );
};

export const AILoader = () => {
  return (
    <div className="relative">
      <div className="inner absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div className="relative w-4 h-4 animate-spin">
          <div className="w-1 h-1 rounded-full bg-backgroundColors-foreground-light dark:bg-backgroundColors-foreground-dark absolute left-0 top-1.5"></div>
          <div className="w-1 h-1 rounded-full bg-backgroundColors-foreground-light dark:bg-backgroundColors-foreground-dark absolute top-0 left-1.5"></div>
          <div className="w-1 h-1 rounded-full bg-backgroundColors-foreground-light dark:bg-backgroundColors-foreground-dark absolute right-0 top-1.5"></div>
          <div className="w-1 h-1 rounded-full bg-backgroundColors-foreground-light dark:bg-backgroundColors-foreground-dark absolute bottom-0 left-1.5"></div>
        </div>
      </div>
    </div>
  );
};
