export const getNotesListQueryKey = ({ userId }: { userId: string }) => [
  'notes-list',
  userId,
]

export const getUserQueryKey = ({ userId }: { userId: string }) => [
  'user',
  userId,
]

export const getIntegrationsQueryKey = ({ userId }: { userId: string }) => [
  'integrations',
  userId,
]
