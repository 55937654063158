import { generateObject } from "ai";
import {
  AIParams,
  AIProviders,
  OpenAIParams,
} from "../hooks/ai/useFetchAiCompletions";
import { AIInstance } from "../integrations/AIContext";

export const getAiCompletions = async ({
  provider,
  params,
  instance,
}: {
  provider: (typeof AIProviders)[keyof typeof AIProviders];
  params: AIParams;
  instance: AIInstance;
}) => {
  if (provider === "openai") {
    const { model, prompt, schema } = params as OpenAIParams;

    const result = await generateObject({
      model: instance(model),
      prompt,
      schema,
    });

    return result;
  }

  return null;
};
