import { Editor } from "@tiptap/react";
import { Bold, Italic, Link, Strikethrough } from "lucide-react";
import { useState } from "react";
import { z } from "zod";
import { BlockItem } from "../../ui-components/BlockItem";
import { Button } from "../../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "../../ui-components/Dialog";
import { Input } from "../../ui-components/Input";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { Separator } from "../../ui-components/Separator";
import { ColorSelector } from "../extensions/color-selector/ColorSelector";
import { AppExtensions } from "../TextEditor";
import { SelectionMenuType } from "./BubbleMenu";
import { RevisionDialogs } from "./revision-marker-generator/RevisionDialogs";
import { RevisionPopover } from "./revision-marker-generator/RevisionPopover";

export const SelectionMenu = ({
  editor,
  selectionType,
  setSelectionType,
  appExtensions,
}: {
  editor: Editor;
  selectionType: SelectionMenuType;
  setSelectionType: (type: SelectionMenuType) => void;
  appExtensions: AppExtensions[];
}) => {
  const { from, to } = editor.view.state.selection;
  const selectedText = editor.state.doc.textBetween(from, to, " ");

  const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);
  const [revisionPopoverDialog, setRevisionPopoverDialog] = useState<
    "ai" | "create" | "preview" | null
  >(null);

  const containerRef = editor?.options?.element as HTMLElement;

  switch (selectionType) {
    case null:
      return (
        <div className="flex flex-row items-center gap-x-1 bg-backgroundColors-background-light dark:bg-backgroundColors-gray-dark shadow border border-border-primary-light dark:border-border-primary-dark rounded-md p-1">
          <BlockItem
            variant={editor.isActive("bold") ? "active" : "default"}
            className="grid place-items-center p-2 rounded-sm h-7 px-2"
            onClick={() => editor.chain().toggleBold().run()}
          >
            <LucideIcon icon={Bold} size={14} />
          </BlockItem>
          <BlockItem
            variant={editor.isActive("italic") ? "active" : "default"}
            className="grid place-items-center p-2 rounded-sm h-7 px-2"
            onClick={() => editor.chain().toggleItalic().run()}
          >
            <LucideIcon icon={Italic} size={14} />
          </BlockItem>
          <BlockItem
            variant={editor.isActive("link") ? "active" : "default"}
            className="grid place-items-center p-2 rounded-sm h-7 px-2"
            onClick={() => {
              if (editor.isActive("link")) {
                // unlink
                editor.chain().unsetLink().run();
              } else setSelectionType("link");
            }}
          >
            <LucideIcon icon={Link} size={14} />
          </BlockItem>
          <BlockItem
            variant={editor.isActive("strike") ? "active" : "default"}
            className="grid place-items-center p-2 rounded-sm h-7 px-2"
            onClick={() => editor.chain().toggleStrike().run()}
          >
            <LucideIcon icon={Strikethrough} size={14} />
          </BlockItem>
          {appExtensions.includes(AppExtensions.REVISE_MARK) && (
            <>
              <Separator orientation="vertical" className="h-9 -my-2 mx-0.5" />
              <RevisionPopover
                editor={editor}
                containerRef={containerRef}
                setDialog={setRevisionPopoverDialog}
                setSelectionType={setSelectionType}
              />
            </>
          )}
          <Separator orientation="vertical" className="h-9 -my-2 mx-0.5" />
          <ColorSelector
            editor={editor}
            isOpen={isColorSelectorOpen}
            setIsOpen={setIsColorSelectorOpen}
            containerRef={containerRef ?? document.body}
          />
        </div>
      );
    case "link":
      return (
        <LinkElement
          setSelectionType={setSelectionType}
          selectedText={selectedText}
          editor={editor}
        />
      );
    case "reviseMark":
      return (
        <RevisionDialogs
          editor={editor}
          setSelectionType={setSelectionType}
          dialog={revisionPopoverDialog}
          setDialog={setRevisionPopoverDialog}
          selectedText={selectedText}
        />
      );
  }
};

export const revisionItemSchema = z.object({
  question: z.string().optional(),
  answer: z.string(),
  color: z.string().optional(),
});

const LinkElement = ({
  setSelectionType,
  editor,
  selectedText,
}: {
  setSelectionType: (type: SelectionMenuType) => void;
  editor: Editor;
  selectedText: string;
}) => {
  const [link, setLink] = useState("");
  const [open, setOpen] = useState(true);

  return (
    <Dialog
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setSelectionType(null);
        }
      }}
      open={open}
    >
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Apply link</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-y-2">
              <p className="max-h-32 text-sm leading-6 overflow-auto bg-bg-secondary-light dark:bg-bg-secondary-dark p-2 rounded-md text-text-tertiary-light dark:text-text-tertiary-dark">
                {selectedText}
              </p>
            </div>
            <div>
              <Input
                type="url"
                placeholder="Enter URL"
                onChange={(event) => setLink(event.target.value)}
              />
            </div>
          </div>
          <DialogFooter className="mt-12">
            <Button
              variant="outline"
              onClick={() => {
                setOpen(false);
                setSelectionType(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setLink({
                    href: link,
                    target: "_blank",
                  })
                  .run();
                setSelectionType(null);
              }}
            >
              Add
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
