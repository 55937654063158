import { useMutation, useQueryClient } from "react-query";
import { toast } from "sonner";
import { TNotesList } from "../../api/fetchNotes";
import { updateNote } from "../../api/updateNote";
import { getNoteDetailsQueryKey } from "../queries/useNoteDetails";
import { getNotesListQueryKey } from "../query-keys";

export type UpdateNotePayload = Partial<TNotesList[0]> & {
  id: string;
  userId?: string;
  parentId?: string;
};

export const useUpdateNote = ({
  showToast = true,
  onSuccess = true,
}: {
  showToast?: boolean;
  onSuccess?: boolean;
}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      title,
      body,
      userId,
      parentId,
      level,
      template,
      description,
      pinned,
      status,
      id,
      icon,
    }: UpdateNotePayload) =>
      updateNote({
        title,
        body,
        userId,
        parentId,
        level,
        template,
        description,
        pinned,
        status,
        id,
        icon,
      }),
    {
      onSuccess: (data) => {
        if (showToast) {
          toast.success(`
            "${data?.[0]?.title}" was updated successfully.
          `);
        }

        if (onSuccess) {
          queryClient.invalidateQueries(
            getNotesListQueryKey({ userId: data?.[0]?.user_id })
          );

          queryClient.invalidateQueries(
            getNoteDetailsQueryKey({
              noteId: data?.[0]?.id,
            })
          );
        }
      },
    }
  );
};
