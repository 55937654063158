import { AlignLeft } from "lucide-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { twMerge } from "tailwind-merge";
import { cn } from "../../../utils/utils";

export const TOC = ({ body, top }: { body: string; top: string }) => {
  const headings = extractHeadings({ jsonString: body });

  const router = useHistory();

  const tocRef = useRef<HTMLUListElement>(null);

  const dimensions = tocRef?.current?.getBoundingClientRect();

  // HACK: initially scroll to the heading
  useLayoutEffect(() => {
    if (router.location.hash) {
      setTimeout(() => {
        const element = document.getElementById(
          `${router.location.hash.replaceAll("#", "")}`
        );

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!headings.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-y-2 sticky top-12">
      <div className="flex flex-row items-center gap-x-1 font-medium">
        <AlignLeft size={14} />
        On this page
      </div>

      <ul
        className={cn(
          "space-y-2",
          "max-h-[500px] overflow-auto"
          // "relative after:content-[''] after:w-[2px] after:h-full after:absolute after:left-0 after:top-0 after:rounded-sm after:bg-border-primary-light/50 after:dark:bg-border-primary-dark/50"
        )}
        ref={tocRef}
      >
        {headings.map((item, index) => {
          const padding = getPadding(item.level, headings);

          return (
            <li
              key={index}
              style={{
                paddingLeft: `${padding}em`,
              }}
            >
              <NavHashLink
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
                to={constructUrl(
                  router.location.pathname,
                  router.location.search,
                  item.id
                )}
                activeClassName="active-link"
                className={twMerge(
                  "block no-underline tracking-tight transition-colors truncate text-sm text-textColors-muted-light dark:text-textColors-muted-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
                  // activeTOC === item.id
                  //   ? 'truncate font-medium !text-textColors-background-light !dark:text-textColors-background-dark'
                  //   : ''
                )}
              >
                {item.text}
              </NavHashLink>
            </li>
          );
        })}
      </ul>
      {dimensions?.height >= 384 ? (
        <div className="flex justify-center text-lg ml-8">...</div>
      ) : null}
    </div>
  );
};

const constructUrl = (url: string, search: string, id: string) => {
  const urlString = url.split("#")[0];
  const searchString = search.split("#")[0];

  return `${urlString}${searchString}#${id}`;
};

const extractHeadings = ({ jsonString }: { jsonString: string }) => {
  const result: {
    level: number;
    text: string;
    id: string;
  }[] = [];
  const content: unknown[] = Boolean(jsonString)
    ? JSON.parse(jsonString)?.content ?? []
    : [];

  const processNode = (node: Record<string, any>) => {
    if (
      node &&
      node.type === "heading" &&
      node.attrs &&
      node.attrs.level &&
      node.content?.[0] &&
      !result.some((item) => item.id === node.attrs.id)
    ) {
      result.push({
        id: node.attrs.id,
        level: node.attrs.level,
        text: node.content[0].text, // Assuming there's always a text node inside the heading
      });
    }

    if (node.content && Array.isArray(node.content)) {
      node.content.forEach((childNode) => processNode(childNode));
    }
  };

  content.forEach((node) => processNode(node));

  return result;
};

const getPadding = (
  level: number,
  headings: {
    level: number;
    text: string;
    id: string;
  }[]
) => {
  const maxLevel = Math.min(...headings.map((item) => item.level));

  return level * (1 / maxLevel);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useActiveItem(itemIds: string[]) {
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -70% 0%`, root: null }
    );

    itemIds?.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });
  }, [itemIds]);

  return { activeId };
}
