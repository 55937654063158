import { Button, ButtonProps } from '@chakra-ui/react'

interface PrimaryButtonProps extends ButtonProps {
  children: React.ReactNode
  Icon?: React.ReactNode
}

export default function PrimaryButton({
  children,
  ...restProps
}: PrimaryButtonProps) {
  return (
    // @ts-ignore -- too complex
    <Button variant="brand" {...restProps}>
      {children}
    </Button>
  )
}
