import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, TabsList, TabsTrigger } from '../../ui-components/Tabs'
import { BASE_URL } from '../NotesDashboardWrapper'
import { AccountSettingsRoutes } from './AccountSettingsRoutes'

const sidebarNavItems = [
  {
    title: 'Profile',
    href: `/profile`,
  },
  {
    title: 'Plan',
    href: `/plan`,
  },
]

export const AccountSettings = () => {
  return (
    <div className="flex flex-col lg:flex-row pb-16 justify-center w-full">
      <div className="flex flex-col gap-y-4">
        <div className="z-20 md:-top-4 md:pt-4 md:sticky bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
          <SettingsTabOptions items={sidebarNavItems} />
        </div>
        <div className="md:w-[768px] lg:w-[878px]">
          <AccountSettingsRoutes />
        </div>
      </div>
    </div>
  )
}

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string
    title: string
  }[]
}

const SettingsTabOptions = ({
  className,
  items,
  ...props
}: SidebarNavProps) => {
  const { pathname } = useLocation()
  const router = useHistory()

  return (
    <Tabs
      defaultValue={pathname.split('/settings').pop()}
      className="w-[400px]"
    >
      <TabsList>
        {items.map((item) => (
          <TabsTrigger
            value={item.href}
            key={item.href}
            onClick={() => {
              router.push(`${BASE_URL}/settings${item.href}`)
            }}
          >
            {item.title}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  )
}
