const customImageCSS = {
  ".simple-image": {
    padding: "20px 0",
  },
  ".simple-image input": {
    width: "100%",
    padding: "10px",
    border: "1px solid #e4e4e4",
    borderRadius: "3px",
    outline: "none",
    fontSize: "16px",
  },
  ".simple-image img": {
    maxWidth: "100%",
    marginBottom: "15px",
  },
  ".simple-image [contenteditable]": {
    width: "100%",
    borderBottom: "1px solid #e4e4e4",
    borderRadius: "3px",
    outline: "none",
    fontSize: "16px",
  },
  ".simple-image.withBorder img": {
    border: "1px solid #e8e8eb",
  },
  ".simple-image.withBackground": {
    background: "#eff2f5",
    padding: "10px",
  },
  ".simple-image.withBackground img": {
    display: "block",
    maxWidth: "60%",
    margin: "0 auto 15px",
  },
};

export default customImageCSS;
