import { Database } from "../../utils/database.types";
import supabase from "../../utils/supabase";

export async function createIntegration({
  metadata,
  user_id,
  secret_key,
}: Partial<Database["public"]["Tables"]["integrations"]["Row"]>) {
  const { data, error } = await supabase
    .from("integrations")
    .insert({
      metadata,
      user_id,
      secret_key,
      active: true,
    })
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}
