import { Session } from '@supabase/supabase-js'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import supabase from '../utils/supabase'
import { AuthContextProvider } from './Auth.context'

export const Auth = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session>(null)
  const router = useHistory()

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)

      if (!session) {
        router.push(`/login`)
      }
    })

    return () => subscription.unsubscribe()
  }, [router])

  if (!session) {
    return null
  } else {
    return (
      <AuthContextProvider session={session}>{children}</AuthContextProvider>
    )
  }
}
