import { Blocks } from "lucide-react";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

export const SnippetsUI = () => {
  const addressSnippet = (
    <span>
      <br />
      <span
        style={{
          color: "var(--revision-blue)",
        }}
        className="rounded-sm font-medium"
      >
        <LucideIcon
          icon={Blocks}
          size={14}
          className="-mt-1 mr-1"
          style={{
            stroke: "var(--revision-blue)",
          }}
        />
        Acme Inc. 1234 Main St. Springfield, IL 62701
      </span>
    </span>
  );
  return (
    <div className="flex flex-col relative">
      <div
        className="absolute border-dashed border-t left-[102px] top-8 w-[33px] opacity-50"
        style={{
          borderColor: "var(--revision-gray)",
        }}
      ></div>
      <div
        className="absolute border-dashed border-l border-b rounded-bl-xl left-8 top-[54px] w-[102px] h-40 opacity-50"
        style={{
          borderColor: "var(--revision-gray)",
        }}
      ></div>
      <div className="flex flex-row items-start gap-4">
        <div
          className={cn(
            "border rounded-md",
            "border-border-primary-light dark:border-border-primary-dark",
            "flex flex-row px-3 py-2 mt-3"
          )}
        >
          <div className="flex flex-row items-center gap-1">
            <LucideIcon
              icon={Blocks}
              size={14}
              className="-mt-0.5"
              style={{
                stroke: "var(--revision-blue)",
              }}
            />
            <span
              className="font-medium"
              style={{
                color: "var(--revision-blue)",
              }}
            >
              Address
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 leading-5 border-border-primary-light dark:border-border-primary-dark px-4 py-3 text-left">
          <div className="border rounded-md border-border-primary-light dark:border-border-primary-dark flex flex-col px-3 py-2">
            <div className="pb-4">
              <p className="mb-2">...</p>
              <p className="p-0 m-0">Regards,</p>
              <p className="-mt-4">{addressSnippet}</p>
            </div>
          </div>
          <div className="border rounded-md border-border-primary-light dark:border-border-primary-dark flex flex-col px-3 py-2">
            <div className="pb-4">
              <p className="mb-2">...</p>
              <p className="p-0 m-0">You can find us at:</p>
              <p className="-mt-4">{addressSnippet}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
