import { ChevronDown, Wind } from 'lucide-react'
import { FC, useEffect } from 'react'
import {
  TNotesResponseWithChildren,
  useNotesList,
} from '../../pages-v2/hooks/queries/useNotesList'
import { useAuthStore } from '../../store/auth'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../ui-components/DropdownMenu'
import { LucideIcon } from '../../ui-components/LucideIcon'
import { Prose } from '../../ui-components/templates/Prose'
import { DEFAULT_EDITOR_CONTENT } from '../../utils/constants'
import { TemplateEnum } from '../../utils/types'
import { AppExtensions, TextEditor } from '../TextEditor'

type SnippetsChoserProps = {
  onSnippetSelect: (snippet: TNotesResponseWithChildren) => void
  selectedSnippet: TNotesResponseWithChildren
}

export const SnippetsChoser: FC<SnippetsChoserProps> = ({
  onSnippetSelect,
  selectedSnippet,
}) => {
  const { userId } = useAuthStore()
  const { data: notes } = useNotesList({
    userId,
  })

  const snippets = notes?.filter(
    (note) => note.template === TemplateEnum.SNIPPET
  )

  const chosenSnippet = selectedSnippet ?? snippets?.[0]
  const showEmptyState = !Boolean(snippets?.length)

  useEffect(() => {
    if (
      !selectedSnippet &&
      chosenSnippet &&
      chosenSnippet !== selectedSnippet
    ) {
      onSnippetSelect(chosenSnippet)
    }
  }, [chosenSnippet, onSnippetSelect, selectedSnippet])

  const isEmptyContent =
    !chosenSnippet?.body || chosenSnippet.body === DEFAULT_EDITOR_CONTENT

  if (showEmptyState) {
    return (
      <div className="h-full grid place-items-center w-full">
        <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
          No snippets have been created.
        </span>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex items-center justify-between w-full pr-20">
        <DropdownMenu modal={false}>
          <DropdownMenuTrigger className="border border-border-primary-light dark:border-border-primary-dark px-2 py-1 rounded-md flex items-center gap-6">
            <div className="flex items-center gap-1">
              {chosenSnippet?.title}
            </div>
            <LucideIcon icon={ChevronDown} />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="!z-[9999999] w-48">
            {snippets?.map((snippet) => {
              return (
                <DropdownMenuItem
                  key={snippet.id}
                  onClick={() => onSnippetSelect(snippet)}
                >
                  {snippet.title}
                </DropdownMenuItem>
              )
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {!isEmptyContent && (
        <div className="mt-2 relative overflow-auto overflow-x-hidden h-[50vh] border border-border-primary-light dark:border-border-primary-dark p-4 border-dashed rounded-md">
          <Prose>
            <TextEditor
              readOnly
              key={chosenSnippet?.id}
              initialData={chosenSnippet?.body || `No content`}
              onUpdate={() => {}}
              appExtensions={[
                AppExtensions.REVISE_MARK,
                AppExtensions.HYPERLINK,
              ]}
            />
          </Prose>
        </div>
      )}
      {isEmptyContent && (
        <div className="h-full grid place-items-center">
          <div className="flex flex-col gap-2 items-center">
            <LucideIcon icon={Wind} size={24} />
            <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
              Selected snippet has no content
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
