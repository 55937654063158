import supabase from '../../utils/supabase'

export async function addFollow({
  sourceUserId,
  userWhoIsFollowing,
}: {
  sourceUserId: string
  userWhoIsFollowing: string
}) {
  const { data, error } = await supabase.from('follows').upsert({
    following: sourceUserId,
    user: userWhoIsFollowing,
  })

  if (error) {
    throw error
  }

  return data
}

export async function removeFollow({
  sourceUserId,
  userWhoIsFollowing,
}: {
  sourceUserId: string
  userWhoIsFollowing: string
}) {
  const { data, error } = await supabase.from('follows').delete().match({
    following: sourceUserId,
    user: userWhoIsFollowing,
  })

  if (error) {
    throw error
  }

  return data
}
