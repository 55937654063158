import { JSONContent } from '@tiptap/react'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { NoteHyperlink } from '../../../text-editor/extensions/note-hyperlink/NoteHyperlinkList'
import { fetchNotes, TNotesList } from '../../api/fetchNotes'
import { getNotesListQueryKey } from '../query-keys'

export type TNotesResponseWithChildren = TNotesList[0] & {
  children: TNotesResponseWithChildren[]
  contentNotes: TNotesList
  depth: number
  parentPaths: string[]
  noteHyperlinks: NoteHyperlink[]
}

export const useNotesList = ({
  userId,
  enabled = true,
}: {
  userId: string
  enabled?: boolean
}) => {
  return useQuery(
    getNotesListQueryKey({ userId }),
    () => fetchNotes({ userId }),
    {
      select: (data) => {
        return transformNotesInHierarchicalStructure({
          notesResponse: data,
        })
      },
      cacheTime: 30 * 60 * 1000,
      enabled: Boolean(userId) && enabled,
    }
  )
}

export const transformNotesInHierarchicalStructure = ({
  notesResponse,
}: {
  notesResponse: TNotesList
}): TNotesResponseWithChildren[] => {
  const buildHierarchy = (
    note: TNotesList[0],
    depth: number = 1,
    parentPaths: string[] = [],
    noteHyperlinks: NoteHyperlink[] = []
  ): TNotesResponseWithChildren => {
    const children = notesResponse
      .filter(
        (childNote) =>
          childNote.parent_id === note.id && !childNote.is_content_note
      )
      .map((childNote) =>
        buildHierarchy(
          childNote,
          depth + 1,
          parentPaths.concat(childNote.parent_id ?? []),
          noteHyperlinks
        )
      )

    const contentNotes = notesResponse.filter(
      (childNote) =>
        childNote.parent_id === note.id && childNote.is_content_note
    )

    return {
      ...note,
      depth,
      // parent paths. array of parent note ids before the current note
      // e.g. if the current note is a child of a child of a child of a root note
      // then the parent paths will be [rootNoteId, childNoteId, childNoteId]
      parentPaths,
      children:
        children.length > 0 ? children.sort((a, b) => a.level - b.level) : [],
      contentNotes:
        contentNotes.length > 0
          ? contentNotes.sort((a, b) => dayjs(a.created_at).diff(b.created_at))
          : [],
      noteHyperlinks: getNoteHyperlinksFromContentRecursive({
        DOM: JSON.parse(note.body || `{}`),
      }),
    }
  }

  const rootNotes = notesResponse.filter((note) => !note.parent_id)
  const hierarchicalNotes = rootNotes.map((note) => buildHierarchy(note))

  return hierarchicalNotes
}

export const transformHeirarchicalNotesInFlatList = ({
  notesResponse,
}: {
  notesResponse: TNotesResponseWithChildren[]
}): TNotesResponseWithChildren[] => {
  const buildFlatList = (
    note: TNotesResponseWithChildren,
    flatList: TNotesResponseWithChildren[] = []
  ): TNotesResponseWithChildren[] => {
    flatList.push(note)

    note.children?.forEach((childNote) => buildFlatList(childNote, flatList))

    return flatList
  }

  const flatList = notesResponse.reduce((acc, note) => {
    return buildFlatList(note, acc)
  }, [] as TNotesResponseWithChildren[])

  return flatList
}

export const transformHeirarchicalNotesToHyperlinks = ({
  notesResponse,
}: {
  notesResponse: TNotesResponseWithChildren[]
}): NoteHyperlink[] => {
  const buildFlatList = (
    note: TNotesResponseWithChildren,
    flatList: NoteHyperlink[] = []
  ): NoteHyperlink[] => {
    flatList.push({
      title: note.title,
      id: note.id,
      template: note.template,
      parentPaths: note.parentPaths,
    })

    note.children.forEach((childNote) => buildFlatList(childNote, flatList))

    return flatList
  }

  const flatList = notesResponse.reduce((acc, note) => {
    return buildFlatList(note, acc)
  }, [])

  return flatList
}

export const getNoteHyperlinksFromContentRecursive = ({
  DOM,
}: {
  DOM: JSONContent
}): NoteHyperlink[] => {
  if (!DOM || typeof DOM !== 'object') {
    return []
  }

  let noteHyperlinks: NoteHyperlink[] = []

  DOM.content?.map((item, idx) => {
    const mentionElements =
      (item.content ?? []).filter((i) => i.type === 'mention') ?? []

    const noteHyperlinkElements = mentionElements.filter(
      (m) => m.attrs?.id && m.attrs?.id?.id && m.attrs?.id?.template
    )

    noteHyperlinks = noteHyperlinks.concat(
      noteHyperlinkElements.map((n) => ({
        id: n.attrs?.id?.id,
        template: n.attrs?.id?.template,
        title: n.attrs?.id?.title,
        parentPaths: n.attrs?.id?.parentPaths ?? [],
      }))
    )

    if (item.content) {
      const nestedNoteHyperlinks = getNoteHyperlinksFromContentRecursive({
        DOM: item,
      })

      noteHyperlinks = noteHyperlinks.concat(
        nestedNoteHyperlinks.map((n) => ({
          id: n.id,
          template: n.template,
          title: n.title,
          parentPaths: n.parentPaths ?? [],
        }))
      )
    }

    return item
  })

  return noteHyperlinks ?? []
}

export const getAllChildNotesRecursively = ({
  noteId,
  allNotes,
}: {
  noteId: string
  allNotes: TNotesResponseWithChildren[]
}): string[] => {
  let childNotes: string[] = []

  const note = allNotes.find((n) => n.id === noteId)

  if (!note) {
    return []
  }

  note.children.forEach((childNote) => {
    childNotes.push(childNote.id)

    childNotes = childNotes.concat(
      getAllChildNotesRecursively({
        noteId: childNote.id,
        allNotes,
      })
    )
  })

  return childNotes ?? []
}
