import { useLocation } from 'react-router-dom'
import { TNotesResponseWithChildren } from '../hooks/queries/useNotesList'
import { BASE_URL } from '../NotesDashboardWrapper'

export const constructUrlForNote = ({
  note,
}: {
  note: Pick<TNotesResponseWithChildren, 'parentPaths' | 'id'>
}) => {
  if (note.parentPaths?.length) {
    return `${BASE_URL}/notes/${note.id}?source=${note.parentPaths.join(',')}`
  }

  return `${BASE_URL}/notes/${note.id}`
}

export const getNotesAtDepth = ({
  notes,
  depth,
}: {
  notes: TNotesResponseWithChildren[]
  depth: number
}) => {
  let notesAtDepth: TNotesResponseWithChildren[] = []

  notes.forEach((note) => {
    if (note.depth === depth) {
      notesAtDepth.push(note)
    }

    if (note.children.length) {
      notesAtDepth = notesAtDepth.concat(
        getNotesAtDepth({ notes: note.children, depth })
      )
    }
  })

  return notesAtDepth
}

export const getActiveNotesFromUrl = ({
  location,
}: {
  location: ReturnType<typeof useLocation>
}) => {
  const activeNoteId = location.pathname.split('/').pop()
  const queryParams = new URLSearchParams(location.search)

  const source = queryParams.get('source')

  let sourceIds: string[] = []

  if (source) {
    sourceIds = source.split(',')
  }

  return sourceIds.concat([activeNoteId])
}

export const getAccordionNoteValue = ({
  activeNoteIds,
  notes,
}: {
  activeNoteIds: string[]
  notes: TNotesResponseWithChildren[]
}) => {
  const activeNote = notes.find((note) => activeNoteIds.includes(note.id))

  if (activeNote) {
    return activeNote.id
  }

  return null
}
