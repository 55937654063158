import { Sparkle } from "lucide-react";
import { useState } from "react";
import { ReactTyped } from "react-typed";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

const mockFlashcardData = [
  {
    question: "What is the principle of conservation of energy?",
    answer:
      "Energy cannot be created or destroyed, only transformed from one form to another.",
  },
];

export const FlashcardMockUI = () => {
  const [showInsight, setShowInsight] = useState(false);

  const card = mockFlashcardData[0];

  const colorToRender = "var(--blue)";

  return (
    <div className="rounded-xl px-4 flex flex-col items-center justify-center gap-4 relative h-48">
      <div
        className={cn(
          "mx-auto h-60 absolute -top-2 md:top-4 shadow-lg",
          "border border-border-primary-light dark:border-border-primary-dark rounded-2xl w-60 p-4"
        )}
      >
        {!showInsight && (
          <Button
            className="absolute mx-auto w-[calc(100%-32px)] bottom-10 rounded-full cursor-pointer justify-between"
            variant="outline"
            onClick={() => setShowInsight(!showInsight)}
          >
            <span className="text-xs text-textColors-muted-light dark:text-textColors-muted-dark font-normal">
              Click here to show insight
            </span>
            <LucideIcon
              icon={Sparkle}
              size={14}
              style={{
                fill: colorToRender,
                stroke: "transparent",
              }}
            />
          </Button>
        )}
        <div
          className={cn(
            "flex flex-row items-start gap-2 p-2 rounded-md bg-gradient-to-r from-indigo-100 dark:from-indigo-500"
          )}
        >
          {card.question && (
            <span className="font-medium shrink line-clamp-3">
              {card.question}
            </span>
          )}
        </div>
        {showInsight && (
          <div onClick={() => setShowInsight(false)} className="mt-2 pt-2">
            <ReactTyped
              backSpeed={50}
              onBegin={function noRefCheck() {}}
              onComplete={function noRefCheck() {}}
              onDestroy={function noRefCheck() {}}
              onLastStringBackspaced={function noRefCheck() {}}
              onReset={function noRefCheck() {}}
              onStart={function noRefCheck() {}}
              onStop={function noRefCheck() {}}
              onStringTyped={function noRefCheck() {}}
              onTypingPaused={function noRefCheck() {}}
              onTypingResumed={function noRefCheck() {}}
              startWhenVisible
              strings={[card.answer]}
              typeSpeed={10}
              showCursor={false}
              typedRef={function noRefCheck() {}}
              className="text-sm hover:backdrop-blur-3xl hover:opacity-50 transition-all duration-400 hover:cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
};
