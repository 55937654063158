import dayjs from "dayjs";
import { Archive, ArchiveRestore, MoreVertical, Trash2 } from "lucide-react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "../../store/auth";
import { BlockItem } from "../../ui-components/BlockItem";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { LucideIcon } from "../../ui-components/LucideIcon";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../ui-components/Tabs";
import { cn, DAYJS_MINI_FORMAT } from "../../utils/utils";
import { TNotesList } from "../api/fetchNotes";
import { ViewType, ViewTypeMap } from "../favorite-notes/FavoriteNotes";
import { AppLoader } from "../global-helpers";
import { useDeleteNotes } from "../hooks/mutations/useDeleteNotes";
import { useUpdateNote } from "../hooks/mutations/useUpdateNote";
import {
  TNotesResponseWithChildren,
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { constructUrlForNote } from "../notes-sidebar/services";

export const ArchivedNotes = () => {
  const { userId } = useAuthStore();
  const [viewType, setViewType] = useState<keyof typeof ViewTypeMap>(
    ViewType.LIST
  );

  const history = useHistory();

  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useNotesList({
    userId,
  });

  const archivedNotes = transformHeirarchicalNotesInFlatList({
    notesResponse: notes,
  }).filter((note) => note.status === "archived");

  if (isLoadingNotes) {
    return <AppLoader />;
  }

  if (!archivedNotes.length || isErrorNotes) {
    return (
      <div className="grid place-items-center justify-center h-full">
        <div className="flex flex-col gap-y-1.5 items-center text-center">
          <LucideIcon
            icon={Archive}
            size={64}
            strokeWidth="1px"
            className="text-text-tertiary-light dark:text-text-tertiary-dark"
          />
          <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
            No archived notes, check <br /> back again later
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4">
      <Tabs defaultValue={ViewType.LIST}>
        <div className="flex flex-row items-start justify-between">
          <div className="flex flex-row items-center gap-x-1">
            <div className="flex flex-col">
              <div className="text-xl font-medium">Archived</div>
              <div className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
                View and manage your archives
              </div>
            </div>
          </div>
          <TabsList className="flex flex-row gap-x-2 h-auto py-0.5">
            {Object.keys(ViewTypeMap).map((view) => {
              const isActive = viewType === view;

              return (
                <TabsTrigger
                  key={view}
                  className="p-0"
                  value={view}
                  onClick={() => setViewType(view as keyof typeof ViewTypeMap)}
                >
                  <Button
                    key={view}
                    variant={isActive ? "outline" : "ghost"}
                    size="icon"
                    className={`w-6 h-6`}
                    onClick={() =>
                      setViewType(view as keyof typeof ViewTypeMap)
                    }
                  >
                    {ViewTypeMap[view as keyof typeof ViewTypeMap].Icon}
                  </Button>
                </TabsTrigger>
              );
            })}
          </TabsList>
        </div>
        <TabsContent value={ViewType.LIST} className="mt-3.5">
          <ArchivedNoteListView
            notes={archivedNotes}
            handleNoteClick={({ note }: { note: TNotesList[0] }) => {
              history.push(
                constructUrlForNote({
                  note: note as unknown as TNotesResponseWithChildren,
                })
              );
            }}
          />
        </TabsContent>
        <TabsContent value={ViewType.GRID} className="mt-4">
          <ArchivedNoteGridView
            notes={archivedNotes}
            handleNoteClick={({ note }: { note: TNotesList[0] }) => {
              history.push(
                constructUrlForNote({
                  note: note as unknown as TNotesResponseWithChildren,
                })
              );
            }}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export const ArchivedNoteListView = ({
  notes,
  handleNoteClick,
}: {
  notes: TNotesList;
  handleNoteClick?: ({ note }: { note: TNotesList[0] }) => void;
}) => {
  const { mutate: deleteNotes } = useDeleteNotes();
  const { mutateAsync: updateNote } = useUpdateNote({
    showToast: false,
  });

  return (
    <div>
      {notes.map((note) => {
        return (
          <div key={note.id}>
            <BlockItem
              className="border-b cursor-text border-b-border-secondary-light dark:border-b-border-secondary-dark py-2 -mx-4"
              // onClick={() => {
              //   handleNoteClick && handleNoteClick({ note });
              // }}
            >
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-center gap-x-2">
                  <div
                    className={cn(
                      "hover:bg-transparent capitalize rounded-md text-xs px-2 py-0.5",
                      "border border-border-primary-light dark:border-border-primary-dark"
                    )}
                  >
                    {note.template}
                  </div>
                  <div className="truncate">{note.title}</div>
                </div>
                <div className="actions flex flex-row items-center gap-x-2 text-textColors-muted-light dark:text-textColors-muted-dark">
                  <p>{dayjs(note.updated_at).format(DAYJS_MINI_FORMAT)}</p>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="spirit" size="xs" className="w-5">
                        <MoreVertical size={14} />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="mr-2">
                      <DropdownMenuItem
                        onClick={() => {
                          updateNote({
                            id: note.id,
                            status: "draft",
                          });
                        }}
                      >
                        <LucideIcon icon={ArchiveRestore} size={14} />
                        Restore
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          deleteNotes({ noteIds: [note.id] });
                        }}
                      >
                        <LucideIcon icon={Trash2} size={14} />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </BlockItem>
          </div>
        );
      })}
    </div>
  );
};

export const ArchivedNoteGridView = ({
  notes,
  handleNoteClick,
}: {
  notes: TNotesList;
  handleNoteClick?: ({ note }: { note: TNotesList[0] }) => void;
}) => {
  const { mutate: deleteNotes } = useDeleteNotes();
  const { mutateAsync: updateNote } = useUpdateNote({
    showToast: false,
  });

  return (
    <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
      {notes.map((note) => {
        return (
          <div
            key={note.id}
            className="p-4 cursor-text rounded border border-border-primary-light dark:border-border-primary-dark flex flex-col gap-y-4 hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
            // onClick={() => {
            //   handleNoteClick && handleNoteClick({ note });
            // }}
          >
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm font-medium truncate">{note.title}</p>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="spirit" size="xs" className="w-5">
                    <MoreVertical size={14} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-2">
                  <DropdownMenuItem
                    onClick={() => {
                      updateNote({
                        id: note.id,
                        status: "draft",
                      });
                    }}
                  >
                    <LucideIcon icon={ArchiveRestore} size={14} />
                    Restore
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      deleteNotes({ noteIds: [note.id] });
                    }}
                  >
                    <LucideIcon icon={Trash2} size={14} />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <p className="text-sm line-clamp-2 h-11 text-textColors-muted-light dark:text-textColors-muted-dark">
              {note.description}
            </p>
            <div className="flex flex-row justify-between">
              <span className={cn("capitalize rounded-md text-sm w-fit")}>
                {dayjs(note.created_at).format(DAYJS_MINI_FORMAT)}
              </span>
              <span
                className={cn(
                  "capitalize rounded-md text-xs px-2 py-0.5 w-fit",
                  "border border-border-primary-light dark:border-border-primary-dark"
                )}
              >
                {note.template}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
