import { useMutation } from 'react-query'
import { useAuthStore } from '../../../store/auth'
import { Json } from '../../../utils/database.types'
import { createIntegration } from '../../api/createIntegration'

type IntegrationPayload = {
  metadata: Json
  secret_key: string
}

export const useCreateIntegration = () => {
  const { userId } = useAuthStore()

  return useMutation((payload: IntegrationPayload) => {
    return createIntegration({
      user_id: userId,
      metadata: payload.metadata,
      secret_key: payload.secret_key,
    })
  })
}
