import { MoreHorizontal } from 'lucide-react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { TNotesList } from '../../../pages-v2/api/fetchNotes'
import { useDeleteNotes } from '../../../pages-v2/hooks/mutations/useDeleteNotes'
import { Copy } from '../../../utils/copy'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../Alert'
import { Button } from '../../Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../../DropdownMenu'

export const CheatsheetNoteActions = ({
  note,
  onEdit,
  onDelete,
}: {
  note: TNotesList[0]
  onEdit: (note: TNotesList[0]) => void
  onDelete: (note: TNotesList[0]) => void
}) => {
  const [open, setOpen] = useState(false)
  const [openNoteDeletor, setOpenNoteDeletor] = useState(false)

  const { mutateAsync: deleteNotes } = useDeleteNotes()

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className={twMerge(
              'w-5 h-5',
              'group-hover:opacity-100 opacity-0 transition-opacity duration-200 flex flex-row items-center gap-x-1',
              open ? 'opacity-100' : '',
              'text-textColors-muted-light dark:text-textColors-muted-dark'
            )}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <MoreHorizontal size={12} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-auto p-1 flex flex-col gap-y-0.5">
          <DropdownMenuItem
            onClick={async () => {
              onEdit(note)

              setOpen(false)
            }}
          >
            Edit
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <Copy
            content={window.location.href}
            variant="ghost"
            classNames="!border-0 justify-start px-2 rounded-sm"
            size={14}
            key={note.id}
            cta="Copy link"
          />
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setOpenNoteDeletor(true)

              setOpen(false)
            }}
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <AlertDialog open={openNoteDeletor} onOpenChange={setOpenNoteDeletor}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete your this note{' '}
              <span>"{note.title}"</span> from our servers. This action cannot
              be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteNotes({
                  noteIds: [note.id],
                })
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
