import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'sonner'
import { useAuthStore } from '../../../store/auth'
import { Database } from '../../../utils/database.types'
import { deleteNotes } from '../../api/deleteNotes'
import { getNotesListQueryKey } from '../query-keys'

export const useDeleteNotes = () => {
  const queryClient = useQueryClient()
  const { userId } = useAuthStore()

  return useMutation(
    ({
      noteIds = [],
    }: {
      noteIds: Array<Database['public']['Tables']['notes']['Row']['id']>
    }) => deleteNotes({ noteIds }),
    {
      onSuccess: (data, variables) => {
        // const { noteIds } = variables

        // const notesList = queryClient.getQueryData<
        //   TNotesResponseWithChildren[]
        // >(getNotesListQueryKey({ userId }))

        // const currentOpenedNote = getCurrentNoteFromStorage({
        //   storage: value,
        // })

        // const lastOpenedNote = getLastNoteFromStorage({
        //   storage: value,
        // })

        // // predict notes list after deletion
        // const deletableNotes = noteIds.concat(
        //   getAllChildNotesRecursively({
        //     noteId: noteIds[0],
        //     allNotes: transformHeirarchicalNotesInFlatList({
        //       notesResponse: transformNotesInHierarchicalStructure({
        //         notesResponse: notesList,
        //       }),
        //     }),
        //   }) ?? []
        // )

        // const noteListAfterDeletion = notesList.filter(
        //   (note) => !deletableNotes.includes(note.id)
        // )

        // setValue({
        //   currentNote: null,
        //   lastNote: null,
        // })

        // // if last opened note is deleted, open first note in the list
        // if (
        //   !deletableNotes.includes(currentOpenedNote?.id) ||
        //   !deletableNotes.includes(lastOpenedNote?.id)
        // ) {
        //   router.push(
        //     constructUrlForNote({
        //       note: deletableNotes.includes(currentOpenedNote?.id)
        //         ? lastOpenedNote
        //         : currentOpenedNote,
        //     })
        //   )
        // } else {
        //   // if last opened note is not deleted, open last opened note
        //   router.push(
        //     constructUrlForNote({
        //       note: noteListAfterDeletion[0],
        //     })
        //   )
        // }

        queryClient.invalidateQueries(getNotesListQueryKey({ userId }))

        toast.success(`
          "${data?.[0]?.title}" was deleted successfully.
        `)
      },
      onError: () => {
        toast.error(`Failed to delete the note. Please try again later.`)
      },
    }
  )
}
