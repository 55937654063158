import { Check, Copy as CopyIcon } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Button, ButtonProps } from '../ui-components/Button'
import { LucideIcon } from '../ui-components/LucideIcon'

export const Copy = ({
  content,
  size = 12,
  classNames = '',
  variant = 'outline',
  cta = '',
}: {
  content: string
  size?: number
  classNames?: string
  variant?: ButtonProps['variant']
  cta?: string
}) => {
  const [isCopied, setIsCopied] = useState(false)
  const timerRef = useRef<any>()

  useEffect(() => {
    return () => {
      setIsCopied(false)

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <Button
      variant={variant}
      onClick={(e) => {
        e.preventDefault()
        navigator.clipboard.writeText(content)

        setIsCopied(true)

        timerRef.current = setTimeout(() => {
          setIsCopied(false)
        }, 3000)
      }}
      className={twMerge(classNames, 'copy bg-transparent font-normal gap-2')}
    >
      {isCopied ? (
        <LucideIcon size={size} icon={Check} />
      ) : (
        <LucideIcon size={size} icon={CopyIcon} />
      )}
      {cta}
    </Button>
  )
}
