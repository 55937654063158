import supabase from '../../utils/supabase'

export async function getIntegrations({ userId }: { userId: string }) {
  const { data, error } = await supabase
    .from('integrations')
    .select()
    .eq('user_id', userId)

  if (error) {
    throw new Error(error.message)
  }

  return data
}
