import {
  Archive,
  Blocks,
  Bold,
  ChevronDown,
  Clock,
  Combine,
  Copy,
  Italic,
  Link,
  PanelLeftClose,
  Plus,
  Sparkle,
  Star,
  Strikethrough,
  User,
} from "lucide-react";
import { AiIcon } from "../../../assets/svg/ai.svg";
import { APP_NAME } from "../../../pages-v2/NotesDashboardWrapper";
import { BlockItem } from "../../../ui-components/BlockItem";
import { Button } from "../../../ui-components/Button";
import { DropdownMenuSeparator } from "../../../ui-components/DropdownMenu";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { Separator } from "../../../ui-components/Separator";
import { APP_PAGE_HEIGHT } from "../../../utils/constants";
import { cn } from "../../../utils/utils";

export const Showcase = () => {
  return (
    <div className="border border-border-primary-light dark:border-border-primary-dark rounded-md shadow-md hidden md:block">
      <nav className="border-b border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-gray-light/50 dark:bg-backgroundColors-gray-dark/50">
        <div className="flex items-center justify-between px-4 py-3">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <div className="h-3 w-3 rounded-full bg-[#ED6B5F]"></div>
              <div className="h-3 w-3 rounded-full bg-[#F4BE4F]"></div>
              <div className="h-3 w-3 rounded-full bg-[#62C956]"></div>
              <LucideIcon icon={PanelLeftClose} className="ml-2" />
            </div>
          </div>
          <div className="rounded-md border border-border-primary-light dark:border-border-primary-dark py-1 px-2 w-48 text-xs flex items-center justify-between">
            <span>{APP_NAME.toLowerCase()}.app</span>
            <div className="rounded-full p-0.5 grid place-items-center">
              <span className="-mt-1.5 text-xs">...</span>
            </div>
          </div>
          <div className="h-6 w-6 rounded-md border border-border-primary-light dark:border-border-primary-dark grid place-items-center">
            🐼
          </div>
        </div>
      </nav>
      <div className="items-center gap-y-2 w-[58rem] grid grid-cols-4">
        <div className="col-span-1 border-r border-border-primary-light dark:border-border-primary-dark">
          <div
            className={`bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark ${APP_PAGE_HEIGHT} relative flex flex-col rounded-b-md`}
          >
            <div className="flex flex-col gap-y-0.5 p-1 pointer-events-none">
              <BlockItem variant="default" className="px-3">
                <Star size={16} />
                Favorites
              </BlockItem>
              <BlockItem variant="default" className="px-3 gap-x-6 relative">
                <div className="flex flex-row items-center gap-x-1">
                  <Archive size={16} />
                  Archived
                </div>
              </BlockItem>
              <BlockItem variant="default" className="px-3">
                <Blocks size={16} />
                Snippets
              </BlockItem>
              <BlockItem variant="default" className="px-3">
                <Combine size={16} />
                Integrations
              </BlockItem>
            </div>
            <DropdownMenuSeparator className="bg-border-secondary-light dark:border-secondary-dark my-2" />
            <div className="h-full flex flex-col justify-between">
              <div className="overflow-auto">
                <div className="flex flex-col gap-y-2">
                  <div className="px-4 flex flex-row items-center justify-between text-textColors-muted-light dark:text-textColors-muted-dark sticky top-0 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark z-5">
                    <span className="text-textColors-muted-light dark:text-textColors-muted-dark">
                      Notes
                    </span>
                    <Plus size={16} />
                  </div>
                  <div className="flex flex-col gap-y-0.5">
                    {mockNotes.map((note, index) => {
                      return (
                        <BlockItem
                          variant={index === 0 ? "active" : "default"}
                          className={cn("px-3 pointer-events-none")}
                          key={index}
                        >
                          <div>{note.icon}</div>
                          <span className="truncate">{note.label}</span>
                        </BlockItem>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NoteShowcase />
      </div>
    </div>
  );
};

const NoteShowcase = () => {
  return (
    <div className="h-full px-2 md:p-10 w-full col-span-3">
      <div className="flex items-center gap-6">
        <LucideIcon icon={Sparkle} />
        <LucideIcon icon={Copy} />
        <LucideIcon icon={User} />
        <LucideIcon icon={Clock} />
      </div>
      <h2 className="font-medium mt-4">Atomic Orbitals</h2>
      <div className="mt-5">
        <span>What is Atomic Orbital?</span>
        <div className="mt-4 relative">
          <span className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark text-textColors-foreground-light dark:text-textColors-foreground-dark">
            An atomic orbital is a mathematical function that describes the
            wave-like behavior of either helps to determine the likelihood of
            discovering any atom’s electron in any particular area surrounding
            the nucleus.
            <div className="absolute border border-border-primary-light dark:border-border-primary-dark rounded-md shadow-lg -right-32 -top-56 z-5 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
              <Button variant="outline" size="icon" className="m-4 mb-0">
                <AiIcon className="fill-backgroundColors-background-dark dark:fill-backgroundColors-background-light" />
              </Button>
              <div className="p-4 pt-2 w-72">
                <ul>
                  <li className="flex flex-col gap-1">
                    <span className="font-semibold">
                      What does an atomic orbital help determine?
                    </span>
                    <span className="text-sm text-textColors-secondary-light dark:text-textColors-secondary-dark">
                      An atomic orbital helps determine the likelihood of
                      discovering an atom's electron in a particular area
                      surrounding the nucleus.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </span>{" "}
          Additionally, the term could be used to describe the physical region
          where an electron can be calculated based on the orbital’s specific
          mathematical form.
        </div>
        <div className="mt-4 relative">
          The atomic orbital model is also referred as the electron cloud model
          or wave mechanics model. It is a contemporary framework for
          illustrating the microscopic behavior of electrons in matter. Atomic
          orbitals are its fundamental building blocks. According to this model,
          the electron configuration that forms the electron cloud of a
          multi-electron atom is essentially the result of{" "}
          <span className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark text-textColors-foreground-light dark:text-textColors-foreground-dark">
            simpler hydrogen-like atomic orbitals.
          </span>
          <div className="absolute border border-border-primary-light dark:border-border-primary-dark rounded-md shadow-lg right-64 bottom-6 z-5 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
            <div className="flex items-center gap-2 rounded-md bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
              <div className="flex items-center gap-2 py-1 pl-2">
                <LucideIcon icon={Bold} />
                <LucideIcon icon={Italic} />
                <LucideIcon icon={Link} />
                <LucideIcon icon={Strikethrough} />
              </div>
              <Separator
                orientation="vertical"
                className="border-red-500 h-8"
              />
              <div className="px-2 py-1 text-sm">Reflect</div>
              <Separator
                orientation="vertical"
                className="border-red-500 h-8"
              />
              <div className="px-2 py-1 flex items-center gap-1">
                A<LucideIcon icon={ChevronDown} />
              </div>
            </div>
          </div>
        </div>
        <div className="font-medium mt-6 text-lg">
          Quantum Numbers and Atomic Orbitals
        </div>
        <p className="mt-2">
          The electron’s energy, angular momentum, and an angular momentum
          vector component are all represented by the quantum numbers n, l, and
          m, which are used to classify atomic orbitals.
        </p>
        <p className="mt-4">
          Each orbital has a unique set of quantum numbers (n, l, and m) that
          define it and can hold up to two electrons, each with a unique spin
          quantum number. Simple names for orbitals with angular momentum
          quantum numbers l = 0, 1, 2, and 3 are the s orbital, p orbital, d
          orbital, and f orbital. Therefore, these names describe the electron
          configurations and denote the orbital shape.
        </p>
      </div>
    </div>
  );
};

const mockNotes = [
  {
    label: `Inorganic Chemistry: Atomic Orbitals`,
    icon: `📚`,
  },
  {
    label: `Interview Prep`,
    icon: `📝`,
  },
  {
    label: `Vue.js cheatsheet`,
    icon: `📄`,
  },
];
