import {
  Box,
  Button,
  IconButtonProps,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'

type ColorModeSwitcherProps = Omit<IconButtonProps, 'aria-label'>

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode()
  const text = useColorModeValue('dark', 'light')
  const SwitchIcon = useColorModeValue(RiMoonLine, RiSunLine)
  const borderColor = useColorModeValue('light.border', 'dark.border')
  const borderHoverColor = useColorModeValue(
    'light.borderHover',
    'dark.borderHover'
  )

  return (
    <Box
      rounded="md"
      border="1px"
      borderStyle="solid"
      borderColor={borderColor}
      _hover={{ borderColor: borderHoverColor }}
      cursor="pointer"
      display="flex"
      alignItems="center"
      gridGap={2}
      className="secondaryText"
    >
      <Button
        size="md"
        onClick={toggleColorMode}
        aria-label={`Switch to ${text} mode`}
        {...props}
        bg="transparent"
        _hover={{
          bg: 'transparent',
        }}
        px={2}
        _focus={{
          outline: 'none',
        }}
      >
        <SwitchIcon height={16} width={16} />
      </Button>
    </Box>
  )
}
