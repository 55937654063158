import {
  Album,
  Aperture,
  Atom,
  BarChart4,
  Book,
  BookMarked,
  BookOpenText,
  BookText,
  Box,
  Boxes,
  CircuitBoard,
  Clock,
  Clock8,
  Clock9,
  Coffee,
  Cog,
  Compass,
  Construction,
  Cookie,
  Database,
  Disc3,
  Dna,
  DollarSign,
  Donut,
  Fan,
  Feather,
  FerrisWheel,
  File,
  Files,
  FileText,
  FileType2,
  Flame,
  FlaskConical,
  FlaskRound,
  Folder,
  FolderClosed,
  FolderOpen,
  GraduationCap,
  Layers2,
  LifeBuoy,
  LucideIcon,
  Magnet,
  Map,
  Milestone,
  Monitor,
  Network,
  Palette,
  PieChart,
  Rocket,
  RockingChair,
  Sailboat,
  Tags,
  TestTube2,
  Trophy,
  Umbrella,
} from 'lucide-react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Button } from '../../ui-components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../ui-components/DropdownMenu'
import { LucideIcon as LucideIconComponent } from '../../ui-components/LucideIcon'

export const NoteIconSelect = ({
  readOnly,
  selectedIcon,
  setSelectedIcon,
}: {
  readOnly: boolean
  selectedIcon: string
  setSelectedIcon: (icon: string) => void
}) => {
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild autoFocus={false}>
        <Button
          variant="outline"
          size="icon"
          className={twMerge(
            'h-9 w-9 text-lg',
            readOnly ? 'pointer-events-none' : ''
          )}
        >
          <LucideIconComponent icon={noteIconsMap[selectedIcon]} size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-2 grid grid-cols-5 h-48 overflow-auto gap-2 p-2">
        {Object.keys(noteIconsMap).map((icon) => {
          const Icon = noteIconsMap[icon]
          return (
            <Button
              variant="outline"
              size="icon"
              key={icon}
              className="text-lg"
              onClick={() => {
                setSelectedIcon(icon)

                setOpen(false)
              }}
            >
              <LucideIconComponent icon={Icon} size={24} />
            </Button>
          )
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const noteIconsMap: {
  [key: string]: LucideIcon
} = {
  aperture: Aperture,
  album: Album,
  atom: Atom,
  chart4: BarChart4,
  book: Book,
  booktext: BookText,
  bookmarked: BookMarked,
  bookopentext: BookOpenText,
  box: Box,
  boxes: Boxes,
  circuit: CircuitBoard,
  clock8: Clock8,
  clock9: Clock9,
  clock: Clock,
  coffee: Coffee,
  cog: Cog,
  compass: Compass,
  construction: Construction,
  cookie: Cookie,
  database: Database,
  disc3: Disc3,
  dna: Dna,
  dollar: DollarSign,
  donut: Donut,
  fan: Fan,
  feather: Feather,
  ferris: FerrisWheel,
  filetext: FileText,
  filetype2: FileType2,
  file: File,
  files: Files,
  flame: Flame,
  flask: FlaskConical,
  flaskround: FlaskRound,
  folderclosed: FolderClosed,
  folderopened: FolderOpen,
  folder: Folder,
  graduation: GraduationCap,
  layers2: Layers2,
  lifebuoy: LifeBuoy,
  magnet: Magnet,
  map: Map,
  milestone: Milestone,
  monitor: Monitor,
  network: Network,
  palette: Palette,
  piechart: PieChart,
  rocket: Rocket,
  rockingchair: RockingChair,
  sailboat: Sailboat,
  tags: Tags,
  testtube2: TestTube2,
  trophy: Trophy,
  umbrella: Umbrella,
}
