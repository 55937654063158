import { useQuery } from "react-query";
import { getIntegrations } from "../../api/getIntegrations";
import { getIntegrationsQueryKey } from "../query-keys";

export const useGetIntegrations = ({ userId }: { userId: string }) => {
  return useQuery(
    getIntegrationsQueryKey({ userId }),
    () => getIntegrations({ userId }),
    {
      enabled: !!userId,
    }
  );
};
