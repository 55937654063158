import { useContext } from "react";
import { useQuery } from "react-query";
import { ZodType, ZodTypeDef } from "zod";
import { getAiCompletions } from "../../api/getAiCompletions";
import { AIContext } from "../../integrations/AIContext";

export type AIParams = OpenAIParams;

export type OpenAIParams = {
  model: string;
  prompt: string;
  schema?: ZodType<unknown, ZodTypeDef, unknown>;
};

export const AIProviders = { openai: "openai" } as const;

export const useFetchAICompletions = ({
  provider,
  params,
}: {
  provider: (typeof AIProviders)[keyof typeof AIProviders];
  params: AIParams;
}) => {
  const { instance } = useContext(AIContext);

  return useQuery(
    ["ai", provider],
    () => getAiCompletions({ provider, params, instance }),
    {
      enabled: !!params.prompt && Boolean(instance),
      cacheTime: 0,
      retry: 0,
    }
  );
};
