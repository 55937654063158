import { Editor } from "@tiptap/core";
import { Check, ChevronDown } from "lucide-react";
import { Dispatch, FC, SetStateAction } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../ui-components/Popover";
import { cn } from "../../../utils/utils";

export interface BubbleColorMenuItem {
  name: string;
  color: string;
}

interface ColorSelectorProps {
  editor: Editor;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  containerRef: HTMLElement;
}

const TEXT_COLORS: BubbleColorMenuItem[] = [
  {
    name: "Default",
    color: "var(--black)",
  },
  {
    name: "Purple",
    color: "var(--purple)",
  },
  {
    name: "Red",
    color: "var(--red)",
  },
  {
    name: "Yellow",
    color: "var(--yellow)",
  },
  {
    name: "Blue",
    color: "var(--blue)",
  },
  {
    name: "Green",
    color: "var(--green)",
  },
  {
    name: "Orange",
    color: "var(--orange)",
  },
  {
    name: "Pink",
    color: "var(--pink)",
  },
  {
    name: "Gray",
    color: "var(--gray)",
  },
  {
    name: "Black",
    color: "var(--black)",
  },
];

const HIGHLIGHT_COLORS: BubbleColorMenuItem[] = [
  {
    name: "Default",
    color: "var(--hl-default)",
  },
  {
    name: "Purple",
    color: "var(--hl-purple)",
  },
  {
    name: "Red",
    color: "var(--hl-red)",
  },
  {
    name: "Yellow",
    color: "var(--hl-yellow)",
  },
  {
    name: "Blue",
    color: "var(--hl-blue)",
  },
  {
    name: "Green",
    color: "var(--hl-green)",
  },
  {
    name: "Orange",
    color: "var(--hl-orange)",
  },
  {
    name: "Pink",
    color: "var(--hl-pink)",
  },
  {
    name: "Gray",
    color: "var(--hl-gray)",
  },
];

export const ColorSelector: FC<ColorSelectorProps> = ({
  editor,
  isOpen,
  setIsOpen,
  containerRef,
}) => {
  const activeColorItem = TEXT_COLORS.find(({ color }) =>
    editor.isActive("textStyle", { color })
  );

  const activeHighlightItem = HIGHLIGHT_COLORS.find(({ color }) =>
    editor.isActive("highlight", { color })
  );

  return (
    <Popover open={isOpen} modal>
      {/* <div className="relative h-full"> */}
      <PopoverTrigger onClick={() => setIsOpen(!isOpen)}>
        <span
          className="rounded-sm px-1"
          style={{
            color: activeColorItem?.color,
            backgroundColor: activeHighlightItem?.color,
          }}
        >
          A
        </span>

        <ChevronDown
          className={cn(
            "h-4 w-4 transition-all duration-400",
            isOpen ? "rotate-180" : ""
          )}
        />
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="z-[99999] my-1 flex max-h-80 w-48 flex-col overflow-hidden overflow-y-auto rounded border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 shadow-xl animate-in fade-in slide-in-from-top-1"
        onInteractOutside={() => setTimeout(() => setIsOpen(false), 0)}
        alignOffset={-8}
        containerRef={containerRef}
        ref={containerRef as any}
      >
        <div className="my-1 px-2 text-sm text-stone-500">Color</div>
        {TEXT_COLORS.map(({ name, color }, index) => (
          <button
            key={index}
            onClick={() => {
              editor.commands.unsetColor();
              name !== "Default" &&
                editor
                  .chain()
                  .focus()
                  .setColor(color || "")
                  .run();
              setIsOpen(false);
            }}
            className="flex items-center justify-between rounded-sm px-2 py-1 text-sm text-textColors-background-light dark:text-textColors-background-dark hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
            type="button"
          >
            <div className="flex items-center space-x-2">
              <div
                className={cn(
                  "rounded-sm border border-text-textColors-background-light/20 dark:border-text-textColors-background-dark/20 px-1.5 py-px font-medium",
                  name === "Default" &&
                    "bg-white dark:bg-backgroundColors-gray-light/40"
                )}
                style={{ color }}
              >
                A
              </div>
              <span>{name}</span>
            </div>
            {editor.isActive("textStyle", { color }) && (
              <Check className="h-4 w-4" />
            )}
          </button>
        ))}

        <div className="mb-1 mt-2 px-2 text-sm text-stone-500">Background</div>

        {HIGHLIGHT_COLORS.map(({ name, color }, index) => (
          <button
            key={index}
            onClick={() => {
              editor.commands.unsetHighlight();
              name !== "Default" && editor.commands.setHighlight({ color });
              setIsOpen(false);
            }}
            className="flex items-center justify-between rounded-sm px-2 py-1 text-sm hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
            type="button"
          >
            <div className="flex items-center space-x-2">
              <div
                className="rounded-sm border border-text-textColors-background-light/20 dark:border-text-textColors-background-dark/20 px-1.5 py-px font-medium  text-textColors-background-light"
                style={{ backgroundColor: color }}
              >
                A
              </div>
              <span>{name}</span>
            </div>
            {editor.isActive("highlight", { color }) && (
              <Check className="h-4 w-4" />
            )}
          </button>
        ))}
      </PopoverContent>
      {/* </div> */}
    </Popover>
  );
};
