import { useQuery } from 'react-query'
import { fetchNoteDetails } from '../../api/fetchNoteDetails'

export const getNoteDetailsQueryKey = ({ noteId }: { noteId: string }) => [
  'note-details',
  noteId,
]

export const useFetchNoteDetails = (noteId: string, enabled?: boolean) => {
  return useQuery(
    getNoteDetailsQueryKey({ noteId }),
    () =>
      fetchNoteDetails({
        noteId,
      }),
    {
      enabled,
    }
  )
}
