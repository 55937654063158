"use client";
import { motion } from "framer-motion";
import { Check, ChevronLeft, ChevronRight, Sparkle } from "lucide-react";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { DEFAULT_REVISION_CARD_COLOR } from "../text-editor/extensions/revise-marks/Revise.mark";
import { cn } from "../utils/utils";
import { Button } from "./Button";
import { LucideIcon } from "./LucideIcon";
import { ReviseMarkDisplaySchema } from "./templates/revise/helpers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";

type Card = {
  question: string;
} & ReviseMarkDisplaySchema;

export const CardStack = ({
  allItems,
  items,
  offset,
  scaleFactor,
  onClose,
  handleNextCard,
  handlePreviousCard,
  updateReviseMark,
  activeCardIndex,
}: {
  allItems: {
    question: string;
    answer: string;
    color: string;
    id: string;
    retained: number;
    forgotten: number;
    revisionId: string;
  }[];
  items: Card[];
  activeCardIndex: number;
  handlePreviousCard: () => void;
  handleNextCard: () => void;
  offset?: number;
  scaleFactor?: number;
  onClose?: React.ReactNode;
  updateReviseMark: (
    revisionId: string,
    id: string,
    attributes: Record<string, unknown>
  ) => void;
}) => {
  const hasNext = activeCardIndex < allItems.length - 1;
  const hasPrevious = activeCardIndex > 0;

  return (
    <div className="relative h-[500px] w-[90vw] md:h-[500px] md:w-[35vw]">
      {items.map((card, index) => {
        return (
          <CardItem
            key={card.id}
            card={card}
            index={index}
            offset={offset}
            scaleFactor={scaleFactor}
            items={allItems}
            onClose={onClose}
            updateReviseMark={updateReviseMark}
            handleNextCard={handleNextCard}
            handlePreviousCard={handlePreviousCard}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            activeCardIndex={activeCardIndex}
          />
        );
      })}
    </div>
  );
};

const CardItem = ({
  card,
  index,
  offset,
  scaleFactor,
  onClose,
  items,
  updateReviseMark,
  handleNextCard,
  handlePreviousCard,
  hasNext,
  hasPrevious,
  activeCardIndex,
}: {
  card: Card;
  index: number;
  offset?: number;
  scaleFactor?: number;
  onClose?: React.ReactNode;
  items: Card[];
  updateReviseMark: (
    revisionId: string,
    id: string,
    attributes: Record<string, unknown>
  ) => void;
  handleNextCard: () => void;
  handlePreviousCard: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  activeCardIndex: number;
}) => {
  const CARD_OFFSET = offset || 10;
  const SCALE_FACTOR = scaleFactor || 0.06;

  const [showInsight, setShowInsight] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const colorToRender = `var(--revision-${card?.color || DEFAULT_REVISION_CARD_COLOR})`;

  const confidenceScore = card.retained
    ? card.retained / (card.retained + card.forgotten)
    : 0;

  const toggleShowInsight = () => {
    if (!showInsight) {
      updateReviseMark(card.revisionId, card.id, {
        forgotten: (card.forgotten + 1 || 0).toString(),
      });
    }

    setShowInsight(!showInsight);
  };

  const handleShowNext = () => {
    if (!showInsight) {
      updateReviseMark(card.revisionId, card.id, {
        retained: (card.retained + 1 || 0).toString(),
      });
    }

    cleanup();
    handleNextCard();
  };

  const handleShowPrevious = () => {
    if (!showInsight) {
      updateReviseMark(card.revisionId, card.id, {
        retained: (card.retained + 1 || 0).toString(),
      });
    }

    handlePreviousCard();
  };

  useHotkeys("N", handleShowNext);
  useHotkeys("P", handleShowPrevious);
  useHotkeys("S", () => toggleShowInsight());
  useHotkeys("I", () => setShowInfo(!showInfo));

  const cleanup = () => {
    setShowInsight(false);
    setShowInfo(false);
  };

  return (
    <motion.div
      key={card.id}
      className="absolute bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark h-[500px] w-[90vw] md:h-[500px] md:w-[35vw] rounded-3xl p-4 pt-12 md:pt-4 shadow-xl border-[0.5px] border-border-primary-light dark:border-border-primary-dark flex flex-col justify-between"
      style={{
        transformOrigin: "right center",
        // background: `var(--revision-${card?.color || DEFAULT_REVISION_CARD_COLOR})`,
      }}
      animate={{
        right: index * -CARD_OFFSET,
        scale: 1 - index * SCALE_FACTOR, // decrease scale for cards that are behind
        zIndex: items.length - index, //  decrease z-index for the cards that are behind
      }}
    >
      <div className="pt-4 pl-2 pr-4 md:pt-16 md:pb-4 md:pr-8 md:pl-8 flex flex-col h-full gap-4">
        <div className="flex flex-row flex-wrap items-center gap-1 absolute top-6 left-5">
          <span className="text-[14px] font-normal px-2 py-0.5 rounded-md bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark text-textColors-secondary-light dark:text-textColors-secondary-dark">
            Reflect
          </span>
          <LucideIcon icon={ChevronRight} size={16} />
          <div className="flex flex-row items-center gap-1">
            <LucideIcon icon={Sparkle} size={14} />
            {activeCardIndex + 1} / {items.length}
          </div>
          <LucideIcon icon={ChevronRight} size={16} />
          <Button variant="link" className="hover:no-underline pl-0 -ml-2">
            <TooltipProvider>
              <Tooltip delayDuration={200} defaultOpen={false}>
                <TooltipTrigger className="p-2">
                  <div className="flex flex-row items-center gap-1">
                    <span>{confidenceScore > 0.5 ? "🔥" : "⏳"}</span>
                    {confidenceScore.toFixed(1)}{" "}
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="bottom"
                  className="w-60 p-4 relative mt-1"
                >
                  <div className="absolute top-4 left-3 text-sm">
                    {confidenceScore > 0.5 ? "🔥" : "⏳"}
                  </div>
                  <div className="flex flex-col gap-2 w-full pl-2">
                    <div className="flex flex-row justify-between flex-wrap">
                      <div className="font-medium text-sm ml-2">
                        Confidence score
                      </div>
                      <div>{confidenceScore.toFixed(2)}</div>
                    </div>
                    <div className="flex flex-col gap-1 pl-2">
                      <p className="text-wrap text-left">Calculation</p>
                      <p className="text-left text-wrap text-textColors-secondary-light dark:text-textColors-secondary-dark">
                        Confidence score is calculated as the ratio of the
                        number of times a card was marked as retained to the
                        number of times it was marked as forgotten.
                      </p>
                      <span className="mt-2 text-wrap text-left text-xs text-textColors-muted-light dark:text-textColors-muted-dark">
                        Clicking on "View Insight" will mark the revision item
                        as forgotten.
                      </span>
                      <span className="mt-2 text-wrap text-left text-xs text-textColors-muted-light dark:text-textColors-muted-dark">
                        Moving to the previous/next flashcard assumes current
                        revision card to be retained.
                      </span>
                    </div>
                    <Button
                      size="sm"
                      variant="outline"
                      className="mt-4"
                      onClick={() => {
                        updateReviseMark(card.revisionId, card.id, {
                          retained: 1,
                          forgotten: 0,
                        });
                      }}
                    >
                      Reset score
                    </Button>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </Button>
        </div>
        {onClose}
        <motion.div
          className={cn("flex flex-row items-start gap-2 mt-12")}
          initial={{
            opacity: 0,
            y: 10,
          }}
          animate={
            showInsight
              ? {
                  opacity: 1,
                  y: -10,
                }
              : {
                  opacity: 1,
                  y: 10,
                }
          }
          transition={{
            duration: 0.4,
          }}
        >
          {card.question && (
            <span className="text-lg shrink line-clamp-3 transition-all duration-300 ease-in-out">
              {card.question}
            </span>
          )}
        </motion.div>
        {showInsight && (
          <motion.div
            className="flex flex-col items-start gap-2 h-[14rem] overflow-auto"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.4,
              delay: 0.4,
            }}
          >
            <span className="ease-in-out line-clamp-8 text-left text-textColors-secondary-light dark:text-textColors-secondary-dark">
              <LucideIcon
                icon={Check}
                size={18}
                style={{
                  stroke: colorToRender,
                }}
                className="mr-1 -mt-0.5"
              />
              {card.answer}
            </span>
          </motion.div>
        )}
      </div>
      <div className="flex flex-row items-center gap-4 justify-between p-2">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handlePreviousCard()}
          disabled={!hasPrevious}
        >
          <LucideIcon icon={ChevronLeft} size={18} />
        </Button>
        <Button
          variant="ghost"
          onClick={() => {
            toggleShowInsight();
          }}
        >
          View Insight
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleShowNext()}
          disabled={!hasNext}
        >
          <LucideIcon icon={ChevronRight} size={18} />
        </Button>
      </div>
    </motion.div>
  );
};
