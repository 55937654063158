import supabase from '../../utils/supabase'

export async function createNote({
  title,
  body,
  userId,
  parentId,
  level,
  template,
  description,
  isContentNote,
  icon,
}: {
  title: string
  body: string
  userId: string
  parentId: string
  level: number
  template: string
  description: string
  isContentNote: boolean
  icon: string
}) {
  const { data, error } = await supabase
    .from('notes')
    .insert({
      title,
      body,
      user_id: userId,
      parent_id: parentId ?? null,
      level,
      template,
      description,
      is_content_note: isContentNote,
      icon,
      status: `draft`,
    })
    .select()

  if (error) {
    throw new Error(error.message)
  }

  return data
}
