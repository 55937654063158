import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { ReactTyped } from "react-typed";
import { AiIcon } from "../../../business/icons/Icons";
import { Button } from "../../../ui-components/Button";
import { cn } from "../../../utils/utils";

export const ReflectUI = () => {
  const mockTextNote = `
  A black hole is a region of spacetime where gravity is so strong that nothing, including light and other electromagnetic waves, is capable of possessing enough energy to escape it. 
  `;

  const colorToRender = "var(--purple)";

  const [showInsight, setShowInsight] = useState(false);
  const [selected, setSelected] = useState(false);

  const handleShowInsightUI = () => {
    if (showInsight) return;

    setShowInsight(!showInsight);
    if (!selected) {
      setSelected(true);
    }
  };

  return (
    <div className="h-56 overflow-y-auto overflow-x-hidden">
      <div className="flex flex-row">
        <div
          className={cn(
            "p-2 flex flex-col gap-4 text-left bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark rounded-xl"
          )}
        >
          <div>
            <span
              className="py-1.5 rounded transition-colors duration-500 ease-linear box-decoration-clone p-1"
              style={
                selected
                  ? {
                      backgroundColor: "var(--hl-purple)",
                    }
                  : {}
              }
            >
              {mockTextNote}
            </span>
          </div>
          <AnimatePresence exitBeforeEnter>
            {showInsight ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.5, delay: 1 },
                }}
              >
                <div className="flex flex-col gap-2">
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className="text-indigo-500 hover:text-indigo-400 cursor-pointer"
                    onClick={() => {
                      setShowInsight(false);
                      setSelected(false);
                    }}
                  >
                    <AiIcon className="mr-1 fill-indigo-500 w-3.5 h-3.5 -mt-0.5" />
                    Why can't anything escape from a black hole?
                  </motion.p>

                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 2, ease: "easeInOut" }}
                    className="h-12"
                  >
                    <ReactTyped
                      backSpeed={50}
                      onBegin={function noRefCheck() {}}
                      onComplete={function noRefCheck() {}}
                      onDestroy={function noRefCheck() {}}
                      onLastStringBackspaced={function noRefCheck() {}}
                      onReset={function noRefCheck() {}}
                      onStart={function noRefCheck() {}}
                      onStop={function noRefCheck() {}}
                      onStringTyped={function noRefCheck() {}}
                      onTypingPaused={function noRefCheck() {}}
                      onTypingResumed={function noRefCheck() {}}
                      startWhenVisible
                      strings={[
                        `Because the gravity in a black hole is so strong that
                    nothing possesses enough energy to escape it.`,
                      ]}
                      startDelay={3000}
                      typeSpeed={10}
                      showCursor={false}
                      typedRef={function noRefCheck() {}}
                      className=""
                    />
                  </motion.p>
                </div>
              </motion.div>
            ) : (
              <Button
                variant="outline"
                className="w-fit sm:mt-2 md:mt-10 mb-2 gap-1 rounded-xl"
                onClick={() => handleShowInsightUI()}
              >
                <AiIcon fill={colorToRender} />
                <span className="font-normal">
                  Click to generate a reflection
                </span>
              </Button>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};
