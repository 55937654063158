import { Route, Switch } from 'react-router-dom'
import { BASE_URL } from '../NotesDashboardWrapper'
import { PlanSettings } from './PlanSettings'
import { ProfileForm } from './ProfileForm'

export const AccountSettingsRoutes = () => {
  return (
    <Switch>
      <Route path={`${BASE_URL}/settings/profile`}>
        <ProfileForm />
      </Route>
      <Route path={`${BASE_URL}/settings/billing`}>
        <div className="flex-1 lg:max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight">
            Billing Settings
          </h2>
          <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
            View & manage your billing settings.
          </p>
        </div>
      </Route>
      <Route path={`${BASE_URL}/settings/plan`}>
        <PlanSettings />
      </Route>
      <Route path={`${BASE_URL}/settings/notifications`}>
        <div className="flex-1 lg:max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight">
            Notifications Settings
          </h2>
          <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
            Manage your notifications settings.
          </p>
        </div>
      </Route>
    </Switch>
  )
}
