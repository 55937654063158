import dayjs from "dayjs";
import {
  FileText,
  Grid2X2,
  Loader,
  MoreVertical,
  StarOff,
  Trash2,
  Waves,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BlockItem } from "../ui-components/BlockItem";
import { Button } from "../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui-components/DropdownMenu";
import { LucideIcon } from "../ui-components/LucideIcon";
import { APP_PAGE_HEIGHT, ERROR_MESSAGE } from "../utils/constants";
import { TemplateEnum } from "../utils/types";
import { cn, DAYJS_MINI_FORMAT } from "../utils/utils";
import { TNotesList } from "./api/fetchNotes";
import { useDeleteNotes } from "./hooks/mutations/useDeleteNotes";
import { useUpdateNote } from "./hooks/mutations/useUpdateNote";
import { TNotesResponseWithChildren } from "./hooks/queries/useNotesList";

export const getIconForTemplate = ({
  templateType,
  iconSize = 14,
}: {
  templateType: TemplateEnum;
  iconSize?: number;
}) => {
  switch (templateType) {
    case TemplateEnum.STANDARD:
      return <FileText size={iconSize} />;
    case TemplateEnum.CHEATSHEET:
      return <Grid2X2 size={iconSize} />;
    default:
      return <FileText size={iconSize} />;
  }
};

export const NotesListRenderer = ({
  notes,
  handleNoteClick,
}: {
  notes: TNotesList;
  handleNoteClick?: ({ note }: { note: TNotesList[0] }) => void;
}) => {
  const { mutate: deleteNotes } = useDeleteNotes();
  const { mutateAsync: updateNote } = useUpdateNote({
    showToast: false,
  });

  return (
    <div>
      {notes.map((note) => {
        return (
          <div key={note.id}>
            <BlockItem
              className="border-b border-b-border-secondary-light dark:border-b-border-secondary-dark py-2 -mx-4"
              onClick={() => {
                handleNoteClick && handleNoteClick({ note });
              }}
            >
              <div className="flex flex-row items-center justify-between w-full">
                <div className="flex flex-row items-center gap-x-2">
                  <div
                    className={cn(
                      "hover:bg-transparent capitalize rounded-md text-xs px-2 py-0.5",
                      "border border-border-primary-light dark:border-border-primary-dark"
                    )}
                  >
                    {note.template}
                  </div>
                  <div className="truncate">{note.title}</div>
                </div>
                <div className="actions flex flex-row items-center gap-x-2 text-textColors-muted-light dark:text-textColors-muted-dark">
                  <p>{dayjs(note.updated_at).format(DAYJS_MINI_FORMAT)}</p>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="spirit" size="xs" className="w-5">
                        <MoreVertical size={14} />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="mr-2">
                      <DropdownMenuItem
                        onClick={() => {
                          updateNote({
                            id: note.id,
                            pinned: false,
                          });
                        }}
                      >
                        <LucideIcon icon={StarOff} size={14} />
                        Remove from favorites
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          deleteNotes({ noteIds: [note.id] });
                        }}
                      >
                        <LucideIcon icon={Trash2} size={14} />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </BlockItem>
          </div>
        );
      })}
    </div>
  );
};

export const NotesGridRenderer = ({
  notes,
  handleNoteClick,
}: {
  notes: TNotesList;
  handleNoteClick?: ({ note }: { note: TNotesList[0] }) => void;
}) => {
  const { mutate: deleteNotes } = useDeleteNotes();
  const { mutateAsync: updateNote } = useUpdateNote({
    showToast: false,
  });

  return (
    <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
      {notes.map((note) => {
        return (
          <div
            key={note.id}
            className="p-4 rounded border border-border-primary-light dark:border-border-primary-dark flex flex-col gap-y-4 hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark"
            onClick={() => {
              handleNoteClick && handleNoteClick({ note });
            }}
          >
            <div className="flex flex-row items-center justify-between">
              <p className="text-sm font-medium truncate">{note.title}</p>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="spirit" size="xs" className="w-5">
                    <MoreVertical size={14} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-2">
                  <DropdownMenuItem
                    onClick={() => {
                      updateNote({
                        id: note.id,
                        pinned: false,
                      });
                    }}
                  >
                    <LucideIcon icon={StarOff} size={14} />
                    Remove from favorites
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={() => {
                      deleteNotes({ noteIds: [note.id] });
                    }}
                  >
                    <LucideIcon icon={Trash2} size={14} />
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <p className="text-sm line-clamp-2 h-11 text-textColors-muted-light dark:text-textColors-muted-dark">
              {note.description}
            </p>
            <div className="flex flex-row justify-between">
              <span className={cn("capitalize rounded-md text-sm w-fit")}>
                {dayjs(note.created_at).format(DAYJS_MINI_FORMAT)}
              </span>
              <span
                className={cn(
                  "capitalize rounded-md text-xs px-2 py-0.5 w-fit",
                  "border border-border-primary-light dark:border-border-primary-dark"
                )}
              >
                {note.template}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const getShouldDisplayChildNotesBasedOnTemplate = ({
  templateType,
}: {
  templateType: TemplateEnum;
}) => {
  switch (templateType) {
    case TemplateEnum.STANDARD:
      return true;
    case TemplateEnum.CHEATSHEET:
      return false;
    default:
      return false;
  }
};

export const EmptyState = ({
  iconComponent,
  cta = `This requested page either does not exist or you do not have access to
  it.`,
}: {
  iconComponent?: JSX.Element;
  cta?: string;
}) => {
  return (
    <div className="grid place-items-center justify-center h-full">
      <div className="flex flex-col gap-y-1.5 items-center text-center">
        {iconComponent ?? <Waves size={64} strokeWidth="1px" />}
        <p className="text-textColors-muted-light dark:text-textColors-muted-dark w-72">
          {cta}
        </p>
      </div>
    </div>
  );
};

export const ErrorState = ({
  cta = ERROR_MESSAGE,
  action,
}: {
  cta?: string;
  action: () => void;
}) => {
  const history = useHistory();

  return (
    <div
      className={`flex flex-col items-center gap-y-2 ${APP_PAGE_HEIGHT} justify-center`}
    >
      <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
        {cta}
      </p>
      <div className="flex item-center gap-x-2">
        {Boolean(action) && <Button onClick={() => action()}>Retry</Button>}
        <Button onClick={() => history.goBack()} variant="outline">
          Head back
        </Button>
      </div>
    </div>
  );
};

export const AppLoader = ({
  description = `Loading content, please wait...`,
  className = "",
}: {
  description?: string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        `grid place-items-center justify-center ${APP_PAGE_HEIGHT}`,
        className
      )}
    >
      <div className="flex flex-row items-center gap-x-2 text-md text-textColors-secondary-light dark:text-textColors-secondary-dark">
        <Loader
          size={16}
          className="animate-spin transition-all duration-1200"
        />
        <span>{description}</span>
      </div>
    </div>
  );
};

export const addHeadingIds = ({ body }: { body: string }) => {
  const json = JSON.parse(body);

  const jsonContentWithHeadingIds = json.content.map((block: any) => {
    if (block.type === "heading" && block?.content?.[0]?.text) {
      block.attrs.id = `${convertLabelToId({ label: block.content[0].text })}`;
    }

    return block;
  });

  return {
    ...json,
    content: jsonContentWithHeadingIds,
  };
};

export const convertLabelToId = ({ label }: { label: string }) => {
  return label.trim().replaceAll(" ", "-").toLowerCase();
};

export const getNoteIdsForDelete = ({
  note,
}: {
  note: TNotesResponseWithChildren;
}) => {
  const getIdsForAllChildNodes = (note: TNotesResponseWithChildren) => {
    const ids: string[] = [];

    note.children.forEach((childNote) => {
      ids.push(childNote.id);
      ids.push(...getIdsForAllChildNodes(childNote));
    });

    return ids;
  };

  return [note.id, ...getIdsForAllChildNodes(note)];
};

export const InteractiveReviseButton = ({
  onClick,
  displayIcon,
}: {
  onClick: () => void;
  displayIcon: string;
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      setIsClicked(false);

      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <Button
      variant="outline"
      size="icon"
      className="text-2xl h-10 w-10"
      onClick={() => {
        setIsClicked(true);
        onClick();

        // Reset the button after 1 second
        timer.current = setTimeout(() => {
          setIsClicked(false);
        }, 5000);
      }}
    >
      {isClicked ? "🎉" : displayIcon}
    </Button>
  );
};
