import { Banner } from "../../ui-components/Banner";

export const EmptyNoteState = () => {
  return (
    <Banner
      label="No notes found"
      description={`You have not created any notes. Please start a new one with ⌥ + N`}
      action={null}
      status="info"
    />
  );
};
