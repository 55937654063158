import { Editor } from '@tiptap/core'
import { SuggestionProps } from '@tiptap/suggestion'
import {
  Blocks,
  Braces,
  Heading,
  Image,
  List,
  ListOrdered,
  ListTodo,
  SeparatorHorizontal,
  TextQuote,
} from 'lucide-react'
import React, { Component, FC, useState } from 'react'

import { twMerge } from 'tailwind-merge'
import { TNotesResponseWithChildren } from '../../../pages-v2/hooks/queries/useNotesList'
import { BlockItem } from '../../../ui-components/BlockItem'
import { Button } from '../../../ui-components/Button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../ui-components/Dialog'
import { DropdownMenuSeparator } from '../../../ui-components/DropdownMenu'
import { LucideIcon } from '../../../ui-components/LucideIcon'
import { cn } from '../../../utils/utils'
import { SnippetsChoser } from '../../components/SnippetsChoser'

export const getSuggestionIcon = (element: string) => {
  switch (element) {
    case 'Heading':
      return <Heading size={14} />
    case 'Quote':
      return <TextQuote size={14} />
    case 'Bullet List':
      return <List size={14} />
    case 'Numbered List':
      return <ListOrdered size={14} />
    case 'Code Block':
      return <Braces size={14} />
    case 'Image':
      return <Image size={14} />
    case 'Task List':
      return <ListTodo size={14} />
    case 'Separator':
      return <SeparatorHorizontal size={14} />
    case 'Snippet':
      return <Blocks size={14} />

    default:
      return null
  }
}

export class TextEditorCommandsView extends Component<SuggestionProps> {
  boxRef: any = React.createRef()

  state: {
    selectedIndex: number
    openSnippetsModal: boolean
  } = {
    selectedIndex: null,
    openSnippetsModal: false,
  }

  componentDidUpdate(oldProps: SuggestionProps) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0,
      })
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }

    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }

    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler() {
    const { selectedIndex } = this.state
    const totalItems = this.props.items.length

    if (selectedIndex === null) {
      return // No active selection to move up from
    }

    const newSelectedIndex =
      selectedIndex === 0 ? totalItems - 1 : selectedIndex - 1

    this.setState(
      {
        selectedIndex: newSelectedIndex,
      },
      () => {
        if (newSelectedIndex === totalItems - 1) {
          // If moving from the first to the last item, scroll to the end
          this.boxRef.current.scrollTop = this.boxRef.current.scrollHeight
        } else {
          this.scrollSelectedIndexItemIntoView(newSelectedIndex)
        }
      }
    )
  }

  downHandler() {
    this.setState(
      {
        selectedIndex:
          this.state.selectedIndex === null
            ? 0
            : (this.state.selectedIndex + 1) % this.props.items.length,
      },
      () => {
        this.scrollSelectedItemIntoView()
      }
    )
  }

  scrollSelectedItemIntoView() {
    const selectedItem = this.boxRef.current.querySelector(
      '.active'
    ) as HTMLElement
    if (selectedItem) {
      const itemBottom = selectedItem.offsetTop + selectedItem.offsetHeight
      const itemTop = selectedItem.offsetTop

      if (itemBottom > this.boxRef.current.scrollTop + 200) {
        // Scroll down if the item is below the visible area
        this.boxRef.current.scrollTop = itemBottom - 200
      } else if (itemTop < this.boxRef.current.scrollTop) {
        // Scroll up if the item is above the visible area
        this.boxRef.current.scrollTop = itemTop
      }
    }
  }

  scrollSelectedIndexItemIntoView(selectedIndex: number) {
    const selectedItem = this.boxRef.current?.querySelector(
      `.command-${selectedIndex}`
    ) as HTMLElement

    if (selectedItem) {
      const itemTop = selectedItem.offsetTop

      if (itemTop < this.boxRef.current.scrollTop) {
        // Scroll up if the item is above the visible area
        this.boxRef.current.scrollTop = itemTop
      }
    }
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex)
  }

  selectItem(index: number | null) {
    const item = this.props.items[index || 0]

    if (item.title === 'Snippet') {
      this.setState({
        openSnippetsModal: true,
      })
    }

    if (item) {
      this.props.command(item)
    }
  }

  render() {
    const { items: commands } = this.props
    const { openSnippetsModal } = this.state

    const selection = this.props.editor.view.state.selection
    const from = selection.$from.posAtIndex(0)
    // const to = selection.$from.posAtIndex(1)

    return (
      <>
        <div
          className={cn(
            'insert-menu z-50 min-w-[10rem] overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 text-popover-foreground shadow-md',
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            'flex flex-col gap-y-0.5'
          )}
          ref={this.boxRef}
        >
          {commands.map((command, index) => {
            const isHidden = command.title === 'Snippet'

            return (
              <BlockItem
                key={index}
                className={twMerge(
                  'flex items-center gap-2 p-1 pr-16',
                  `command-${index}`,
                  isHidden ? 'hidden md:flex' : ''
                )}
                onClick={() => this.selectItem(index)}
                {...command.attrs}
                variant={
                  index === this.state.selectedIndex ? 'active' : 'default'
                }
              >
                {getSuggestionIcon(command.title)}
                <div>{command.title}</div>
              </BlockItem>
            )
          })}
          {commands.length === 0 && (
            <div className="text-xs text-textColors-muted-light dark:text-textColors-muted-dark p-8">
              No results
            </div>
          )}
        </div>
        <Dialog
          open={openSnippetsModal}
          onOpenChange={(open) => {
            this.setState({ openSnippetsModal: open })
          }}
          defaultOpen={openSnippetsModal}
          modal
        >
          <DialogContent
            className="z-[99999] bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark min-w-[55vw] h-[70vh]"
            ref={document.body as any}
          >
            <DialogHeader>
              <DialogTitle className="flex items-center gap-1">
                <LucideIcon icon={Blocks} />
                Select a snippet
              </DialogTitle>
            </DialogHeader>
            <SnippetsMenuWrapper
              onClose={() => {
                this.setState({ openSnippetsModal: false })
              }}
              editor={this.props.editor}
              onAddSnippet={(snippetContent: string) => {
                // append the content in the editor dom
                this.props.editor.commands.insertContentAt(
                  from - 1,
                  JSON.parse(snippetContent || '{}'),
                  {
                    updateSelection: true,
                  }
                )
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

const SnippetsMenuWrapper: FC<{
  onClose: () => void
  onAddSnippet: (snippetContent: string) => void
  editor: Editor
}> = ({ onClose, onAddSnippet, editor }) => {
  const [selectedSnippet, setSelectedSnippet] =
    useState<TNotesResponseWithChildren>(null)

  const handleAddSnippet = ({ snippetContent }: { snippetContent: string }) => {
    const selection = editor.view.state.selection
    const from = selection.$from.posAtIndex(0)
    const to = selection.$from.posAtIndex(1)

    if (snippetContent) {
      editor.commands.deleteRange({ from, to })
      onAddSnippet(snippetContent)
      editor.chain().focus(from).run()
    }

    onClose()
  }

  return (
    <div className="flex flex-col gap-y-4 h-full w-[50vw]">
      <div className="flex flex-row gap-8 h-[calc(100%-152px)] w-[50vw]">
        <SnippetsChoser
          onSnippetSelect={setSelectedSnippet}
          selectedSnippet={selectedSnippet}
        />
      </div>
      <DropdownMenuSeparator />
      <div className="flex items-center gap-4 justify-end">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() =>
            handleAddSnippet({
              snippetContent: selectedSnippet?.body,
            })
          }
        >
          Add
        </Button>
      </div>
    </div>
  )
}
