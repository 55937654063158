import { extendTheme } from '@chakra-ui/react'
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'
import { Dict } from '@chakra-ui/utils'
import { DEFAULT_FONT_SIZE } from '../utils/constants'
import customImageCSS from './editorjs/custom-image'
import defaultEditorCSS from './editorjs/index'
import scrollbarCSS from './scrollbar'

// theme.js
const theme = extendTheme({
  styles: {
    global: (props: Dict<any>) => ({
      ...defaultEditorCSS(props),
      ...scrollbarCSS,
      ...customImageCSS,
      body: {
        bg: mode('light.primary', 'dark.primary')(props),
        fontSize: DEFAULT_FONT_SIZE,
        lineHeight: '1.5rem',
      },
      svg: {
        display: 'inline',
      },
      h1: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      h2: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      h3: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      h4: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      h5: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      h6: {
        fontSize: 'revert',
        fontWeight: 'revert',
      },
      '.chakra-breadcrumb__list-item:hover': {
        textDecoration: 'underline',
      },
      '.card': {
        // boxShadow: mode(
        //   '2px 2px 4px rgba(0, 0, 0, 0.1)',
        //   '2px 2px 4px rgba(255, 255, 255, 0.1)'
        // )(props),
        border: mode('1px solid #EBEBEB', '1px solid #202020')(props),
        borderRadius: '8px !important',
      },
      '.cheatsheet-card': {
        boxShadow: mode(
          '0px 2px 4px rgba(0, 0, 0, 0.1)',
          '0px 2px 4px rgba(255, 255, 255, 0.1)'
        ),
      },
      '.link:hover': {
        textDecoration: 'underline',
      },
      '.simpleBanner': {
        borderRadius: 'md',
        // color: mode("#007BFF", "#6876E0"),
        border: mode('1px solid #616161', '1px solid #6876E0'),
      },
      '.successBanner': {
        bg: mode('#EEF8F5', '#073312')(props),
        color: mode('#5DC673', '#5DC673')(props),
        border: '1px solid #5DC673',
      },
      '.brandBanner': {
        bg: mode('#eeecff', '#232443')(props),
        color: mode('#007BFF', '#004a9e')(props),
        border: '1px solid #007BFF',
      },
      '.ghostBanner': {
        bg: mode('white', 'dark.secondary')(props),
        border: mode(
          '1px solid #c1c1c1',
          '1px solid rgba(255, 255, 255, 0.2)'
        )(props),
      },
      '.pricingBoxBanner': {
        bg: mode('#eeecff', 'dark.secondary')(props),
        color: mode('#007BFF', '#6876E0')(props),
        border: mode(
          '1px solid #007BFF',
          '1px solid rgba(255, 255, 255, 0.2)'
        )(props),
      },
      '.githubBanner': {
        bg: mode('#fff', 'dark.secondary')(props),
        border: mode(
          '1px solid #0F0F0F',
          '1px solid rgba(255, 255, 255, 0.4)'
        )(props),
      },
      '.formBadge': {
        // bg: mode("#EAF5FE", "#1E344E")(props),
        // color: mode("#057AFC", "#057AFC")(props),
        border: '1px solid #d1d1d1',
        color: mode('#616161', '#b3b3b3')(props),
      },
      '.formBadge.active': {
        bg: mode('#EAF5FE', '#53a3fd')(props),
        color: mode('#057AFC', '#fff')(props),
        border: '1px solid #057AFC',
      },
      'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
        {
          WebkitBoxShadow: mode(
            `0 0 0 30px white inset !important`,
            `0 0 0 30px #1C1F27 inset !important`
          )(props),
          WebkitTextFillColor: mode('#1C1F27', '#d1d1d1')(props),
        },
      '.secondaryText': {
        color: mode('#333333', '#CCCCCC')(props),
      },
      '.tertiaryText': {
        color: mode('#555555', '#AAAAAA')(props),
      },
      '.mutedText': {
        color: mode('#90959D', '#8C8C8C')(props),
      },
      '.placeholder': {
        color: mode('#A9A9A9', '#D3D3D3')(props),
      },
      '.tabBtn': {
        _focus: {
          boxShadow: 0,
        },
      },
      // code styles for documentation.
      '.tiptap-editor-auto-save.wiki pre': {
        background: mode('light.secondary', 'dark.tertiary')(props),
        padding: '16px',
        borderRadius: '8px',
      },
      // Prosemirror
      'div.ProseMirror': {
        _focusVisible: {
          outline: 'none',
        },
        color: props.colorMode === 'dark' ? `#E0E0E0` : '#333333', // secondaryText
      },
      'div.ProseMirror > h1, div.ProseMirror > h2, div.ProseMirror > h3, div.ProseMirror > h4':
        {
          _focusVisible: {
            outline: 'none',
          },
          color: props.colorMode === 'dark' ? `#CCCCCC` : '#333333', // secondaryText
        },
      'div.ProseMirror > div > div.code-block': {
        position: 'relative',
      },
      'div.ProseMirror > .details': {
        background: mode('light.brandSoft', 'dark.brandSoft')(props),
        color: mode('light.brandSoftText', 'dark.brandSoftText')(props),
      },
      'div.ProseMirror > div > div.code-block > pre, div.ProseMirror > div > div.code-block':
        {
          minHeight: '1vh',
        },
      '.editor-image': {
        borderRadius: '4px',
      },
    }),
  },
  fontSizes: {
    md: DEFAULT_FONT_SIZE,
  },
  components: {
    Divider: {
      baseStyle: (props: { colorMode: string }) => ({
        borderColor:
          props.colorMode === 'dark'
            ? 'dark.secondaryBorder'
            : 'light.secondaryBorder',
      }),
    },
    Input: {
      baseStyle: {
        field: {
          _focus: {
            boxShadow: 'none',
            borderColor: 'inherit',
          },
          _focusVisible: {
            boxShadow: 'none',
            borderColor: 'inherit',
          },
          _hover: {
            boxShadow: 'none',
            borderColor: 'inherit',
          },
        },
      },
      sizes: {},
      defaultProps: {},
    },
    FormLabel: {
      baseStyle: {
        fontWeight: 'normal',
      },
    },
    Button: {
      variants: {
        solid: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? 'dark.secondary' : 'light.secondary',
          color: props.colorMode === 'dark' ? 'white' : 'black',
          borderRadius: 6,
          _hover: {
            bg:
              props.colorMode === 'dark' ? 'dark.secondary' : 'light.secondary',
          },
          _active: {
            bg:
              props.colorMode === 'dark' ? 'dark.secondary' : 'light.secondary',
          },
        }),
        ghostBtn: (props: any) => ({
          pointerEvents: 'none',
        }),
        cardBtn: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#39495D' : '#F4F7FF',
          color: props.colorMode === 'dark' ? '#B2C4FE' : '#8F9FD2',
          borderRadius: 6,
          pointerEvents: 'none',
        }),
        transparent: (props: { colorMode: string }) => ({
          bg: 'transparent',
          fontWeight: 400,
          color:
            props.colorMode === 'dark'
              ? 'dark.primaryText'
              : 'light.secondaryText',
          _hover: {
            bg: 'transparent',
            color:
              props.colorMode === 'dark'
                ? 'dark.primaryText'
                : 'light.secondaryText',
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
          _focusVisible: {
            outline: 'none',
          },
        }),
        success: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#2D9E64' : '#EEF8F5',
          color: props.colorMode === 'light' ? '#218856' : '#EEF8F5',
          borderRadius: 'md',
          border:
            props.colorMode === 'dark'
              ? '1px solid #073312'
              : '1px solid #5DC673',
          _hover: {
            _disabled: {
              bg: props.colorMode === 'dark' ? '#2D9E64' : '#EEF8F5',
              color: props.colorMode === 'light' ? '#218856' : '#EEF8F5',
              borderRadius: 'md',
            },
          },
        }),
        warning: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#d89614' : '#FFFAF0',
          color: props.colorMode === 'light' ? '#d89614' : '#FFFAF0',
          borderRadius: 'md',
        }),
        error: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'light' ? 'red.100' : 'red.400',
          // color: props.colorMode === "light" ? "#d89614" : "#FFFAF0",
          borderRadius: 'md',
        }),
        public: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#30A46C' : '#EEF8F5',
          color: props.colorMode === 'light' ? '#218856' : '#FFF',
          borderRadius: 'md',
          pointerEvents: 'none',
        }),
        private: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#FCEA5C' : '#FFFAF0',
          color: props.colorMode === 'light' ? '#d89614' : '#21201c',
          borderRadius: 'md',
          pointerEvents: 'none',
        }),
        user: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#8F9FD2' : '#F4F7FF',
          color: props.colorMode === 'light' ? '#8F9FD2' : '#F4F7FF',
          borderRadius: 'md',
        }),
        info: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#8F9FD2' : '#F4F7FF',
          color: props.colorMode === 'light' ? '#8F9FD2' : '#F4F7FF',
          borderRadius: 'md',
          pointerEvents: 'none',
        }),
        themedPurple: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? '#39495D' : '#B2C4FE',
          color: props.colorMode === 'light' ? '#39495D' : '#B2C4FE',
          borderRadius: 'md',
        }),
        brand: (props: { colorMode: string }) => ({
          bg: props.colorMode === 'dark' ? 'dark.brand' : 'light.brand',
          color: '#fff',
          borderRadius: 'md',
        }),
        bordered: (props: { colorMode: string }) => ({
          bg: 'transparent',
          borderRadius: 'md',
          border:
            props.colorMode === 'dark'
              ? '1px solid rgba(255, 255, 255, 0.3)'
              : '1px solid #0F0F0F',
        }),
        'inactive-tool': (props: { colorMode: string }) => ({
          bg: 'transparent',
          borderRadius: 'md',
          color: props.colorMode === 'light' ? '#999999' : '#b3b3b3',
          border:
            props.colorMode === 'dark'
              ? '1px solid #202020'
              : '1px solid #999999',
          _focusVisible: {
            outline: 'none',
            boxShadow: 'none',
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
          outline: 'none',
          boxShadow: 'none',
        }),
        'active-tool': (props: { colorMode: string }) => ({
          bg: props.colorMode === 'light' ? '#F2F2F5' : '#343434',
          borderRadius: 'md',
          color: props.colorMode === 'light' ? '#333333' : '#E0E0E0',
          border:
            props.colorMode === 'dark'
              ? '1px solid #202020'
              : '1px solid #333333',
          _focusVisible: {
            outline: 'none',
            boxShadow: 'none',
          },
          _focus: {
            outline: 'none',
            boxShadow: 'none',
          },
          outline: 'none',
          boxShadow: 'none',
        }),
      },
    },
    Popover: {
      parts: ['popper', 'content'],
      baseStyle: (props: { colorMode: string }) => ({
        popper: {
          background:
            props.colorMode === 'dark' ? 'dark.secondary' : 'light.primary',
          _focusVisible: {
            outline: 0,
          },
        },
        content: {
          background:
            props.colorMode === 'dark' ? 'dark.secondary' : 'light.primary',
          _focusVisible: {
            outline: 0,
          },
          _focus: {
            boxShadow: 0,
          },
        },
        arrow: {},
      }),
    },
    Menu: {
      parts: ['list', 'item', 'button'],
      baseStyle: (props: { colorMode: string }) => ({
        list: {
          background:
            props.colorMode === 'dark' ? 'dark.secondary' : 'light.primary',
        },
      }),
    },
    Accordion: {
      parts: ['container', 'button', 'panel', 'icon'],
      baseStyle: (props: any) => ({
        container: {
          padding: 0,
          border: 0,
        },
        button: {
          padding: 0,
          _focus: {
            boxShadow: 0,
          },
          _hover: {
            background: 'transparent',
          },
        },
      }),
    },
    Modal: {
      parts: ['dialog'],
      baseStyle: (props: { colorMode: string }) => ({
        dialog: {
          background: props.colorMode === 'dark' ? 'dark.secondary' : '#fff',
          boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
        },
      }),
    },
    Tabs: {
      parts: ['root', 'tab', 'tablist', 'tabpanel'],
      variants: {
        line: (props: { colorMode: string }) => ({
          tab: {
            _selected: {
              borderBottomColor:
                props.colorMode === 'dark' ? '#b2c4fe' : 'light.brand',
              borderBottomWidth: '2px',
              color: props.colorMode === 'dark' ? '#fff' : '#0F0F0F',
            },
          },
          tabpanel: {
            padding: 0,
          },
          tablist: {
            mb: '-1px',
          },
        }),
      },
    },
    Badge: {
      variants: {
        subtle: (props: Dict<any>) => ({
          background: 'transparent',
          border: mode('1px solid #2F5393', '1px solid #67A9ED')(props),
          color: mode('#2F5393', '#67A9ED')(props),
          p: 1,
        }),
      },
    },
    Text: {
      variants: {
        brand: (props: Dict<any> | StyleFunctionProps) => ({
          color: mode('light.brand', 'dark.brand')(props),
        }),
      },
    },
    Tag: {
      parts: ['container', 'label'],
      variants: {
        new: (props: Dict<any>) => ({
          container: {
            // #E37878
            color: mode('#9B2C2C', '#fff')(props),
            bg: mode('#FFF5F5', '#FC8181')(props),
          },
        }),
      },
    },
  },
  colors: {
    transparent: 'transparent',
    light: {
      primary: '#FFFFFF',
      secondary: '#F7F7F7',
      tertiary: '#F9FAFB',
      primaryText: '#000',
      tertiaryTextColor: '#555555',
      secondaryTextColor: '#333333',
      activeCard: '#F8F9FC',
      alertSuccess: 'teal.100',
      error: '#B00020',
      // brand
      brand: '#007BFF',
      brandSoft: '#E6F0FF',
      brandSoftText: '#007BFF',
      border: '#EEF1F4',
      borderHover: '#d0d0d0',
      secondaryBorder: '#e9e8ea',
      muted: '#90959D',
      successSoft: '#EFF9EE',
      successSoftText: '#367B3D',
    },
    dark: {
      primary: '#101010',
      secondary: '#181818',
      tertiary: '#232326',
      primaryText: '#E0E0E0',
      secondaryTextColor: '#F5F5F5',
      tertiaryTextColor: '#AAAAAA',
      modalItemHighlight: '#373737',
      activeCard: '#0D171B',
      error: '#cf6679',
      brand: '#004a9e',
      brandSoft: '#1A2C4D',
      brandSoftText: '#6BC1FE',
      brandHighlight: '#4D94B8',
      border: '#202020',
      secondaryBorder: '#2e2e32',
      borderHover: 'rgb(139, 148, 158)',
      muted: '#8C8C8C',
      successSoft: '#1B281D',
      successSoftText: '#73D484',
    },
    button: {
      hovered: '#18161B',
      focused: '#251F2D',
      pressed: '#231D29',
    },
    // ...
  },
})

export default theme
