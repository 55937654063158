import { cn } from "../utils/utils";

type ChipProps = {
  className: string;
};

export const Chip: React.FC<ChipProps> = ({ children, className }) => {
  return (
    <span
      className={cn(
        "border border-border-primary-light dark:border-border-primary-dark px-2 py-1 rounded-md w-fit",
        className
      )}
    >
      {children}
    </span>
  );
};
