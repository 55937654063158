import { createOpenAI } from "@ai-sdk/openai";
import { Database } from "../utils/database.types";
import { AIProviders } from "./hooks/ai/useFetchAiCompletions";

export const getIsActiveIntegration = ({
  integrations = [],
}: {
  integrations: Database["public"]["Tables"]["integrations"]["Row"][];
}) => {
  return integrations?.find((integration) => integration.active);
};

export const getIsOpenAIIntegration = ({
  integration,
}: {
  integration: Partial<Database["public"]["Tables"]["integrations"]["Row"]>;
}) => {
  if (!integration) return false;

  return (
    (integration.metadata as Record<string, unknown>)?.integrationType ===
    AIProviders.openai
  ) && integration;
};

export const getAIInstance = ({
  activeIntegration,
}: {
  activeIntegration: Partial<
    Database["public"]["Tables"]["integrations"]["Row"]
  >;
}) => {
  if (getIsOpenAIIntegration({ integration: activeIntegration })) {
    const openai = createOpenAI({ apiKey: activeIntegration.secret_key });

    return openai;
  }

  return null;
};
