const defaultEditorCSS = (props: any) => ({
  ".ce-block__content": {
    margin: 0,
    maxWidth: "95%",
  },
  ".ce-toolbar__content": {
    minWidth: "40vw",
    marginLeft: "1.5vw",
    marginRight: "5px",
  },
  ".ce-paragraph::placeholder": {
    marginLeft: "2vw",
  },
  ".cdx-marker": {
    backgroundColor: props.colorMode === "dark" ? "#536d13" : "#f0fab5",
    color: props.colorMode === "light" ? "#616161" : "#fff",
    padding: "0 4px 0 4px",
  },
  ".ce-code__textarea.cdx-input": {
    background: props.colorMode === "dark" && "#0F171B",
    color: props.colorMode === "dark" && "#fff",
    border: props.colorMode === "dark" && "1px solid rgba(82, 82, 82, 0.4)",
  },
  ".inline-code": {
    backgroundColor: props.colorMode === "dark" ? "#2a3645" : "#F2F3F4",
    color: props.colorMode === "light" ? "#616161" : "#fff",
  },
  ".editor-caption": {
    color: props.colorMode === "dark" && "#0F0F0F",
  },
});

export default defaultEditorCSS;
