import Code from '@tiptap/extension-code'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import Color from '@tiptap/extension-color'
import Dropcursor from '@tiptap/extension-dropcursor'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextStyle from '@tiptap/extension-text-style'
import Typography from '@tiptap/extension-typography'
import { ReactNodeViewRenderer } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import bash from 'highlight.js/lib/languages/bash'
import cpp from 'highlight.js/lib/languages/cpp'
import css from 'highlight.js/lib/languages/css'
import go from 'highlight.js/lib/languages/go'
import graphql from 'highlight.js/lib/languages/graphql'
import js from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import python from 'highlight.js/lib/languages/python'
import rust from 'highlight.js/lib/languages/rust'
import scss from 'highlight.js/lib/languages/scss'
import sql from 'highlight.js/lib/languages/sql'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
import { lowlight } from 'lowlight/lib/core'
import CodeBlockComponent from '../../editor-interfaces/tiptap/components/codeblock'
import { constructUrlForNote } from '../../pages-v2/notes-sidebar/services'
import { EmojiReplacer } from './emojis/EmojiReplacer'
import CustomHeading from './headings/HeadingNode'
import { Indent } from './indent/Indent'
import { NoteHyperlink } from './note-hyperlink/NoteHyperlinkList'
import { NoteHyperlinkRenderer } from './note-hyperlink/NoteHyperlinkRenderer'
import { getNoteHyperlinkSuggestions } from './note-hyperlink/NoteHyperlinkSuggestions'

lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)
lowlight.registerLanguage('bash', bash)
lowlight.registerLanguage('cpp', cpp)
lowlight.registerLanguage('go', go)
lowlight.registerLanguage('python', python)
lowlight.registerLanguage('graphql', graphql)
lowlight.registerLanguage('sql', sql)
lowlight.registerLanguage('json', json)
lowlight.registerLanguage('scss', scss)
lowlight.registerLanguage('rust', rust)

export const getBasicPlugins = ({
  placeholder = `Type "/" for commands`,
}: {
  placeholder?: string
}) => [
  Typography,
  EmojiReplacer,
  StarterKit.configure({
    codeBlock: false,
    heading: false,
    paragraph: false,
    code: false,
    dropcursor: false,
  }),
  Code,
  Paragraph,
  Dropcursor,
  CustomHeading,
  TextStyle,
  Color.configure({
    types: ['textStyle'],
  }),
  Highlight.configure({
    multicolor: true,
  }),
  Placeholder.configure({
    // Use a placeholder:
    // placeholder: 'Write something …',
    // Use different placeholders depending on the node type:
    placeholder: ({ node }) => {
      if (node.type.name === 'codeblock') {
        return 'Add Code Block'
      }

      return placeholder
    },
    includeChildren: true,
    emptyEditorClass: 'is-editor-empty',
    emptyNodeClass: 'my-custom-is-empty-class',
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: `editor-task-list`,
    },
  }),
  TaskItem.configure({
    nested: true,
    HTMLAttributes: {
      class: `editor-task-item`,
    },
  }).extend({
    draggable: true,
  }),
  Image.configure({
    inline: true,
    allowBase64: true,
    HTMLAttributes: {
      class: 'editor-image',
    },
  }),
  Indent.configure({
    types: ['listItem', 'paragraph'],
    minLevel: 0,
    maxLevel: 8,
  }),
  Link.configure({
    openOnClick: true,
    HTMLAttributes: {
      class: `editor-link`,
    },
  }),
  CodeBlockLowlight.extend({
    addNodeView() {
      return ReactNodeViewRenderer(CodeBlockComponent)
    },
    addAttributes() {
      return {
        ...this.parent?.(),
        language: {
          default: 'auto', // Default language is 'auto'
        },
      }
    },
  }).configure({
    lowlight,
  }),
]

export const getNoteHyperlinkPlugin = ({
  queryResults,
}: {
  queryResults: NoteHyperlink[]
}) => {
  return Mention.extend({
    renderHTML(props) {
      const { node } = props
      let id = node.attrs.id

      const url = constructUrlForNote({
        note: {
          parentPaths: id.parentPaths,
          id: id.id,
        },
      })

      return [
        'a',
        {
          class: 'note-hyperlink',
          href: url,
        },

        `@${node.attrs.id.title}`,
      ]
    },
    addNodeView() {
      return ReactNodeViewRenderer(NoteHyperlinkRenderer)
    },
  }).configure({
    HTMLAttributes: {
      class: 'note-hyperlink',
    },
    suggestion: {
      ...getNoteHyperlinkSuggestions({ queryResults }),
    },
  })
}

export const getLanguageLabel = ({ language }: { language: string }) => {
  switch (language) {
    case 'bash':
      return 'Bash'
    case 'cpp':
      return 'C++'
    case 'css':
      return 'CSS'
    case 'go':
      return 'Go'
    case 'graphql':
      return 'GraphQL'
    case 'html':
      return 'HTML'
    case 'js':
      return 'JavaScript'
    case 'json':
      return 'JSON'
    case 'python':
      return 'Python'
    case 'rust':
      return 'Rust'
    case 'scss':
      return 'SCSS'
    case 'sql':
      return 'SQL'
    case 'tex':
      return 'TeX'
    case 'ts':
      return 'TypeScript'
    default:
      return 'Auto'
  }
}
