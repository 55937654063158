import dayjs from "dayjs";
import { MoreVertical, Trash2 } from "lucide-react";
import { AppLoader } from "../../pages-v2/global-helpers";
import { useDeleteNotes } from "../../pages-v2/hooks/mutations/useDeleteNotes";
import {
  TNotesResponseWithChildren,
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../../pages-v2/hooks/queries/useNotesList";
import { useAuthStore } from "../../store/auth";
import { BlockItem } from "../../ui-components/BlockItem";
import { Button } from "../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { TemplateEnum } from "../../utils/types";
import { DAYJS_MINI_FORMAT } from "../../utils/utils";

type SnippetsMenuProps = {
  onSelect: (snippet: TNotesResponseWithChildren) => void;
  showActions: boolean;
};

export const SnippetsMenu: React.FC<SnippetsMenuProps> = ({
  onSelect,
  showActions,
}) => {
  const { userId } = useAuthStore();
  const {
    data: notes = [],
    isLoading: isLoadingNotes,
    isError: isErrorNotes,
  } = useNotesList({
    userId,
  });

  const { mutate: deleteNotes } = useDeleteNotes();

  if (isLoadingNotes || isErrorNotes) {
    return <AppLoader />;
  }

  const snippets = transformHeirarchicalNotesInFlatList({
    notesResponse: notes.filter(
      (note) => note.template === TemplateEnum.SNIPPET
    ),
  });

  return (
    <div className="w-full">
      {snippets.map((note) => {
        return (
          <div key={note.id}>
            <BlockItem
              className="border-b border-b-border-secondary-light dark:border-b-border-secondary-dark py-2 -mx-4"
              onClick={() => onSelect(note)}
            >
              <div className="flex flex-row items-center justify-between w-full gap-x-2">
                <p className="truncate">{note.title}</p>
                <div className="actions flex flex-row items-center gap-x-2 text-textColors-muted-light dark:text-textColors-muted-dark">
                  <p className="text-xs">
                    {dayjs(note.updated_at).format(
                      showActions ? DAYJS_MINI_FORMAT : "MMM DD, YY"
                    )}
                  </p>
                  {showActions && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="xs" className="w-5">
                          <MoreVertical size={14} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="mr-2">
                        <DropdownMenuItem
                          onClick={() => {
                            deleteNotes({ noteIds: [note.id] });
                          }}
                        >
                          <LucideIcon icon={Trash2} size={14} />
                          Delete snippet
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>
            </BlockItem>
          </div>
        );
      })}
    </div>
  );
};
