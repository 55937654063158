import { motion } from "framer-motion";
import { Book, CornerDownLeft } from "lucide-react";
import { useState } from "react";
import { BlockItem } from "../../../ui-components/BlockItem";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

const mockBacklinks = [
  {
    active: false,
    label: "Inorganic Chemistry",
  },
  {
    active: false,
    label: "Laws of Thermodynamics",
  },
  {
    active: false,
    label: "Complex Numbers",
  },
  {
    active: true,
    label: "Newton's Laws of Motion",
  },
];

export const BacklinksUI = () => {
  const [selectedBacklink, setSelectedBacklink] = useState(null);

  return (
    <div className="flex flex-col gap-y-3 p-4 justify-center mx-auto">
      <div className="flex flex-row items-center gap-x-2 justify-start">
        <div
          className={cn(
            `text-textColors-muted-light dark:text-textColors-muted-dark group`
          )}
        >
          {selectedBacklink ? (
            <div
              className="text-md flex items-center gap-1 hover:line-through hover:text-textColors-muted-light dark:hover:text-textColors-muted-dark cursor-default"
              onClick={() => {
                setSelectedBacklink(null);
              }}
            >
              <LucideIcon
                icon={Book}
                size={14}
                className="!stroke-text-tertiary-light dark:!stroke-text-tertiary-dark"
              />
              {selectedBacklink.label}
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <span className="font-medium">@</span>
              <span
                className={cn(
                  "animate-blink",
                  `before:content-[''] before:w-1 before:h-6 before:bg-text-textColors-muted-light dark:before:bg-text-muted-dark`,
                  "text-textColors-muted-light dark:text-textColors-muted-dark"
                )}
              >
                Add a backlink
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-y-0.5 rounded-md border bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark p-1 shadow-md outline-none w-64">
        {mockBacklinks.map((backlink, index) => {
          return (
            <BlockItem
              variant={backlink.active ? "active" : "default"}
              key={index}
              className="rounded-sm justify-between"
              onClick={() => setSelectedBacklink(backlink)}
            >
              <span className="truncate">{backlink.label}</span>
              {backlink.active && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.3,
                  }}
                >
                  <LucideIcon
                    icon={CornerDownLeft}
                    size={14}
                    className="stroke-text-textColors-muted-light dark:stroke-text-muted-dark"
                  />
                </motion.div>
              )}
            </BlockItem>
          );
        })}
      </div>
    </div>
  );
};
