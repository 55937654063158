import { Maximize2, Minimize2 } from 'lucide-react'
import { useRef, useState } from 'react'
import { z } from 'zod'
import { TNotesList } from '../../../pages-v2/api/fetchNotes'
import {
  NoteCreationForm,
  noteCreationFormSchema,
} from '../../../pages-v2/notes-sidebar/NoteCreationForm'
import { AppExtensions, TextEditor } from '../../../text-editor/TextEditor'
import { cn } from '../../../utils/utils'
import { Button } from '../../Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../Dialog'
import { Label } from '../../Label'
import { LucideIcon } from '../../LucideIcon'
import { Prose } from '../Prose'

enum NoteCreatorDialogSize {
  SMALL = 'max-w-lg',
  LARGE = 'max-w-xl',
}

export const CheatsheetEditorDialog = ({
  title,
  cta,
  note,
  defaultValues,
  description,
  onSubmit,
  isLoading,
  open,
  setOpen,
}: {
  title: string
  cta: string
  note: TNotesList[0]
  defaultValues: z.infer<typeof noteCreationFormSchema>
  onSubmit: ({
    values,
    body,
  }: {
    values: z.infer<typeof noteCreationFormSchema>
    body: string
  }) => void
  description: React.ReactNode
  isLoading: boolean
  open: boolean
  setOpen: (open: boolean, note: TNotesList[0]) => void
}) => {
  const editorRef = useRef(null)
  const [noteCreatorSize, setNoteCreatorSize] = useState(
    NoteCreatorDialogSize.LARGE
  )

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open, note)
        if (!open) {
          setTimeout(() => {
            setNoteCreatorSize(NoteCreatorDialogSize.LARGE)
          }, 200)
        }
      }}
    >
      <DialogContent className={noteCreatorSize}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div>
          <NoteCreationForm
            defaultValues={defaultValues}
            onSubmit={(values) => {
              onSubmit({
                values,
                body: JSON.stringify(editorRef.current?.getJSON()),
              })
            }}
            isIconEnabled={false}
            showLoader={isLoading}
            isDescriptionEnabled={false}
            isLayoutSelectionEnabled={false}
            additionalFields={
              <div className={cn('flex flex-col gap-y-4')}>
                <Label>Add content</Label>
                <div
                  className={cn(
                    'cheatsheet overflow-auto border border-border-secondary-light dark:border-border-secondary-dark rounded-md',
                    noteCreatorSize === NoteCreatorDialogSize.SMALL
                      ? 'h-40'
                      : 'h-80'
                  )}
                >
                  <Prose classNames="p-4">
                    <TextEditor
                      key={note?.id}
                      ref={editorRef}
                      onUpdate={() => {}}
                      initialData={note?.body}
                      appExtensions={[AppExtensions.HYPERLINK]}
                    />
                  </Prose>
                </div>
              </div>
            }
            customSubmitButton={
              <DialogFooter className="pt-4">
                <Button type="submit" loading={isLoading}>
                  {cta}
                </Button>
              </DialogFooter>
            }
            onCancel={() => setOpen(false, note)}
          />
        </div>
        <Button
          className="absolute top-4 right-12 h-6 w-6 !p-0.5"
          size="sm"
          variant="ghost"
          onClick={() => {
            if (noteCreatorSize === NoteCreatorDialogSize.SMALL) {
              setNoteCreatorSize(NoteCreatorDialogSize.LARGE)
            } else {
              setNoteCreatorSize(NoteCreatorDialogSize.SMALL)
            }
          }}
        >
          {noteCreatorSize === NoteCreatorDialogSize.SMALL ? (
            <LucideIcon icon={Maximize2} size={14} />
          ) : (
            <LucideIcon icon={Minimize2} size={14} />
          )}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
