import supabase from '../../utils/supabase'
import { UpdateUserArgs } from '../hooks/mutations/useUpdateUser'

export async function updateUser(params: UpdateUserArgs) {
  const { data, error } = await supabase
    .from('users')
    .update({
      username: params.username,
      name: params.name,
      avatar: params.avatar,
      bio: params.bio,
      url: params.url,
    })
    .eq('id', params.id)
    .select()

  if (error) throw error

  return data
}
