import { OpenAIProvider } from "@ai-sdk/openai";
import { createContext, FC, PropsWithChildren } from "react";

export type AIInstance = OpenAIProvider;

export const AIContext = createContext<{
  instance: AIInstance;
}>(null);

export const AIContextProvider: PropsWithChildren<
  FC<{ instance: AIInstance }>
> = ({ children, instance }) => {
  return (
    <AIContext.Provider value={{ instance }}>{children}</AIContext.Provider>
  );
};
