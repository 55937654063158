import { Box } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'sonner'
import PublicLayout from '../../layouts/public'
import { BASE_URL } from '../../pages-v2/NotesDashboardWrapper'
import { useAuthStore } from '../../store/auth'
import { ERROR_MESSAGE } from '../../utils/constants'
import supabase from '../../utils/supabase'

const ProviderSignin = () => {
  const history = useHistory()
  const { updateUser } = useAuthStore()

  const hydrateStoreInitially = useCallback(
    async (userId: string, email: string) => {
      const { data, error } = await supabase
        .from('users')
        .select('username')
        .eq('id', userId)
      if (error) {
        toast.error(ERROR_MESSAGE, {
          duration: 5000,
        })
      }
      if (data) {
        if (data.length > 0) {
          updateUser({ userId, email, username: data[0].username })
          history.push(BASE_URL)
        } else {
          // move to complete profile
          history.push('/complete-profile')
        }
      }
    },
    [updateUser, history]
  )

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      hydrateStoreInitially(session?.user?.id, session?.user?.email)
    })

    return () => {
      subscription?.unsubscribe()
    }
  }, [hydrateStoreInitially])

  return (
    <PublicLayout>
      <Box
        px={{ base: 2, lg: '30vw' }}
        display="grid"
        placeItems="center"
        pt={20}
      >
        <Box mt="2vh" fontSize="md" textAlign="center">
          You shall now be redirected to the dashboard in a few seconds, else
          you can try to redirect
          <Link
            to={BASE_URL}
            style={{ textDecoration: 'underline', marginLeft: '5px' }}
            className="link"
          >
            manually
          </Link>
          .
        </Box>
      </Box>
    </PublicLayout>
  )
}

export default ProviderSignin
