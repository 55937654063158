import { useHistory } from "react-router-dom";
import supabase from "../../utils/supabase";

import { zodResolver } from "@hookform/resolvers/zod";
import { Github } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsGoogle } from "react-icons/bs";
import { toast } from "sonner";
import { z } from "zod";
import { Layout } from "../../pages-v2";
import { Button } from "../../ui-components/Button";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { ERROR_MESSAGE } from "../../utils/constants";

const signupSchema = z
  .object({
    email: z.string().email(),
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
  })
  .refine((input) => input.password === input.confirmPassword, {
    message: "Passwords do not match",
  });

const Signup = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof signupSchema>>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = form;
  const { email, password, confirmPassword } = form.watch();

  const signUpWithGithub = async () => {
    setIsLoading(true);
    await supabase.auth.signInWithOAuth({
      provider: "github",
      options: {
        redirectTo: `${process.env.REACT_APP_DEPLOY_ENDPOINT}/complete-profile`,
        scopes: "user",
      },
    });
    setIsLoading(false);
  };

  const signUpWithGoogle = async () => {
    setIsLoading(true);
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: `${process.env.REACT_APP_DEPLOY_ENDPOINT}/complete-profile`,
      },
    });
    setIsLoading(false);
  };

  const onSubmit = async (values: z.infer<typeof signupSchema>) => {
    const { password, email } = values;

    const {
      data: { user },
      error: signupError,
    } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_DEPLOY_ENDPOINT}/complete-profile`,
      },
    });

    if (signupError) {
      toast.error(ERROR_MESSAGE, {
        duration: 5000,
      });

      throw signupError;
    }

    if (!user) throw new Error("query unsuccessful");

    history.push("/email-verify", { state: email });
  };

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full grid place-items-center md:mt-0 mt-8"
      >
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium">Create an Account</h2>
            <p className="text-sm">Let's get started! 🤩</p>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <Button
              className="border border-border-primary-light dark:border-border-primary-dark rounded-sm justify-center flex"
              onClick={() => {
                signUpWithGithub();
              }}
              variant="outline"
              type="button"
            >
              <div className="flex items-center gap-x-2 font-medium">
                <Github size={14} />
                Continue with Github
              </div>
            </Button>
            <Button
              className="border border-border-primary-light dark:border-border-primary-dark rounded-sm justify-center flex"
              onClick={() => {
                signUpWithGoogle();
              }}
              autoFocus={false}
              id="github"
              variant="outline"
              type="button"
            >
              <div className="flex items-center gap-x-2 font-medium">
                <BsGoogle size={14} />
                Continue with Google
              </div>
            </Button>
          </div>
          <div className="border-t border-border-primary-light dark:border-border-primary-dark relative">
            <div className="absolute -top-3 text-sm left-[36%] translate-x-[36%] z-10 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark px-4 text-textColors-muted-light dark:text-textColors-muted-dark">
              or
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="Your email"
              value={email}
              name="email"
              {...register("email", { required: true })}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              placeholder="Your password"
              value={password}
              type="password"
              name="password"
              className="max-w-full"
              {...register("password", { required: true })}
              size={36}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="password">Confirm Password</Label>
            <Input
              id="confirm-password"
              placeholder="Confirm your password"
              value={confirmPassword}
              type="password"
              name="confirmPassword"
              className="max-w-full"
              {...register("confirmPassword", { required: true })}
              size={36}
            />
          </div>
          <DropdownMenuSeparator />
          <Button type="submit" loading={isLoading || isSubmitting}>
            Create an account
          </Button>
          <div className="text-sm flex flex-row items-center gap-x-1">
            <span>Have an account?</span>
            <Button
              variant="link"
              className="p-0 gap-x-0.5"
              onClick={() => {
                history.push("/login");
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Signup;
