import { useQuery } from 'react-query'
import { GptIntegrationModel } from '../../integrations/GptIntegrationForm'
import { fetchModelList } from './api/fetchModelList'

export const useOpenAIModelList = ({
  apiKey,
  enabled,
}: {
  apiKey: string
  enabled: boolean
}) => {
  return useQuery(['openai-models'], () => fetchModelList({ apiKey }), {
    enabled: !!apiKey && enabled,
    select: (data: { data: GptIntegrationModel[] }) => data.data,
  })
}
