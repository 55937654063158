import { Database } from '../../utils/database.types'
import supabase from '../../utils/supabase'

export async function deleteIntegration({
  integrationId,
}: {
  integrationId: Database['public']['Tables']['integrations']['Row']['id']
}) {
  const { data, error } = await supabase
    .from('integrations')
    .delete()
    .match({ id: integrationId })
    .select()

  if (error) {
    throw new Error(error.message)
  }

  return data
}
