import supabase from '../../utils/supabase'
import { UpdateNotePayload } from '../hooks/mutations/useUpdateNote'

export async function updateNote(props: UpdateNotePayload) {
  const { data, error } = await supabase
    .from('notes')
    .update({
      ...props,
    })
    .eq('id', props.id)
    .select()

  if (error) {
    throw new Error(error.message)
  }

  return data
}
