import { CheckCheck, RefreshCcw, X } from "lucide-react";
import { FC } from "react";
import { ReactTyped } from "react-typed";
import { Button } from "../../../ui-components/Button";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { Separator } from "../../../ui-components/Separator";
import { AiLottie } from "./AiLottie";

export const AIResultSingleView: FC<{
  aiRevisionMarkers: { question: string; answer: string }[];
  onSubmit: (results: { question: string; answer: string }[]) => void;
  onDelete: () => void;
  onRetry: () => void;
}> = ({ aiRevisionMarkers, onDelete, onSubmit, onRetry }) => {
  // there is one result
  const { question, answer } = aiRevisionMarkers?.[0];

  return (
    <div className="flex flex-col gap-4">
      <div className="mt-[0px] flex flex-col items-center">
        <AiLottie />
        <div className="flex gap-1 items-baseline text-textColors-muted-light dark:text-textColors-muted-dark">
          <span className="font-medium text-textColors-muted-light dark:text-textColors-muted-dark -mt-4">
            Here's the generated reflection
          </span>
          <div className="flex flex-row gap-1">
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce"></div>
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.3s]"></div>
            <div className="size-1 rounded-full bg-textColors-muted-light dark:bg-textColors-muted-dark animate-bounce [animation-delay:-.5s]"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-textColors-background-light dark:text-textColors-background-dark text-right flex flex-col gap-1">
          <div className="flex flex-col items-end gap-1 justify-end items-end self-end">
            <span className="w-4/5 text-left w-fit border border-border-primary-light rounded-md px-2 py-1 dark:border-border-primary-dark">
              {question}
            </span>
            {/* <span className="text-textColors-muted-light dark:text-textColors-muted-dark text-sm">
            Edit
          </span> */}
          </div>
        </div>
        <div className="text-textColors-secondary-light dark:text-textColors-secondary-dark">
          <ReactTyped
            backSpeed={50}
            onBegin={function noRefCheck() {}}
            onComplete={function noRefCheck() {}}
            onDestroy={function noRefCheck() {}}
            onLastStringBackspaced={function noRefCheck() {}}
            onReset={function noRefCheck() {}}
            onStart={function noRefCheck() {}}
            onStop={function noRefCheck() {}}
            onStringTyped={function noRefCheck() {}}
            onTypingPaused={function noRefCheck() {}}
            onTypingResumed={function noRefCheck() {}}
            startWhenVisible
            strings={[answer]}
            typeSpeed={10}
            showCursor={false}
            typedRef={function noRefCheck() {}}
            className="leading-7"
          />
        </div>
      </div>
      <div className="absolute bottom-20 left-0 size-12 w-full">
        <div className="bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark blur-lg h-32 w-full z-5"></div>
      </div>
      <div className="absolute z-10 bottom-8 left-[50%] -translate-x-1/2 flex flex-row items-center gap-4 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark px-3 py-2 rounded-md">
        <Button onClick={onRetry} variant="ghost" size="icon">
          <LucideIcon icon={RefreshCcw} />
        </Button>
        <Separator
          orientation="vertical"
          className="h-8 !border-border-primary-dark"
        />
        <Button
          onClick={() => {
            onSubmit([{ question, answer }]);
          }}
          variant="ghost"
          size="icon"
        >
          <LucideIcon icon={CheckCheck} />
        </Button>
        <Separator
          orientation="vertical"
          className="h-8 !border-border-primary-dark"
        />
        <Button onClick={onDelete} variant="ghost" size="icon">
          <LucideIcon icon={X} />
        </Button>
      </div>
    </div>
  );
};
