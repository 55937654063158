import { useAuthStore } from "../../store/auth";
import { useGetIntegrations } from "../hooks/queries/useGetIntegration";

export const useActiveIntegration = () => {
  const { userId } = useAuthStore();
  const integrationsQuery = useGetIntegrations({ userId });

  const activeIntegration =
    integrationsQuery.data?.find((integration) => integration.active) || null;

  return {
    ...integrationsQuery,
    data: activeIntegration,
  };
};
