import { zodResolver } from '@hookform/resolvers/zod'
import { Fingerprint } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { z } from 'zod'
import { Layout } from '../../pages-v2'
import { Button } from '../../ui-components/Button'
import { DropdownMenuSeparator } from '../../ui-components/DropdownMenu'
import { Input } from '../../ui-components/Input'
import { Label } from '../../ui-components/Label'
import supabase from '../../utils/supabase'

// const passwordSchema = Yup.object().shape({
//   password: Yup.string()
//     .required('Required!')
//     .matches(
//       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
//       'Password validation failed'
//     ),
//   confirmPassword: Yup.string().oneOf(
//     [Yup.ref('password'), null],
//     'Passwords must match'
//   ),
// })

const changePasswordSchema = z
  .object({
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
  })
  .refine((input) => input.password === input.confirmPassword, {
    message: 'Passwords do not match',
  })

export default function ChangePassword() {
  const { state } = useLocation()
  const history = useHistory()

  const form = useForm<z.infer<typeof changePasswordSchema>>({
    resolver: zodResolver(changePasswordSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const { handleSubmit, register } = form
  const { password, confirmPassword } = form.watch()

  const onSubmit = async (values: z.infer<typeof changePasswordSchema>) => {
    const { error, data } = await supabase.auth.updateUser({
      password: values?.password,
    })

    if (error || !data) {
      history.push('/error', {
        state: {
          errorMsg:
            error?.message ||
            'We were unable to reset your password, please check the reset link and try again later',
        },
      })
    } else {
      history.push('/login')
    }
  }

  // push to custom page
  if (!state) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            errorMsg:
              'The reset link has expired, please request for a new one again',
          },
        }}
      />
    )
  }

  // push to custom page
  if (!(state as any)?.access_token) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            errorMsg:
              'The reset link has expired, please request for a new one again',
          },
        }}
      />
    )
  }

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full grid place-items-center"
      >
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <Button
            className="pointer-events-none h-12 w-12 p-0"
            variant="outline"
          >
            <Fingerprint size={20} />
          </Button>
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium">Change password</h2>
            <p className="text-sm">
              Enter a new strong password for your account
            </p>
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              placeholder="Your password"
              value={password}
              type="password"
              name="password"
              className="max-w-full"
              {...register('password', { required: true })}
              size={36}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="password">Confirm Password</Label>
            <Input
              id="confirm-password"
              placeholder="Confirm your password"
              value={confirmPassword}
              type="password"
              name="confirmPassword"
              className="max-w-full"
              {...register('confirmPassword', { required: true })}
              size={36}
            />
          </div>
          <DropdownMenuSeparator />
          <Button type="submit" className="gap-2">
            Change password
          </Button>
        </div>
      </form>
    </Layout>
  )
}
