import { AiFillQuestionCircle, AiFillStar } from "react-icons/ai";
import { BsFolderFill, BsPeopleFill } from "react-icons/bs";
import { IoRocketSharp } from "react-icons/io5";
import { RiBook2Fill } from "react-icons/ri";
import {
  AvatarCap,
  AvatarDazed,
  AvatarDope,
  AvatarKid,
  AvatarMusic,
  AvatarSerene,
  AvatarSocial,
  AvatarWork,
} from "../business/icons/Icons";
import { TemplateEnum } from "./types";

export const CARD_BORDER = "1px solid rgba(0, 0, 0, 0.2)";
export const SEARCH_TOPICS_LIMIT = 20;
export const SEARCH_USERS_LIMIT = 5;
export const TEMPLATES = [
  {
    template_name: TemplateEnum.STANDARD,
    description:
      "Use the cheatsheet template to create concise notes for revision and quick reference",
  },
  {
    template_name: TemplateEnum.CHEATSHEET,
    description:
      "Use the cheatsheet template to create concise notes for revision and quick reference",
  },
];

export const SUPPORTED_EMOJIS = [
  "🎯",
  "🚀",
  "🔮",
  "🎉",
  "⏳",
  "📭",
  "📌",
  "🔭",
];

export const SUPPORTED_ICONS: { type: string; icon: any }[] = [
  { type: "question", icon: <AiFillQuestionCircle /> },
  { type: "doc", icon: <RiBook2Fill /> },
  { type: "people", icon: <BsPeopleFill /> },
  { type: "folder", icon: <BsFolderFill /> },
  { type: "star", icon: <AiFillStar /> },
  { type: "rocket", icon: <IoRocketSharp /> },
];

export const AvatarItems = [
  {
    icon: "🦊",
    name: "Happy Fox",
  },
  {
    icon: "🦁",
    name: "Majestic Lion",
  },
  {
    icon: "🐻",
    name: "Friendly Bear",
  },
  {
    icon: "🐼",
    name: "Cute Panda",
  },
  {
    icon: "🦄",
    name: "Magical Unicorn",
  },
  {
    icon: "🦉",
    name: "Wise Owl",
  },
  {
    icon: "🐬",
    name: "Playful Dolphin",
  },
  {
    icon: "🦋",
    name: "Graceful Butterfly",
  },
];

export const FORM_WIDTH = {
  base: "100%",
  md: "80%",
  lg: "40vw",
  "2xl": "30vw",
};
export const DEFAULT_EDITOR_CONTENT = `{"type":"doc","content":[{"type":"paragraph"}]}`;
export const DEFAULT_EDITOR_CONTENT_WITH_INDENT = `{"type":"doc","content":[{"type":"paragraph","attrs":{"indent":null}}]}`;

// also change in App.scss
export const DEFAULT_FONT_SIZE = "0.9375rem";

export enum AccessType {
  READ = "read",
  EDIT = "edit",
}

export const ERROR_MESSAGE = `An unexpected error has occured, please try again!`;

export const IS_NATIVE = navigator.userAgent.indexOf("Electron") >= 0;

export const PERSISTED_NOTES_STORAGE_KEY = "persisted-notes";

export const APP_PAGE_HEIGHT = `h-[calc(100vh-50px)]`;

export const FREE_PLAN_MAX_NOTES = 15;

export const KeyboardShortCuts: Array<{
  label: string;
  mappings: string[];
  group?: "accessibility" | "navigation";
}> = [
  {
    label: "Toggle theme",
    mappings: ["option", "T"],
    group: "accessibility",
  },
  {
    label: "Create a new note",
    mappings: ["option", "N"],
    group: "accessibility",
  },
  {
    label: "Revise a note",
    mappings: ["option", "R"],
    group: "accessibility",
  },
  {
    label: "Forward",
    mappings: ["Cmd", "]"],
    group: "navigation",
  },
  {
    label: "Back",
    mappings: ["Cmd", "["],
    group: "navigation",
  },
  {
    label: "Go to first note",
    mappings: ["F1"],
    group: "navigation",
  },
  {
    label: "Go to Settings",
    mappings: ["option", "S"],
    group: "navigation",
  },
  {
    label: "Go to Profile",
    mappings: ["option", "P"],
    group: "navigation",
  },
];

export const AvatarsMap = {
  "avatar-dope": <AvatarDope className="h-12 w-12" />,
  "avatar-work": <AvatarWork className="h-12 w-12" />,
  "avatar-music": <AvatarMusic className="h-12 w-12" />,
  "avatar-kid": <AvatarKid className="h-12 w-12" />,
  "avatar-serene": <AvatarSerene className="h-12 w-12" />,
  "avatar-cap": <AvatarCap className="h-12 w-12" />,
  "avatar-social": <AvatarSocial className="h-12 w-12" />,
  "avatar-dazed": <AvatarDazed className="h-12 w-12" />,
};

export const DefaultAvatar = Object.keys(AvatarsMap)[0];
