import React from "react";
import { Center, Text } from "@chakra-ui/layout";
import PublicLayout from "../../layouts/public";
import { Link } from "react-router-dom";
import { BiCheckCircle } from "react-icons/bi";

const EmailVerificationSuccess = () => {
  return (
    <PublicLayout>
      <Center mt="10vh" fontSize="5rem" color="green">
        <BiCheckCircle />
      </Center>
      <Center>
        <Text maxW="60%" mt={4}>
          Your email has been verified successfully. Click{" "}
          <Link to="/login" style={{ textDecoration: "underline" }}>
            here
          </Link>{" "}
          to login.
        </Text>
      </Center>
    </PublicLayout>
  );
};

export default EmailVerificationSuccess;
