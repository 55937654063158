import { cva, VariantProps } from "class-variance-authority";
import { forwardRef } from "react";
import { cn } from "../utils/utils";

const blockItemVariants = cva(
  "relative flex cursor-default border border-transparent items-center rounded-sm px-4 py-1.5 text-sm outline-none transition-colors gap-x-1.5 truncate",
  {
    variants: {
      variant: {
        default: "hover:bg-bg-secondary-light dark:hover:bg-bg-secondary-dark",
        secondary: "hover:bg-bg-tertiary-light dark:hover:bg-bg-tertiary-dark",
        active: "bg-bg-secondary-light dark:bg-bg-secondary-dark",
        activeSecondary: "bg-bg-tertiary-light dark:bg-bg-tertiary-dark",
        disabled: "bg-transparent dark:bg-transparent",
        interactive:
          "transition-colors duration-400 bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark border border-transparent",
        interactiveDefault:
          "transition-colors duration-400 border border-transparent hover:border-[#A6BFF9] dark:hover:border-[#2655A2]",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BlockItemProps
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    VariantProps<typeof blockItemVariants> {
  children: React.ReactNode;
}

export const BlockItem = forwardRef<HTMLButtonElement, BlockItemProps>(
  ({ variant, children, className, ...props }, ref) => {
    return (
      <div className={cn(blockItemVariants({ variant, className }))} {...props}>
        {children}
      </div>
    );
  }
);

BlockItem.displayName = "BlockItem";
