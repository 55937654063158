import { cn } from '../utils/utils'
import { Button } from './Button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip'

export const ShortcutKey = ({
  children,
  tooltipLabel,
}: {
  children: React.ReactNode
  tooltipLabel?: string
}) => {
  const content = (
    <Button
      variant="secondary"
      size="icon"
      className="text-xs text-[#999999] dark:text-[#999999] h-5 w-5 rounded-md bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark stroke-1 border border-border-primary-light dark:border-border-primary-dark"
    >
      {children}
    </Button>
  )

  if (tooltipLabel) {
    return (
      <TooltipProvider>
        <Tooltip defaultOpen={false}>
          <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
            {content}
          </TooltipTrigger>
          <TooltipContent
            className={cn(
              'flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark',
              'text-textColors-background-light dark:text-textColors-background-dark',
              'border border-border-primary-light/80 dark:border-border-primary-dark/80'
            )}
          >
            {tooltipLabel}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return content
}

export const Shortcut = ({
  trigger,
  label,
  shortcutKey,
}: {
  trigger: React.ReactNode
  label: string
  shortcutKey: string
}) => {
  return (
    <TooltipProvider>
      <Tooltip defaultOpen={false}>
        <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
          {trigger}
        </TooltipTrigger>
        <TooltipContent
          className={cn(
            'flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark',
            'text-textColors-background-light dark:text-textColors-background-dark',
            'border border-border-primary-light/80 dark:border-border-primary-dark/80'
          )}
        >
          {label}
          {shortcutKey && <ShortcutKey>{shortcutKey}</ShortcutKey>}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
