import supabase from '../../utils/supabase'

export type TNotesList = Array<{
  id: string
  title: string
  template: string
  created_at: string
  updated_at: string
  level: number
  parent_id: string
  pinned: boolean
  description: string
  users: {
    id: string
    name: string
    avatar: string
  }
  body: string
  is_content_note: boolean
  icon: string
  status: 'archived' | 'draft' | 'published'
}>

export async function fetchNotes({
  userId,
}: {
  userId: string
}): Promise<TNotesList> {
  const { data, error } = await supabase
    .from('notes')
    .select(
      `
        id,
        title,
        template,
        created_at,
        updated_at,
        level,
        parent_id,
        pinned,
        description,
        users (
          id,
          name,
          avatar
        ),
        body,
        is_content_note,
        icon,
        status
      `
    )
    .eq('user_id', userId)
    .order('created_at', { ascending: true })

  if (error) {
    throw new Error(error.message)
  }

  return data
}
