import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TNotesList } from '../../../pages-v2/api/fetchNotes'
import { DropdownMenuItem } from '../../DropdownMenu'
import { Input } from '../../Input'
import { constructCheatsheetUrl } from './CheatsheetList'

export const CheatsheetFinder = ({ notes = [] }: { notes: TNotesList }) => {
  const [searchTerm, setSearchTerm] = useState('')

  const filteredNotes = useMemo(
    () =>
      notes.filter((note) => {
        return note.title.toLowerCase().includes(searchTerm.toLowerCase())
      }),
    [notes, searchTerm]
  )

  const router = useHistory()

  const input = useMemo(
    () => (
      <div
        className="sticky top-0 z-10 p-1"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          placeholder="Search"
          onChange={(e) => {
            setSearchTerm(e.target.value)
          }}
          value={searchTerm}
          className="h-7 text-sm rounded-md px-2 shadow-none"
        />
      </div>
    ),
    [searchTerm]
  )

  const notesToDisplay = useMemo(
    () => (
      <div>
        {Boolean(filteredNotes.length) ? (
          filteredNotes.map((note) => {
            return (
              <DropdownMenuItem
                key={note.id}
                onClick={() =>
                  router.push(
                    constructCheatsheetUrl(
                      router.location.pathname,
                      router.location.search,
                      note.id
                    )
                  )
                }
              >
                {note.title}
              </DropdownMenuItem>
            )
          })
        ) : (
          <p className="line-clamp-2 text-textColors-muted-light dark:text-textColors-muted-dark p-2 pb-3 text-xs">
            No notes found
          </p>
        )}
      </div>
    ),
    [filteredNotes, router]
  )

  return (
    <div className="flex flex-col gap-y-0.5 ">
      <div className="p-1">{input}</div>
      {notesToDisplay}
    </div>
  )
}
