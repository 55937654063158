import { useColorMode } from "@chakra-ui/react";
import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { Code } from "lucide-react";
import { useContext, useRef, useState } from "react";
import { getLanguageLabel } from "../../../text-editor/extensions/Plugins";
import { TextEditorContext } from "../../../text-editor/TextEditor";
import { Button } from "../../../ui-components/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui-components/DropdownMenu";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { Copy } from "../../../utils/copy";
import {
  capitalizeFirstLetter,
  cn,
  transformEditorCodeblock,
} from "../../../utils/utils";

const CodeBlockComponent = ({
  node: {
    attrs: { language: defaultLanguage },
    content,
  },
  updateAttributes,
  extension,
}: {
  node: {
    attrs: { language: string };
    content: any;
  };
  updateAttributes: (attributes: { language: string }) => void;
  extension: {
    options: {
      lowlight: {
        listLanguages: () => string[];
      };
    };
  };
  content: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode } = useColorMode();
  const codeblockRef = useRef(null);

  const { editorRef } = useContext(TextEditorContext);

  const supportedLanguages =
    extension.options.lowlight
      .listLanguages()
      .sort((a, b) => (defaultLanguage === a ? -1 : 1)) ?? [];

  return (
    <NodeViewWrapper className="code-block">
      <div className="editor-code-block relative" ref={codeblockRef}>
        <div
          className="absolute top-2 right-3 flex flex-row items-center gap-x-2"
          contentEditable={false}
        >
          <DropdownMenu open={isOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="xs"
                className="p-1 !bg-transparent gap-x-1"
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                <LucideIcon icon={Code} size={14} />
                {capitalizeFirstLetter(defaultLanguage)}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              onInteractOutside={() => {
                setIsOpen(false);
              }}
              className="h-[200px] overflow-auto z-[100]"
              containerRef={editorRef}
            >
              {supportedLanguages.map((language) => {
                return (
                  <DropdownMenuItem
                    key={language}
                    onClick={() => {
                      updateAttributes({ language });
                      setIsOpen(false);
                    }}
                    className={cn(
                      defaultLanguage === language &&
                        "bg-bg-secondary-light dark:bg-bg-secondary-dark"
                    )}
                  >
                    {capitalizeFirstLetter(getLanguageLabel({ language }))}
                  </DropdownMenuItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
          <Copy
            content={transformEditorCodeblock(content)}
            classNames="px-1 !h-5 copy-block !bg-transparent"
          />
        </div>
        <pre className={colorMode}>
          <NodeViewContent as="code" />
        </pre>
      </div>
    </NodeViewWrapper>
  );
};

export default CodeBlockComponent;
