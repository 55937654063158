import supabase from '../../utils/supabase'

export async function fetchUser({ userId }: { userId: string }) {
  const { data, error } = await supabase
    .from('users')
    .select(`*`)
    .eq('id', userId)
    .select()

  if (error) throw error

  return data
}
