import supabase from '../../utils/supabase'

export const fetchNoteDetails = async ({ noteId }: { noteId: string }) => {
  const { data, error } = await supabase
    .from('notes')
    .select(
      `
      id,
      title,
      template,
      created_at,
      updated_at,
      level,
      parent_id,
      pinned,
      description,
      users (
        id,
        name,
        avatar
      ),
      body,
      is_content_note,
      icon,
      status
  `
    )
    .eq('id', noteId)

  if (error) {
    throw new Error(error.message)
  }

  return data
}
