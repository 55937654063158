import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'sonner'
import { ERROR_MESSAGE } from '../../../utils/constants'
import { Database } from '../../../utils/database.types'
import { updateUser } from '../../api/updateUser'
import { getUserQueryKey } from '../query-keys'

export type UpdateUserArgs = Partial<
  Database['public']['Tables']['users']['Row']
>

export const useUpdateUser = ({ userId }: { userId: string }) => {
  const queryClient = useQueryClient()

  return useMutation(
    (params: UpdateUserArgs) =>
      updateUser({
        id: userId,
        ...params,
      }),
    {
      onError: () => {
        toast.error(ERROR_MESSAGE)
      },
      onSuccess: (data = []) => {
        toast.success(`Successfully updated your profile!`)

        queryClient.invalidateQueries(
          getUserQueryKey({ userId: data?.[0]?.id })
        )
      },
    }
  )
}
