import { zodResolver } from "@hookform/resolvers/zod";
import { Github, HelpCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsGoogle } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { Layout } from "../../pages-v2";
import { APP_URL, BASE_URL } from "../../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { DropdownMenuSeparator } from "../../ui-components/DropdownMenu";
import { Input } from "../../ui-components/Input";
import { Label } from "../../ui-components/Label";
import { ERROR_MESSAGE } from "../../utils/constants";
import supabase from "../../utils/supabase";

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { hydrate } = useAuthStore();

  const form = useForm({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const signInWithGithub = async () => {
    setIsLoading(true);
    await supabase.auth.signInWithOAuth({
      provider: "github",
      options: {
        redirectTo: `http://${APP_URL}/complete-profile`,
        scopes: "user",
      },
    });
    setIsLoading(false);
  };

  const signInWithGoogle = async () => {
    setIsLoading(true);
    await supabase.auth.signInWithOAuth({
      provider: "google",
    });
    setIsLoading(false);
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = form;

  const { email, password } = form.watch();

  const onSubmit = async (values: z.infer<typeof loginSchema>) => {
    const { password, email } = values;

    try {
      await supabase.auth.signOut();
    } catch (error: any) {}

    const {
      data: { user, session },
      error: signinError,
    } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (signinError) {
      toast.error(ERROR_MESSAGE, {
        duration: 5000,
      });
      return;
    }

    if (!user || !session) {
      toast.error(ERROR_MESSAGE, {
        duration: 5000,
      });
      return;
    }

    try {
      hydrate();
      history.push(`${BASE_URL}/notes`);
    } catch (error: any) {
      toast.error(ERROR_MESSAGE, {
        duration: 5000,
      });
    }
  };

  return (
    <Layout>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="h-full grid place-items-center"
      >
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium">Login</h2>
            <p className="text-sm">Hi, Welcome back! 👋</p>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <Button
              className="border border-border-primary-light dark:border-border-primary-dark rounded-sm justify-center flex"
              onClick={(e) => {
                e.preventDefault();
                signInWithGithub();
              }}
              autoFocus={false}
              id="github"
              variant="outline"
              type="button"
            >
              <div className="flex items-center gap-x-2 font-medium">
                <Github size={14} />
                Continue with Github
              </div>
            </Button>
            <Button
              className="border border-border-primary-light dark:border-border-primary-dark rounded-sm justify-center flex"
              onClick={(e) => {
                e.preventDefault();
                signInWithGoogle();
              }}
              autoFocus={false}
              id="github"
              variant="outline"
              type="button"
            >
              <div className="flex items-center gap-x-2 font-medium">
                <BsGoogle size={14} />
                Continue with Google
              </div>
            </Button>
          </div>
          <div className="border-t border-border-primary-light dark:border-border-primary-dark relative">
            <div className="absolute -top-3 text-sm left-[36%] translate-x-[36%] z-10 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark px-4 text-textColors-muted-light dark:text-textColors-muted-dark">
              or
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="Your email"
              value={email}
              name="email"
              {...register("email", { required: true })}
            />
          </div>
          <div className="flex flex-col gap-y-4">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              placeholder="Your password"
              value={password}
              type="password"
              name="password"
              className="max-w-full"
              {...register("password", { required: true })}
              size={36}
            />
          </div>
          <DropdownMenuSeparator />
          <Button type="submit" loading={isSubmitting || isLoading}>
            Login
          </Button>
          <div className="text-sm flex flex-col md:flex-row justify-between items-center">
            <Button
              variant="link"
              className="p-0 gap-x-0.5"
              onClick={() => {
                history.push("/signup");
              }}
            >
              Create an account
            </Button>
            <Button
              variant="link"
              className="p-0 gap-x-0.5"
              onClick={() => {
                history.push("/forgot-password");
              }}
            >
              Forgot Password
              <HelpCircle size={14} />
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Login;
