import { useMutation, useQueryClient } from 'react-query'
import { addFollow, removeFollow } from '../../api/updateFollow'

export const useUpdateFollow = ({
  sourceUserId,
  targetUserId,
  relationType,
}: {
  sourceUserId: string
  targetUserId: string
  relationType: 'add' | 'remove'
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      relationType === 'add'
        ? addFollow({ sourceUserId, userWhoIsFollowing: targetUserId })
        : removeFollow({ sourceUserId, userWhoIsFollowing: targetUserId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['follow-relation'])
        queryClient.invalidateQueries(['followers'])
        queryClient.invalidateQueries(['following'])
      },
    }
  )
}
