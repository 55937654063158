import { Option, Plus } from "lucide-react";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui-components/Dialog";
import { ShortcutKey } from "../../ui-components/Shortcut";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui-components/Tooltip";
import {
  DEFAULT_EDITOR_CONTENT,
  ERROR_MESSAGE,
  FREE_PLAN_MAX_NOTES,
} from "../../utils/constants";
import { Database } from "../../utils/database.types";
import { useCreateNote } from "../hooks/mutations/useCreateNote";
import { BASE_URL } from "../NotesDashboardWrapper";
import { NoteCreationForm, noteCreationFormSchema } from "./NoteCreationForm";

export const NoteCreator = ({
  planType,
  createdNotes,
}: {
  planType: Database["public"]["Tables"]["users"]["Row"]["plan"];
  createdNotes: number;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { userId } = useAuthStore();

  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useCreateNote();
  const router = useHistory();

  useHotkeys("alt+N", () => setIsDialogOpen(true));

  const trigger = (
    <TooltipProvider>
      <Tooltip defaultOpen={false}>
        <TooltipTrigger asChild>
          <Plus size={12} />
        </TooltipTrigger>
        <TooltipContent>
          Create a new note
          <div className="flex items-center gap-1">
            <ShortcutKey tooltipLabel="option">
              <Option size={14} className="stroke-[#999999]" />
            </ShortcutKey>
            <ShortcutKey tooltipLabel="N">N</ShortcutKey>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  if (planType === "free" && createdNotes > FREE_PLAN_MAX_NOTES) {
    return (
      <Dialog
        open={isDialogOpen}
        onOpenChange={(open) => setIsDialogOpen(open)}
      >
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="xs"
            className="w-5"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            {trigger}
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New</DialogTitle>
            <DialogDescription>Add your new note</DialogDescription>
          </DialogHeader>
          <div>Your plan has expired. Please renew it in the settings.</div>
          <DialogFooter className="mt-6">
            <Button
              onClick={() => {
                setIsDialogOpen(false);
                router.push(`${BASE_URL}/settings/plan`);
              }}
            >
              Open plan settings
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  async function onSubmit(values: z.infer<typeof noteCreationFormSchema>) {
    const { title, description, layout, icon } = values;

    const response = await createNote(
      {
        title,
        body: DEFAULT_EDITOR_CONTENT,
        template: layout,
        description,
        level: 0,
        userId,
        isContentNote: false,
        icon,
      },
      {
        onError: () => {
          toast.error(ERROR_MESSAGE);
        },
      }
    );

    if (Boolean(response) && response?.[0]?.id) {
      router.push(`${BASE_URL}/notes/${response?.[0]?.id}`);

      setIsDialogOpen(false);
    }
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={(open) => setIsDialogOpen(open)}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="xs"
          className="w-5"
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          {trigger}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New</DialogTitle>
          <DialogDescription>Add your new note</DialogDescription>
        </DialogHeader>
        <NoteCreationForm
          onSubmit={onSubmit}
          showLoader={isLoadingCreateNote}
          onCancel={() => setIsDialogOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};
