import { useQuery } from 'react-query'
import supabase from '../../utils/supabase'

export const useFetchFollowing = (userId: string) => {
  async function fetchFollowers(userId: string) {
    const { data, error, count } = await supabase
      .from('follows')
      .select(`users!following(*)`, { count: 'exact' })
      .eq('user', userId)

    if (error) {
      throw new Error(error.message)
    }

    return { data, count }
  }

  return useQuery(['following', userId], () => fetchFollowers(userId))
}
