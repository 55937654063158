export interface SelectedTopic {
  topic: string;
  id: string;
}

export interface Heading {
  id: string;
  text: string;
  level: number;
}

export interface AuthState {
  accessToken: string | undefined;
  refreshToken: string | undefined;
  userId: string | undefined;
  email: string | undefined;
  username: string | undefined;
}

export interface SelectedChapter {
  chap: string;
  id: string;
}

export interface queryChapter {
  chapterName: string;
  id: string;
  topicId: string;
  user_id: string;
}

export interface queryTopic {
  topic: string;
  id: string;
  user_id: string;
}
export interface queryUser {
  username: string;
  name: string;
  id: string;
}

export enum Visibility {
  PUBLIC = "public",
  PRIVATE = "private",
}

export type Emoji = {
  emoji: string;
  unified: string;
  activeSkinTone: string;
  originalUnified: string;
  names: any[];
};

export type CurrentArticleType = {
  content: string;
  summary?: string;
};

export enum TemplateEnum {
  CHEATSHEET = "cheatsheet",
  STANDARD = "standard",
  SNIPPET = "snippet",
}
