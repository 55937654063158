import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import React from 'react'
import { FiMenu } from 'react-icons/fi'
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom'
import PrimaryButton from '../business/buttons/PrimaryButton'
import { ColorModeSwitcher } from '../utils/darkmode'
import Logo from '../utils/logo'

interface PageProps {
  children: React.ReactNode
}

const externalLinks: Array<{
  name: string
  url: string
  Icon?: React.ReactElement
}> = [
  {
    name: 'Features',
    url: '/templates',
    Icon: null,
  },
  {
    name: 'Templates',
    url: '/templates',
    Icon: null,
  },
  {
    name: 'Blog',
    url: '/blog',
    Icon: null,
  },
]

class ExternalLink {
  name: string
  url: string
  Icon?: React.ReactElement

  constructor(name: string, url: string, Icon?: React.ReactElement) {
    this.name = name
    this.url = url
    this.Icon = Icon
  }

  isActiveLink = (pathname: string) => {
    return pathname === this.url
  }
}

const ExternalLinkItem = ({ externalLink }: { externalLink: ExternalLink }) => {
  const iconToggleBg = useColorModeValue('light.secondary', 'dark.tertiary')
  const location = useLocation()

  return (
    <Box
      // TODO: Revisit
      key={Date.now()}
      bg={externalLink.isActiveLink(location.pathname) ? iconToggleBg : ''}
      px={2}
      py={1}
      rounded="md"
      fontSize={{ base: 'sm', md: 'md' }}
      role="group"
    >
      <Link to={externalLink.url}>
        <Box display="flex" alignItems="center" gridGap={1}>
          <Text>{externalLink.name}</Text>
          <Box mt={1}>{externalLink.Icon && externalLink.Icon}</Box>
        </Box>
      </Link>
    </Box>
  )
}

const PublicLayout = (props: PageProps) => {
  const history = useHistory()
  const location = useLocation()

  if (location.hash) {
    if (location.hash.endsWith('type=recovery')) {
      return (
        <Redirect
          to={{
            pathname: '/change-password',
            state: {
              access_token: new URLSearchParams(location.hash).get(
                '#access_token'
              ),
            },
          }}
        />
      )
    }

    // error case
    if (location.hash.includes('#error')) {
      return (
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMsg:
                'The reset link has expired, please request for a new link again',
            },
          }}
        />
      )
    }

    // login request.
    if (location.hash.startsWith('#access_token')) {
      return (
        <Redirect
          to={{
            pathname: '/complete-profile',
          }}
        />
      )
    }
  }

  const handleLaunchVirtualBook = () => history.push('/login')

  return (
    <Box h="100vh" className="layout">
      <Box
        pt={6}
        display="inline-flex"
        justifyContent="space-between"
        flexWrap="nowrap"
        w="full"
        px={{ base: 4, md: 24 }}
      >
        <Box display="flex">
          <Box
            cursor="pointer"
            display="flex"
            alignItems="center"
            onClick={() => history.push('/')}
            gridGap={2}
          >
            <Logo />
            <Text
              mt={0.5}
              display={{ base: 'none', lg: 'block' }}
              fontWeight={500}
              fontSize="lg"
            >
              Virtualbook
            </Text>
          </Box>
        </Box>
        <Box
          alignItems="center"
          gridGap={{ base: 1, md: 3 }}
          rounded={24}
          px={{ md: 4 }}
          display={{ base: 'none', md: 'flex' }}
        >
          {externalLinks.map((link) => {
            const externalLink = new ExternalLink(
              link.name,
              link.url,
              link.Icon as any
            )
            return <ExternalLinkItem externalLink={externalLink} />
          })}
        </Box>
        <Box
          textAlign="right"
          className="actions"
          alignItems="center"
          gridGap={4}
          display={{ base: 'none', md: 'flex' }}
        >
          <PrimaryButton
            onClick={() => handleLaunchVirtualBook()}
            py={2}
            px={{ base: 2, md: 5 }}
            rounded="md"
            fontSize="sm"
            display={{ base: 'none', md: 'flex' }}
          >
            Login
          </PrimaryButton>
          <ColorModeSwitcher />
        </Box>
        <Box className="hamburger-menu" display={{ md: 'none' }}>
          <Menu>
            <MenuButton
              as={Button}
              _focus={{
                boxShadow: 'none',
                outline: 'none',
              }}
            >
              <FiMenu />
            </MenuButton>
            <MenuList textAlign="left">
              <Box px={2} display="flex" alignItems="center" gridGap={2}>
                <ColorModeSwitcher />
              </Box>
              <Box mt={2}>
                {externalLinks.map((link) => {
                  const externalLink = new ExternalLink(
                    link.name,
                    link.url,
                    link.Icon as any
                  )
                  return (
                    <Box key={externalLink.name} mb={1}>
                      <ExternalLinkItem externalLink={externalLink} />
                    </Box>
                  )
                })}
              </Box>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Box ml="auto" mr="auto" className="main" h="calc(100vh - 92px)">
        {props.children}
      </Box>
    </Box>
  )
}

export default PublicLayout
