import {
  Archive,
  Cog,
  FolderUp,
  Layers2,
  MoreHorizontal,
  Star,
  StarOff,
  Trash2,
} from "lucide-react";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import { noteIconsMap } from "../../components/visual/NoteIconSelect";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../ui-components/Alert";
import { Button } from "../../ui-components/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui-components/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui-components/DropdownMenu";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { ERROR_MESSAGE } from "../../utils/constants";
import { cn } from "../../utils/utils";
import { NoteSidebarContext } from "../context/NoteSidebarItem.context";
import { useCreateNote } from "../hooks/mutations/useCreateNote";
import { useDeleteNotes } from "../hooks/mutations/useDeleteNotes";
import { useUpdateNote } from "../hooks/mutations/useUpdateNote";
import { TNotesResponseWithChildren } from "../hooks/queries/useNotesList";
import { BASE_URL } from "../NotesDashboardWrapper";
import { NoteCreationForm, noteCreationFormSchema } from "./NoteCreationForm";
import { constructUrlForNote } from "./services";

export const NoteWithActions = ({
  note,
  style,
  isActive = false,
}: {
  note: TNotesResponseWithChildren;
  style?: React.CSSProperties;
  isActive: boolean;
}) => {
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-between w-[calc(100%-24px)] gap-x-2 -mx-1"
      )}
      style={style}
    >
      <div className="flex flex-row items-center gap-x-1 min-w-0">
        <div className={cn("flex justify-center")}>
          <LucideIcon
            icon={noteIconsMap[note.icon]}
            size={14}
            className={cn(
              note.status === "archived" &&
                "stroke-text-textColors-muted-light dark:stroke-text-muted-dark",
              isActive
                ? "stroke-[#1E2D5C] dark:stroke-[#D4E1FF]"
                : "stroke-text-textColors-muted-light dark:stroke-text-textColors-muted-light",
              "transition-colors duration-100"
            )}
          />
        </div>
        <div
          className={cn(
            "truncate",
            isActive ? "text-[#1E2D5C] dark:text-[#D4E1FF]" : "",
            note.status === "archived" &&
              "text-textColors-muted-light dark:text-textColors-muted-dark"
          )}
        >
          {note.title}
        </div>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex-none"
      >
        <NoteSidebarActions note={note} />
      </div>
    </div>
  );
};

export const NoteSidebarActions = ({
  note,
}: {
  note: TNotesResponseWithChildren;
}) => {
  const [open, setOpen] = useState(false);
  const [openNoteCreator, setOpenNoteCreator] = useState(false);
  const [openNoteDeletor, setOpenNoteDeletor] = useState(false);
  const [openNoteEditor, setOpenNoteEditor] = useState(false);
  const [openNotePublisher, setOpenNotePublisher] = useState(false);
  const [openNoteArchiver, setOpenNoteArchiver] = useState(false);
  const { parentNote } = useContext(NoteSidebarContext);

  const { mutateAsync: createNote, isLoading: isLoadingCreateNote } =
    useCreateNote();

  const { mutateAsync: updateNote, isLoading: isLoadingUpdateNote } =
    useUpdateNote({
      showToast: false,
      onSuccess: true,
    });

  const { mutateAsync: deleteNotes } = useDeleteNotes();

  const isArchived = note.status === "archived";

  const router = useHistory();

  const onCreateNoteFormSubmit = async (
    values: z.infer<typeof noteCreationFormSchema>
  ) => {
    const { title, description, layout, icon } = values;

    const response = await createNote(
      {
        title,
        body: "",
        template: layout,
        description,
        level: parentNote?.children?.length + 1 || 1,
        userId: note.users.id,
        parentId: note.id,
        isContentNote: false,
        icon,
      },
      {
        onError: () => {
          toast.error(ERROR_MESSAGE);
        },
      }
    );

    if (Boolean(response) && response?.[0]?.id) {
      const fakeParentPaths = note.parentPaths ?? [];
      router.push(
        constructUrlForNote({
          note: {
            ...response?.[0],
            parentPaths: [...fakeParentPaths, note.id],
          } as unknown as TNotesResponseWithChildren,
        })
      );

      setOpenNoteCreator(false);
    }
  };

  const onUpdateNoteFormSubmit = async (
    values: z.infer<typeof noteCreationFormSchema>
  ) => {
    const { title, description, icon } = values;

    await updateNote(
      {
        title,
        description,
        id: note.id,
        icon,
      },
      {
        onError: () => {
          toast.error(ERROR_MESSAGE);
        },
      }
    );

    setOpenNoteEditor(false);
  };

  const canPublishNote = note.status === "draft";

  if (isArchived) return null;

  return (
    <>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="link"
            size="icon"
            className={cn(
              "w-4 h-4",
              "group-hover:opacity-100 opacity-0 transition-opacity duration-200 flex flex-row items-center gap-x-1",
              open ? "opacity-100" : "",
              "text-textColors-muted-light dark:text-textColors-muted-dark"
            )}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <MoreHorizontal size={12} />
          </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-auto p-1 flex flex-col gap-y-0.5">
          <DropdownMenuItem
            onClick={() => {
              setOpen(false);
              setOpenNoteCreator(true);
            }}
          >
            <LucideIcon icon={Layers2} size={14} />
            Add subnote
          </DropdownMenuItem>
          {canPublishNote && (
            <DropdownMenuItem
              onClick={() => {
                setOpen(false);
                setOpenNotePublisher(true);
              }}
            >
              <LucideIcon icon={FolderUp} size={14} />
              Publish
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              setOpen(false);
              updateNote(
                {
                  pinned: !note.pinned,
                  id: note.id,
                },
                {
                  onSuccess: (data) => {
                    if (data?.[0]?.id) {
                      toast.success(
                        `"${data?.[0]?.title || `Note`}" ${
                          note.pinned
                            ? `has been removed from`
                            : `has been added to`
                        } favorites`
                      );
                    }
                  },
                }
              );
            }}
          >
            {note.pinned ? (
              <LucideIcon icon={StarOff} size={14} />
            ) : (
              <LucideIcon icon={Star} size={14} />
            )}
            {note.pinned ? (
              <span>Remove from favorites</span>
            ) : (
              <span>Add to favorites</span>
            )}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              setOpen(false);
              setOpenNoteEditor(true);
            }}
          >
            <LucideIcon icon={Cog} size={14} />
            Edit Metadata
          </DropdownMenuItem>
          {note.status !== "archived" && (
            <DropdownMenuItem
              onClick={() => {
                setOpen(false);
                setOpenNoteArchiver(true);
              }}
            >
              <LucideIcon icon={Archive} size={14} />
              Archive
            </DropdownMenuItem>
          )}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setOpen(false);
              setOpenNoteDeletor(true);
            }}
          >
            <LucideIcon icon={Trash2} size={14} />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={openNoteEditor} onOpenChange={setOpenNoteEditor}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Editing "{note.title}"</DialogTitle>
          </DialogHeader>
          <NoteCreationForm
            onSubmit={onUpdateNoteFormSubmit}
            showLoader={isLoadingUpdateNote}
            isLayoutSelectionEnabled={false}
            defaultValues={{
              title: note.title,
              description: note.description,
              icon: note.icon,
            }}
            isIconEnabled={true}
            customSubmitButton={
              <DialogFooter className="pt-4">
                <Button type="submit" loading={isLoadingUpdateNote}>
                  Update
                </Button>
              </DialogFooter>
            }
            onCancel={() => {
              setOpenNoteEditor(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={openNoteCreator} onOpenChange={setOpenNoteCreator}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New</DialogTitle>
            <DialogDescription>
              Add a new subnote for <span>"{note.title}"</span>
            </DialogDescription>
          </DialogHeader>
          <NoteCreationForm
            onSubmit={onCreateNoteFormSubmit}
            showLoader={isLoadingCreateNote}
            isIconEnabled={true}
            onCancel={() => {
              setOpenNoteCreator(false);
            }}
          />
        </DialogContent>
      </Dialog>
      <AlertDialog open={openNotePublisher} onOpenChange={setOpenNotePublisher}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will publish this note <span>"{note.title}"</span> to the
              web.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                await updateNote({
                  id: note.id,
                  status: "published",
                });
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog open={openNoteDeletor} onOpenChange={setOpenNoteDeletor}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete your this note{" "}
              <span>"{note.title}"</span> and all it's child notes from our
              servers. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                deleteNotes({
                  noteIds: [note.id],
                });

                router.push(`${BASE_URL}/notes`);
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog open={openNoteArchiver} onOpenChange={setOpenNoteArchiver}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will move your this note <span>"{note.title}"</span> to
              archives, you will not be able to access this note until you have
              restored it.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={async () => {
                await updateNote({
                  id: note.id,
                  status: "archived",
                });
              }}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
