import { InfoIcon, LucideIcon } from "lucide-react";
import { ReactNode } from "react";
import { LucideIcon as LucideIconComponent } from "./LucideIcon";

export const Info = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon?: LucideIcon;
}) => {
  return (
    <div className="bg-backgroundColors-interactive-light dark:bg-backgroundColors-interactive-dark flex items-start gap-2 p-4 rounded-md">
      {icon ? (
        <LucideIconComponent
          icon={icon}
          className="stroke-textColors-foreground-light dark:stroke-textColors-foreground-dark shrink-0 mt-0.5"
          size={16}
        />
      ) : (
        <LucideIconComponent
          icon={InfoIcon}
          className="stroke-textColors-foreground-light dark:stroke-textColors-foreground-dark shrink-0 mt-0.5"
          size={16}
        />
      )}
      <span className="text-textColors-foreground-light dark:text-textColors-foreground-dark shrink">
        {children}
      </span>
    </div>
  );
};
