import { Button } from '../../Button'
import { Label } from '../../Label'
import { Tabs, TabsList, TabsTrigger } from '../../Tabs'

const ViewTypeMap = {
  '2': {
    Icon: <div>2</div>,
  },
  '3': {
    Icon: <div>3</div>,
  },
  '4': {
    Icon: <div>4</div>,
  },
}

export const CheatsheetTemplateDisplayOptions = ({
  columnView,
  onColumsViewChange,
}: {
  columnView: number
  onColumsViewChange: ({ column }: { column: number }) => void
}) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-row items-center gap-x-2 justify-between">
        <Label>Layout columns</Label>
        <Tabs defaultValue={String(columnView)}>
          <TabsList>
            {Object.keys(ViewTypeMap).map((view) => {
              const isActive = columnView === Number(view)

              return (
                <TabsTrigger
                  key={view}
                  className="p-0"
                  value={view}
                  onClick={() => onColumsViewChange({ column: Number(view) })}
                >
                  <Button
                    key={view}
                    variant={isActive ? 'outline' : 'ghost'}
                    size="icon"
                    className={`w-6 h-6`}
                    onClick={() => onColumsViewChange({ column: Number(view) })}
                  >
                    {ViewTypeMap[view as keyof typeof ViewTypeMap].Icon}
                  </Button>
                </TabsTrigger>
              )
            })}
          </TabsList>
        </Tabs>
      </div>
    </div>
  )
}
