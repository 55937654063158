import { zodResolver } from "@hookform/resolvers/zod";
import { DropdownMenuSeparator } from "@radix-ui/react-dropdown-menu";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { RevisionColorPicker } from "../../../components/visual/RevisionColorPicker";
import { Button } from "../../../ui-components/Button";
import { DialogFooter } from "../../../ui-components/Dialog";
import { Input } from "../../../ui-components/Input";
import { Textarea } from "../../../ui-components/Textarea";
import { cn } from "../../../utils/utils";
import {
  DEFAULT_REVISION_CARD_COLOR,
  RevisionCardColorsType,
} from "../../extensions/revise-marks/Revise.mark";

const revisionSchema = z.object({
  question: z.string(),
  answer: z.string(),
  color: z.string().default(DEFAULT_REVISION_CARD_COLOR),
});

export const MarkerForm = ({
  onSubmit,
  onDelete,
  selectedText,
}: {
  onSubmit: SubmitHandler<{
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }>;
  onDelete: () => void;

  selectedText: string;
  onRefetch?: () => void;
  defaultValues?: {
    questions: string;
    answers: string;
    color: RevisionCardColorsType;
  };
}) => {
  const form = useForm<{
    question: string;
    answer: string;
    color: RevisionCardColorsType;
  }>({
    resolver: zodResolver(revisionSchema),
    defaultValues: {
      question: "",
      answer: selectedText,
      color: DEFAULT_REVISION_CARD_COLOR,
    },
  });
  const { register, handleSubmit, setValue } = form;

  const { color, answer, question } = form.watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className={cn(
          "flex flex-col gap-y-3 px-1 relative font-medium"
          // "after:content=[''] after:absolute after:h-[12px] after:w-[1px] after:bg-border-primary-light dark:after:bg-gray-600 after:top-[32px] after:left-[15px] after:rounded-full"
        )}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Input
              placeholder="Title"
              className="text-textColors-secondary-light border-0 pl-0 text-lg dark:text-textColors-secondary-dark shadow-none focus:shadow-none focus:ring-0 focus-visible:shadow-none focus-visible:ring-0"
              {...register("question")}
              autoComplete="off"
            />
          </div>
          <div className={cn("flex flex-col gap-2")}>
            <label>Add insight</label>
            <Textarea
              placeholder={"Add insight corresponding to the cue"}
              className={cn(
                "text-textColors-secondary-light dark:text-textColors-secondary-dark shadow-none focus:shadow-none focus:ring-0 focus-visible:shadow-none focus-visible:ring-0",
                "h-[200px] max-h-[200px] -mt-0.5 overflow-y-auto resize-none font-normal"
              )}
              {...register("answer")}
            />
          </div>
        </div>
      </div>
      <DropdownMenuSeparator className="my-6" />
      <DialogFooter className="gap-2 !justify-between flex flex-row">
        <div className="flex flex-row">
          <RevisionColorPicker
            value={color}
            setValue={(newVal) => setValue("color", newVal)}
          />
        </div>
        <Button type="submit" disabled={!answer || !question}>
          Create
        </Button>
      </DialogFooter>
    </form>
  );
};
