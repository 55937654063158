import { Blocks, Sparkle, Undo2 } from "lucide-react";
import { useHistory } from "react-router-dom";
import { AiIcon } from "../../assets/svg/ai.svg";
import { BrandIconTransparent } from "../../business/icons/Icons";
import { RootLayout } from "../../layouts/Root.layout";
import { Button } from "../../ui-components/Button";
import { Chip } from "../../ui-components/Chip";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { cn } from "../../utils/utils";
import { BacklinksUI } from "./components/Backlinks";
import { FeaturesList } from "./components/FeaturesList";
import { FlashcardMockUI } from "./components/FlashcardMock";
import { ReflectUI } from "./components/Reflect";
import { Showcase } from "./components/Showcase";
import { SnippetsUI } from "./components/Snippets";

const AppStill = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-1.5 rounded-2xl bg-[#E5EEFD]/30 dark:bg-bg-secondary-dark size-16 text-sm font-medium">
      <BrandIconTransparent className="size-12" />
    </div>
  );
};

const HEADER_CLASSNAMES = `text-3xl sm:text-3xl md:text-4xl font-medium tracking-tight leading-10`;

export const LandingPage = () => {
  const router = useHistory();

  const subTextClasses =
    "max-w-[30ch] mt-4 tracking-normal text-textColors-muted-light dark:text-textColors-secondary-dark text-base sm:leading-6 text-center";

  return (
    <RootLayout>
      <main className="flex-1 md:px-24">
        <section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10">
          <div className="flex max-w-[64rem] flex-col items-center gap-4 text-center mx-auto mt-24">
            <AppStill />
            <div className="flex flex-col items-center">
              <h1 className="font-heading text-3xl sm:text-3xl md:text-4xl font-medium text-center leading-snug">
                Write clearly and <br /> revise effortlessly
              </h1>
              <p className={subTextClasses}>
                Envise is a note-taking app that helps you write, reflect and
                revise your notes with ease.
              </p>
            </div>
            <div className="flex flex-row items-center space-x-4 mt-4">
              <Button
                className="h-[40px] py-0 px-8 rounded-xl text-lg"
                onClick={() => router.push(`/signup`)}
              >
                Get started for free
              </Button>
            </div>
          </div>
        </section>
        <section
          id="revise"
          className="flex flex-col items-center space-y-6 py-8 px-2 dark:bg-transparent md:py-12 lg:py-24"
        >
          <Showcase />
        </section>
        <section
          id="features"
          className="flex flex-col items-center gap-6 text-center py-8 px-2 dark:bg-transparent md:py-12 lg:py-24"
        >
          <div className="flex flex-col items-center">
            <h1 className={cn("font-heading", HEADER_CLASSNAMES)}>
              Powerful Features
            </h1>
            <p className={subTextClasses}>
              Carefully crafted tools that boost writing productivity.
            </p>
          </div>
          <div className="flex flex-col items-start gap-y-2 max-w-[58rem]">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
              <LandingPageCard className="flex flex-col gap-y-4 w-full md:col-span-7">
                <div className="flex flex-col gap-2">
                  <Chip className="px-1.5 py-0 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark">
                    <div className="flex flex-row items-center gap-1">
                      <div
                        className="w-3.5 h-3.5 rounded-sm grid place-items-center"
                        style={{
                          background: "var(--brand)",
                        }}
                      >
                        <AiIcon className="fill-white h-2 w-2" />
                      </div>
                      <span>Reflect</span>
                    </div>
                  </Chip>
                  <div className="text-left">
                    <h2 className="font-medium tracking-tight mb-1.5">
                      Refine your concepts.
                    </h2>
                    <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
                      Create reflections or cues using AI and reflect on your
                      notes to enhance your perspective.
                    </p>
                  </div>
                </div>
                <ReflectUI />
              </LandingPageCard>
              <LandingPageCard className="flex flex-col gap-y-4 w-full md:col-span-5">
                <div className="flex flex-col gap-2">
                  <Chip className="px-1.5 py-0 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark">
                    <div className="flex flex-row items-center gap-1">
                      <div
                        className="w-3.5 h-3.5 rounded-sm grid place-items-center"
                        style={{
                          background: "var(--brand)",
                        }}
                      >
                        <LucideIcon
                          icon={Sparkle}
                          size={10}
                          className="stroke-white"
                        />
                      </div>
                      <span>Revise with flashcards</span>
                    </div>
                  </Chip>
                  <div className="text-left">
                    <h2 className="font-medium tracking-tight text-left mb-1.5">
                      Visualise your learning.
                    </h2>
                    <p className="text-textColors-muted-light dark:text-textColors-muted-dark text-left">
                      Use interactive flashcards to strengthen fundamental
                      concepts and evaluate your understanding.
                    </p>
                  </div>
                </div>
                <div className="relative text-left">
                  <FlashcardMockUI />
                </div>
              </LandingPageCard>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-8 !mt-6">
              <LandingPageCard className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-2">
                  <Chip className="px-1.5 py-0 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark">
                    <div className="flex flex-row items-center gap-1">
                      <div
                        className="w-3.5 h-3.5 rounded-sm grid place-items-center"
                        style={{
                          background: "var(--brand)",
                        }}
                      >
                        <LucideIcon
                          icon={Undo2}
                          size={10}
                          className="stroke-white"
                        />
                      </div>
                      <span>Backlinks</span>
                    </div>
                  </Chip>
                  <div className="text-left">
                    <h2 className="font-medium tracking-tight mb-1.5">
                      Connect the dots.
                    </h2>
                    <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
                      Create backlinks to other notes and easily navigate
                      between them.
                    </p>
                  </div>
                </div>
                <BacklinksUI />
              </LandingPageCard>
              <LandingPageCard className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-2">
                  <Chip className="px-1.5 py-0 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark">
                    <div className="flex flex-row items-center gap-1">
                      <div
                        className="w-3.5 h-3.5 rounded-sm grid place-items-center"
                        style={{
                          background: "var(--brand)",
                        }}
                      >
                        <LucideIcon
                          icon={Blocks}
                          size={10}
                          className="stroke-white"
                        />
                      </div>
                      <span>Snippets</span>
                    </div>
                  </Chip>
                  <div className="text-left">
                    <h2 className="font-medium tracking-tight mb-1.5">
                      Don't repeat yourself.
                    </h2>
                    <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
                      Tired of typing the same phrases? Save your frequently
                      used text snippets and insert them with a click.
                    </p>
                  </div>
                </div>
                <SnippetsUI />
              </LandingPageCard>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-6 gap-y-16 mt-20">
              <FeaturesList />
            </div>
          </div>
        </section>
        <section
          id="try"
          className="flex flex-col items-center gap-4 text-center py-8 px-2 dark:bg-transparent md:py-12 lg:py-24"
        >
          <div className="flex flex-col gap-8 -mt-4 items-center">
            <AppStill />
            <h1
              className={cn(
                "font-heading text-3xl sm:text-3xl md:text-4xl font-medium text-center leading-snug max-w-[26ch]"
              )}
            >
              A better way to write, organise and remember.
            </h1>
            <p className={cn(subTextClasses, "mt-0")}></p>
          </div>
          <div className="flex flex-row items-center gap-4">
            <Button
              className="h-[40px] py-0 px-8 rounded-xl text-lg"
              onClick={() => router.push(`/signup`)}
            >
              Start writing
            </Button>
          </div>
        </section>
      </main>
    </RootLayout>
  );
};

const LandingPageCard = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "rounded-xl relative overflow-clip bg-transparent p-6 py-6 border border-border-primary-light dark:border-border-primary-dark",
        className
      )}
    >
      {children}
    </div>
  );
};
