import { Node } from "@tiptap/pm/model";
import { Editor, NodeViewWrapper } from "@tiptap/react";
import { Workflow } from "lucide-react";
import { Link } from "react-router-dom";
import { constructUrlForNote } from "../../../pages-v2/notes-sidebar/services";
import { LucideIcon } from "../../../ui-components/LucideIcon";
import { cn } from "../../../utils/utils";

export const NoteHyperlinkRenderer = ({
  node,
}: {
  node: Node;
  editor: Editor;
  selected: boolean;
}) => {
  const { attrs } = node;

  const url = constructUrlForNote({
    note: {
      parentPaths: attrs.id.parentPaths,
      id: attrs.id.id,
    },
  });

  return (
    <NodeViewWrapper as="span">
      <span>
        <Link
          to={url}
          className={cn(
            "inline-flex gap-1 hover:!opacity-[1] items-center font-medium !no-underline !decoration-0 p-0.5 px-1 rounded-md w-fit !text-text-tertiary-light dark:!text-text-tertiary-dark",
            "border border-border-primary-light dark:border-border-primary-dark hover:bg-backgroundColors-gray-light dark:hover:bg-backgroundColors-gray-dark"
          )}
        >
          <LucideIcon
            icon={Workflow}
            size={14}
            className="!stroke-text-tertiary-light dark:!stroke-text-tertiary-dark"
          />
          {attrs.id.title}
        </Link>
      </span>
    </NodeViewWrapper>
  );
};
