import { Button } from "../../ui-components/Button";
import { AvatarsMap } from "../../utils/constants";
import { cn } from "../../utils/utils";

export const AvatarSelect = ({
  value,
  onSelect,
}: {
  value: string;
  onSelect: (selectedAvatar: string) => void;
}) => {
  return (
    <div className="flex flex-row flex-wrap gap-4">
      {Object.entries(AvatarsMap).map(([avatarKey, avatarIcon]) => {
        return (
          <Button
            variant="link"
            size="icon"
            key={avatarKey}
            className={cn(
              value === avatarKey
                ? "w-auto h-auto ring-4 rounded-full ring-backgroundColors-foreground-light dark:ring-backgroundColors-foreground-dark"
                : "w-auto h-auto ring-4 ring-transparent opacity-90"
            )}
            onClick={(e) => {
              e.preventDefault();
              onSelect(avatarKey);
            }}
          >
            {avatarIcon}
          </Button>
        );
      })}
    </div>
  );
};
