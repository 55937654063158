import { EditorContent, useEditor } from '@tiptap/react'
import { useEffect } from 'react'
import { getBasicPlugins } from '../../text-editor/extensions/Plugins'

const CheatsheetViewer = (props: { content: string }) => {
  const { content } = props

  const editor = useEditor(
    {
      extensions: [...getBasicPlugins({ placeholder: `` })],
      content: JSON.parse(content),
      editable: false,
    },
    [content]
  )

  useEffect(() => {
    editor?.setEditable(false)
  }, [editor])

  return (
    <div className="tiptap-editor flex flex-col">
      {Boolean(JSON.parse(content).content[0].content) && (
        <EditorContent editor={editor} readOnly />
      )}
    </div>
  )
}

export default CheatsheetViewer
