import { createContext } from 'react'
import { TNotesResponseWithChildren } from '../hooks/queries/useNotesList'

type TNoteSidebarContextType = {
  parentNote: TNotesResponseWithChildren
}

export const NoteSidebarContext = createContext<TNoteSidebarContextType>({
  parentNote: null,
})

export const NoteSidebarContextProvider = ({
  children,
  parentNote,
}: {
  children: React.ReactNode
  parentNote: TNotesResponseWithChildren
}) => {
  return (
    <NoteSidebarContext.Provider value={{ parentNote }}>
      {children}
    </NoteSidebarContext.Provider>
  )
}
