import {
  Brain,
  Command,
  Contrast,
  Highlighter,
  Lock,
  Pilcrow,
} from "lucide-react";
import { FC } from "react";
import { LucideIcon } from "../../../ui-components/LucideIcon";

const iconSize = 14;

const featuresList = [
  {
    id: "Markdown Support",
    description:
      "Enjoy the flexibility and efficiency of Markdown formatting while capturing your thoughts and ideas.",
    icon: <LucideIcon icon={Pilcrow} size={iconSize} />,
  },
  {
    id: "Highlightable Notes",
    description:
      "Easily emphasize key points and important details as you accentuate crucial information.",
    icon: <LucideIcon icon={Highlighter} size={iconSize} />,
  },

  {
    id: "Share only what you need",
    description:
      "Control what you publish to the web, ensuring your notes are always secure and accessible.",
    icon: <LucideIcon icon={Lock} size={iconSize} />,
  },
  {
    id: "Built for keyboard",
    description: "Fly through your notes with rapid-fire keyboard shortcuts.",
    icon: <LucideIcon icon={Command} size={iconSize} />,
  },
  {
    id: "Dynamic light & dark mode",
    description: "Switch between light and dark mode seamlessly.",
    icon: <LucideIcon icon={Contrast} size={iconSize} />,
  },
  {
    id: "Mindmaps",
    description:
      "Visualise how your brain thinks. Interpret deeply hidden connections in your ideas.",
    icon: <LucideIcon icon={Brain} size={iconSize} />,
    available: false,
  },
];

export const FeaturesList: FC = () => {
  return (
    <>
      {featuresList.map((feature) => {
        return (
          <div key={feature.id} className="flex flex-col text-left">
            <div className="flex items-center gap-1.5">
              {feature.icon}
              <div className="font-semibold text-base flex items-center gap-1.5">
                {feature.id}
                {feature.available === false && (
                  <span className="font-normal text-sm border border-border-primary-light dark:border-border-primary-dark rounded-md px-1 py-0.5">
                    Soon
                  </span>
                )}
              </div>
            </div>
            <p className="text-text-textColors-muted-light dark:text-textColors-muted-dark">
              {feature.description}
            </p>
          </div>
        );
      })}
    </>
  );
};
