import { Session } from '@supabase/supabase-js'
import { createContext } from 'react'

type AuthContextType = {
  session: Session
}

export const AuthContext = createContext<AuthContextType>({
  session: null,
})

export const AuthContextProvider = ({
  session,
  children,
}: {
  children: React.ReactNode
  session: Session
}) => {
  return (
    <AuthContext.Provider value={{ session }}>{children}</AuthContext.Provider>
  )
}
