import { useQuery } from 'react-query'
import supabase from '../../utils/supabase'

export enum FollowRelationType {
  FOLLOWER = 'follower',
  FOLLOWING = 'following',
}

// TODO: Revisit.
async function isSourceFollower(sourceUser: string, targetUser: string) {
  const { data, error, count } = await supabase
    .from('follows')
    .select(`following`, { count: 'exact' })
    .eq('user', sourceUser)
    .eq('following', targetUser)

  if (error) {
    throw new Error(error.message)
  }

  return { data, count }
}

async function isSourceBeingFollowed(sourceUser: string, targetUser: string) {
  const { data, error, count } = await supabase
    .from('follows')
    .select(`following`, { count: 'exact' })
    .eq('following', sourceUser)
    .eq('user', targetUser)

  if (error) {
    throw new Error(error.message)
  }

  return { data, count }
}

export const useFetchFollowRelation = ({
  sourceUser,
  targetUser,
  relationType,
}: {
  sourceUser: string
  targetUser: string
  relationType: FollowRelationType
}) => {
  return useQuery(
    ['follow-relation', sourceUser, targetUser, relationType],
    () =>
      relationType === FollowRelationType.FOLLOWER
        ? isSourceFollower(sourceUser, targetUser)
        : isSourceBeingFollowed(sourceUser, targetUser),
    {
      enabled: Boolean(sourceUser) && Boolean(targetUser),
    }
  )
}
