import { Fingerprint } from 'lucide-react'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout } from '../pages-v2'
import { Button } from '../ui-components/Button'
import { DropdownMenuSeparator } from '../ui-components/DropdownMenu'

export const CustomError = () => {
  const { state } = useLocation()
  const history = useHistory()

  return (
    <Layout>
      <div className="h-full grid place-items-center">
        <div className="overflow-hidden rounded-md border border-border-primary-light dark:border-border-primary-dark bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark py-8 px-8 flex flex-col gap-y-6">
          <Button
            className="pointer-events-none h-12 w-12 p-0"
            variant="outline"
          >
            <Fingerprint size={20} />
          </Button>
          <div className="flex flex-col gap-y-1">
            <h2 className="font-medium">Oops! an error occurred</h2>
            <p className="text-sm">
              {state?.errorMsg
                ? state?.errorMsg
                : 'We could not process your request, please try again later.'}
            </p>
          </div>
          <DropdownMenuSeparator />
          <Button
            onClick={() => {
              history.push('/')
            }}
            className="gap-2"
          >
            Back to home
          </Button>
        </div>
      </div>
    </Layout>
  )
}
