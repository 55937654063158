import { Mark, mergeAttributes, SingleCommands } from "@tiptap/react";

export interface RevisionMarkOptions {
  HTMLAttributes: {
    revision?: {
      [question: string]: {
        answer: string;
        id: string;
        retained?: string;
        forgotten?: string;
      };
    };
  };
}

export type RevisionCardColorsType = keyof typeof RevisionCardColors;

export const RevisionCardColors = {
  blue: {
    label: "blue",
    value: "--blue",
  },
  orange: {
    label: "orange",
    value: "--orange",
  },
  red: {
    label: "red",
    value: "--red",
  },
  green: {
    label: "green",
    value: "--green",
  },
  yellow: {
    label: "yellow",
    value: "--yellow",
  },
  purple: {
    label: "purple",
    value: "--purple",
  },
  pink: {
    label: "pink",
    value: "--pink",
  },
  gray: {
    label: "gray",
    value: "--gray",
  },
} as const;

export const DEFAULT_REVISION_CARD_COLOR: RevisionCardColorsType = Object.keys(
  RevisionCardColors
)[0] as RevisionCardColorsType;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    reviseMark: {
      setReviseMark: (
        color: RevisionCardColorsType,
        attributes: {
          [question: string]: {
            answer: string;
            id: string;
            retained?: string;
            forgotten?: string;
          };
        },
        revisionId: string
      ) => ReturnType;

      toggleReviseMark: (
        color: RevisionCardColorsType,
        attributes: {
          [question: string]: string;
        }
      ) => ReturnType;

      unsetReviseMark: () => ReturnType;

      updateReviseMark: (
        color: (typeof RevisionCardColors)[keyof typeof RevisionCardColors],
        id: string,
        attributes?: {
          question?: string;
          answer: string;
        },
        retained?: number,
        forgotten?: number
      ) => ReturnType;
    };
  }
}

export const ReviseMark = Mark.create<RevisionMarkOptions>({
  name: "reviseMark",
  priority: 10000000000,
  keepOnSplit: false,
  excludes: "code",
  exitable: true,

  addAttributes() {
    return {
      class: {
        default: "revision-mark py-1 rounded-sm",
      },
      // class: {
      //   default: `revision-mark before:content-['🔵'] before:absolute sm:before:-left-2 md:before:-left-4 sm:before:text-[6px] md:before-text-xs`,
      //   parseHTML: (element) => {
      //     return element.getAttribute("class");
      //   },
      //   renderHTML: (attributes) => {
      //     if (!attributes.class) {
      //       return {};
      //     }
      //     return {
      //       class: attributes.class + ` revision-mark2`,
      //       dataTooltip:
      //         attributes.revision[Object.keys(attributes.revision)?.[0]] || "",
      //     };
      //   },
      // },
      color: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("color");
        },
      },
      revision: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("revision");
        },
        renderHTML: (attributes) => {
          if (!attributes.revision) {
            return [];
          }
          return {
            revision: attributes.revision,
          };
        },
      },
      revisionId: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute("revisionId");
        },
        renderHTML: (attributes) => {
          if (!attributes.revisionId) {
            return [];
          }
          return {
            id: attributes.revisionId,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setReviseMark:
        (
          color: RevisionCardColorsType,
          revision: RevisionMarkOptions["HTMLAttributes"]["revision"],
          revisionId: string
        ) =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.setMark(this.name, {
            revision: revision,
            color,
            revisionId,
          });
        },
      toggleReviseMark:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.toggleMark(this.name);
        },
      unsetReviseMark:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.unsetMark(this.name);
        },

      changeReviseMark:
        ({ retained, forgotten }: { retained: number; forgotten: number }) =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.extendMarkRange(this.name, {
            retained,
            forgotten,
          });
        },
      updateReviseMark:
        (revision: RevisionMarkOptions["HTMLAttributes"]["revision"]) =>
        ({ commands }: { commands: SingleCommands }) => {
          return commands.extendMarkRange(this.name, {
            revision: revision,
          });
        },
    } as any;
  },
});
