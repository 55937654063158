import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'sonner'
import { TemplateEnum } from '../../../utils/types'
import { createNote } from '../../api/createNote'
import { getNotesListQueryKey } from '../query-keys'

type CreateNotePayload = {
  title: string
  body: string
  userId: string
  parentId?: string
  level: number
  template: TemplateEnum
  description: string
  isContentNote: boolean
  icon: string
}

export const useCreateNote = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      title,
      body,
      userId,
      parentId,
      level,
      template,
      description,
      isContentNote,
      icon,
    }: CreateNotePayload) =>
      createNote({
        title,
        body,
        userId,
        parentId,
        level,
        template,
        description,
        isContentNote,
        icon,
      }),
    {
      onSuccess: (data) => {
        toast.success(`
            A new note "${data?.[0]?.title}" was created successfully.
          `)

        queryClient.refetchQueries(
          getNotesListQueryKey({ userId: data?.[0]?.user_id })
        )
      },
    }
  )
}
