import { Heading } from '@tiptap/extension-heading'
import { NodeType } from '@tiptap/pm/model'
import { mergeAttributes } from '@tiptap/react'

const convertLabelToId = ({ label }: { label: string }) => {
  if (!label) return null

  const cleanedTitle = label
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')
    .toLowerCase()
  return cleanedTitle
}

export const CustomHeading = Heading.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        default: null,
      },
    }
  },
  parseHTML() {
    return this.options.levels.map((level: number) => ({
      tag: `h${level}`,
      getAttrs: (node: any) => {
        return {
          level,
          id: node.getAttribute('id'),
        }
      },
    }))
  },
  renderHTML({ HTMLAttributes, node }) {
    return [
      `h${
        this.options.levels.includes(HTMLAttributes.level)
          ? HTMLAttributes.level
          : 3
      }`,
      mergeAttributes(HTMLAttributes, {
        id: convertLabelToId({ label: node.content.toJSON()?.[0]?.text }),
      }),
      0,
    ]
  },
  onUpdate(this: {
    name: string
    options: any
    storage: any
    editor: any
    type: NodeType
    parent: () => void
  }) {
    const { editor, type } = this
    const { doc } = editor.state

    doc.descendants((node: any, pos: number) => {
      if (node.type === type) {
        const label = node.textContent
        const id = convertLabelToId({ label })

        if (node.attrs.id !== id) {
          editor.view.dispatch(
            editor.view.state.tr.setNodeMarkup(pos, undefined, {
              ...node.attrs,
              id,
            })
          )
        }
      }
    })
  },
})
export default CustomHeading
