import { useMutation, useQueryClient } from "react-query";
import { useAuthStore } from "../../../store/auth";
import { deleteIntegration } from "../../api/deleteIntegration";
import { getIntegrationsQueryKey } from "../query-keys";

type IntegrationPayload = {
  integrationId: number;
};

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  const { userId } = useAuthStore();

  return useMutation(
    (payload: IntegrationPayload) => {
      return deleteIntegration({
        integrationId: payload.integrationId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: getIntegrationsQueryKey({ userId }),
        });
      },
    }
  );
};
