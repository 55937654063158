import create, { GetState, SetState } from "zustand";
import { devtools, persist } from "zustand/middleware";
import supabase from "../utils/supabase";

export interface AuthStore {
  userId: string | undefined;
  username: string | undefined;
  email: string | undefined;
  name: "";
  updateUser: ({
    userId,
    username,
    email,
  }: {
    userId: string | undefined;
    username: string | undefined;
    email: string | undefined;
  }) => void;
  hydrating: boolean;
  removeUser: () => void;
  hydrate: () => void;
}

let useStore: any = (set: SetState<AuthStore>, get: GetState<AuthStore>) => ({
  userId: "",
  email: "",
  username: "",
  name: "",
  hydrate: async () => {
    set({ hydrating: true });
    const userId = get().userId;

    if (!userId) {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (user) {
        set({
          userId: user.id,
          email: user.email,
        });
      }

      // fetches the username
      const { data, error } = await supabase
        .from("users")
        .select("username, name")
        .eq("id", user.id);

      if (!data?.length) {
        window.location.href = "/complete-profile";
      }

      // TODO: Revisit
      if (data && !error) {
        set({
          username: data?.[0].username,
          name: data?.[0].name,
        } as any);
      }
    }

    set({ hydrating: false });
  },
  updateUser: ({ userId, username, email }: any) =>
    set(() => ({ userId, username, email })),
  removeUser: () =>
    set({ userId: undefined, username: undefined, email: undefined }),
});

useStore = persist(useStore, { name: "auth" });
useStore = devtools(useStore);

export const useAuthStore = create<AuthStore>(useStore);
