import { Database } from '../../utils/database.types'
import supabase from '../../utils/supabase'

export const deleteNotes = async ({
  noteIds = [],
}: {
  noteIds: Array<Database['public']['Tables']['notes']['Row']['id']>
}) => {
  if (noteIds.length === 0) {
    return
  }

  const { data, error } = await supabase
    .from('notes')
    .delete()
    .in('id', noteIds)
    .select()

  if (error) {
    throw error
  }

  return data
}
