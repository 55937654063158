import { useColorMode, useColorModeValue } from "@chakra-ui/react";
import { ArrowBigUp, ChevronUp, Moon, Sun } from "lucide-react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useSupabaseSession } from "../pages-v2/hooks/queries/useSupabaseSession";
import { APP_NAME, BASE_URL } from "../pages-v2/NotesDashboardWrapper";
import { useAuthStore } from "../store/auth";
import { Button } from "../ui-components/Button";
import { LucideIcon } from "../ui-components/LucideIcon";
import { Separator } from "../ui-components/Separator";
import { ShortcutKey } from "../ui-components/Shortcut";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui-components/Tooltip";
import { APP_PAGE_HEIGHT } from "../utils/constants";
import Logo from "../utils/logo";
import { cn } from "../utils/utils";

export const RootLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col h-screen overflow-auto px-2 md:p-0">
      <Navbar />
      <div className={APP_PAGE_HEIGHT}>
        {children} <Footer />
      </div>
    </div>
  );
};

const Footer = () => {
  const history = useHistory();
  return (
    <section
      id="footer"
      className="border-t border-border-secondary-light dark:border-border-secondary-dark mx-auto p-2 md:px-4 dark:bg-transparent md:py-2.5 flex justify-around items-start"
    >
      <div className="flex flex-row gap-4 items-center">
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-x-1.5">
            <Logo /> {APP_NAME}
          </div>
        </div>
        <Separator orientation="vertical" className="h-4" />
        <div>
          <Button
            variant="link"
            className="hover:no-underline text-textColors-muted-light dark:text-textColors-muted-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
            onClick={() => {
              history.push("/about");
            }}
          >
            About
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-2 mt-1">
        <Button
          variant="outline"
          className="border-0 pointer-events-none text-textColors-muted-light dark:text-textColors-muted-dark hover:text-textColors-background-light dark:hover:text-textColors-background-dark"
        >
          © 2024 {APP_NAME}
        </Button>
      </div>
    </section>
  );
};

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const { userId } = useAuthStore();

  const { data: session, isLoading: isLoadingSession } = useSupabaseSession();

  const router = useHistory();

  const { toggleColorMode } = useColorMode();
  const mode = useColorModeValue("light", "dark");

  if (location.hash.startsWith("#access_token")) {
    return (
      <Redirect
        to={{
          pathname: "/complete-profile",
          hash: location.hash,
          state: {
            access_token: new URLSearchParams(location.hash).get(
              "#access_token"
            ),
          },
        }}
      />
    );
  }

  const hasSession = Boolean(userId) && Boolean(session?.session);

  if (hasSession) {
    return (
      <Redirect
        to={{
          pathname: `${BASE_URL}/notes`,
        }}
      />
    );
  }

  return (
    <div className="flex flex-row items-center justify-between border-b px-4 py-2 border-b-border-secondary-light dark:border-b-border-secondary-dark">
      <div className="flex flex-row items-center gap-x-1.5">
        <Logo onClick={() => router.push(`/`)} />
        <span className="text-textColors-background-light lowercase dark:text-textColors-background-dark font-medium tracking-tight">
          {APP_NAME}
        </span>
      </div>
      <div className="flex flex-row items-center space-x-4">
        <TooltipProvider>
          <Tooltip defaultOpen={false}>
            <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
              <Button
                variant="outline"
                size="icon"
                onClick={() => {
                  toggleColorMode();
                }}
              >
                {mode === "dark" ? (
                  <LucideIcon icon={Sun} size={14} />
                ) : (
                  <LucideIcon icon={Moon} size={14} />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent
              className={cn(
                "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                "text-textColors-background-light dark:text-textColors-background-dark",
                "border border-border-primary-light/80 dark:border-border-primary-dark/80"
              )}
            >
              Toggle theme
              <div className="flex items-center gap-1">
                <ShortcutKey tooltipLabel="ctrl">
                  <ChevronUp size={14} className="stroke-[#999999]" />
                </ShortcutKey>
                <ShortcutKey tooltipLabel="shift">
                  <ArrowBigUp size={14} className="stroke-[#999999]" />
                </ShortcutKey>
                <ShortcutKey tooltipLabel="T">T</ShortcutKey>
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <Separator orientation="vertical" className="h-4" />
        <Button
          variant="outline"
          loading={isLoadingSession}
          size="sm"
          onClick={() => {
            if (Boolean(userId) && Boolean(session?.session)) {
              history.push(`${BASE_URL}/`);
            } else {
              history.push("/login");
            }
          }}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
};
