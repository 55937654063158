import { FC, memo } from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/ai.json";

export const AiLottie: FC<{
  height?: number;
  width?: number;
}> = memo(({ height = 150, width = 150 }) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      height={height}
      width={width}
      isClickToPauseDisabled
    />
  );
});
