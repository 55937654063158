import { useCallback, useLayoutEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { useHistory, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { z } from "zod";
import CheatsheetViewer from "../../../editor-interfaces/tiptap/editorviewmode";
import { TNotesList } from "../../../pages-v2/api/fetchNotes";
import { useUpdateNote } from "../../../pages-v2/hooks/mutations/useUpdateNote";
import { TNotesResponseWithChildren } from "../../../pages-v2/hooks/queries/useNotesList";
import { noteCreationFormSchema } from "../../../pages-v2/notes-sidebar/NoteCreationForm";
import { useAuthStore } from "../../../store/auth";
import {
  AccessType,
  DEFAULT_EDITOR_CONTENT,
  DEFAULT_EDITOR_CONTENT_WITH_INDENT,
  ERROR_MESSAGE,
} from "../../../utils/constants";
import { injectTextInEditorContent } from "../../../utils/helpers";
import { Card, CardContent, CardHeader } from "../../Card";
import { DropdownMenuSeparator } from "../../DropdownMenu";
import { Prose } from "../Prose";
import { breakpointColumnsObj } from "./Cheatsheet";
import { CheatsheetEditorDialog } from "./CheatsheetEditor";
import { CheatsheetNoteActions } from "./CheatsheetNoteActions";

export const constructCheatsheetUrl = (
  url: string,
  search: string,
  id: string
) => {
  const urlString = url.split("#")[0];
  const searchString = search.split("#")[0];

  return id
    ? `${urlString}${searchString}#${id}`
    : `${urlString}${searchString}`;
};

export const CheatsheetList = ({
  note,
  layoutColumns,
}: {
  note: TNotesResponseWithChildren;
  layoutColumns: number;
}) => {
  const [editCheatsheet, setEditCheatsheet] = useState<TNotesList[0]>(null);
  const { mutateAsync: updateNote, isLoading: isUpdatingNote } = useUpdateNote({
    showToast: true,
  });

  const handleEditNote = useCallback(
    async ({
      values,
      body,
    }: {
      values: z.infer<typeof noteCreationFormSchema>;
      body: string;
    }) => {
      if (editCheatsheet) {
        await updateNote(
          {
            id: editCheatsheet.id,
            title: values.title,
            body,
          },
          {
            onError: () => {
              toast.error(ERROR_MESSAGE);
            },
          }
        );

        setEditCheatsheet(null);
      } else {
        setEditCheatsheet(null);

        toast.error(ERROR_MESSAGE);
      }
    },
    [editCheatsheet, updateNote]
  );

  const router = useHistory();
  const location = useLocation();

  // HACK: initially scroll to the heading
  useLayoutEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(
          `${location.hash.replaceAll("#", "")}`
        );

        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const isActiveNoteInUrl = (noteId: string) => {
    const activeNoteId = location.hash?.split("#")?.[1];

    return activeNoteId === noteId;
  };

  const { userId } = useAuthStore();
  const accessType =
    userId === note.users.id ? AccessType.EDIT : AccessType.READ;

  const fakeDescriptionNote: TNotesList = [
    {
      id: "fake_description",
      title: "Description",
      body: injectTextInEditorContent({ text: note.description }),
      template: "cheatsheet",
      created_at: "",
      updated_at: "",
      level: 0,
      parent_id: "",
      pinned: false,
      description: "",
      users: {
        id: "",
        name: "",
        avatar: "",
      },
      is_content_note: false,
      icon: "",
      status: "archived",
    },
  ];

  return (
    <>
      <Masonry
        className="cheatsheets"
        breakpointCols={{
          ...breakpointColumnsObj,
          default: layoutColumns,
        }}
        columnClassName="my-masonry-grid_column"
      >
        {(note.description ? fakeDescriptionNote : [])
          .concat(note.contentNotes)
          .map((contentNote) => {
            const isEmptyContent =
              contentNote.body === "{}" ||
              contentNote.body === DEFAULT_EDITOR_CONTENT ||
              contentNote.body === DEFAULT_EDITOR_CONTENT_WITH_INDENT ||
              !contentNote.body;

            return (
              <Card
                className={twMerge(
                  "rounded-md box-border",
                  accessType === AccessType.EDIT
                    ? "hover:border-border-primary-light dark:hover:border-border-primary-dark group mb-4"
                    : ""
                )}
                key={contentNote.id}
              >
                <CardHeader className="p-0 overflow-clip min-w-0">
                  <div
                    className={twMerge(
                      "py-2.5 rounded-t-md justify-between flex flex-row items-center gap-x-2 px-4 text-sm"
                    )}
                  >
                    <NavHashLink
                      to={constructCheatsheetUrl(
                        router.location.pathname,
                        router.location.search,
                        contentNote.id
                      )}
                      scroll={(el) => scrollWithOffset(el)}
                    >
                      <div
                        id={contentNote.id}
                        className={twMerge(
                          "truncate transition-colors",
                          isActiveNoteInUrl(contentNote.id) &&
                            `after:content-['#'] after:ml-2 after:text-textColors-muted-light dark:after:text-textColors-muted-dark`
                        )}
                      >
                        {contentNote.title}
                      </div>
                    </NavHashLink>
                    {accessType === AccessType.EDIT &&
                      contentNote.id !== "fake_description" && (
                        <div className="opacity-0 group-hover:opacity-100">
                          <CheatsheetNoteActions
                            note={contentNote}
                            onEdit={(note) => {
                              setEditCheatsheet(note);
                            }}
                            onDelete={() => {}}
                          />
                        </div>
                      )}
                  </div>
                </CardHeader>
                <DropdownMenuSeparator className="m-0 bg-border-secondary-light dark:bg-border-secondary-dark group-hover:bg-border-primary-light dark:group-hover:bg-border-primary-dark" />
                <CardContent className="p-4">
                  {isEmptyContent ? (
                    <div
                      className={twMerge(
                        "h-24 p-2 grid place-items-center justify-center"
                      )}
                    >
                      <div className="flex flex-col items-center text-center">
                        <p className="text-xs text-textColors-muted-light dark:text-textColors-muted-dark">
                          This cheatsheet is currently empty.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <Prose
                      classNames={twMerge(
                        "prose-pre:p-0 prose-pre:border-0 prose-pre:bg-transparent dark:prose-pre:bg-transparent prose-pre:m-0 max-w-none",
                        "prose-code:bg-transparent dark:prose-code:bg-transparent text-sm"
                      )}
                    >
                      <div className={twMerge("h-auto cheatsheet")}>
                        <CheatsheetViewer content={contentNote.body} />
                      </div>
                    </Prose>
                  )}
                </CardContent>
              </Card>
            );
          })}
      </Masonry>
      <>
        {Boolean(editCheatsheet) && (
          <CheatsheetEditorDialog
            title={`Edit`}
            cta={`Save`}
            defaultValues={{ title: editCheatsheet.title, description: `` }}
            open={Boolean(editCheatsheet)}
            note={editCheatsheet}
            setOpen={(open, note) => {
              if (open) {
                setEditCheatsheet(note);
              } else {
                setEditCheatsheet(null);
              }
            }}
            description={
              <>
                Edit <span>"{editCheatsheet.title}"</span>
              </>
            }
            onSubmit={({ values, body }) => handleEditNote({ values, body })}
            isLoading={isUpdatingNote}
          />
        )}
      </>
    </>
  );
};

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -80;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};
