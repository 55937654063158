import { WalletCards } from "lucide-react";
import { FC } from "react";
import { useAuthStore } from "../../store/auth";
import { Button } from "../../ui-components/Button";
import { Card, CardContent } from "../../ui-components/Card";
import { LucideIcon } from "../../ui-components/LucideIcon";
import { Progress } from "../../ui-components/Progress";
import { FREE_PLAN_MAX_NOTES } from "../../utils/constants";
import { Database } from "../../utils/database.types";
import {
  transformHeirarchicalNotesInFlatList,
  useNotesList,
} from "../hooks/queries/useNotesList";
import { useUser } from "../hooks/queries/useUser";
import { MAILBOX } from "../NotesDashboardWrapper";

export const PlanSettings: FC = () => {
  const { userId } = useAuthStore();
  const { data: [user] = [] } = useUser({ userId });

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h2 className="text-2xl font-bold tracking-tight">Plan Settings</h2>
        <p className="text-textColors-muted-light dark:text-textColors-muted-dark">
          View & manage your plan settings.
        </p>
      </div>
      <div className="flex flex-row flex-wrap gap-4">
        {user?.plan === "free" ? (
          <FreePlanCard planType={user?.plan} />
        ) : (
          <ProPlanCard planType={user?.plan} />
        )}
      </div>
      <p className="mt-8 w-10/12">
        We're currently running on a beta version of the app. If you wish to
        switch to another plan or a require a custom plan, please{" "}
        <a
          href={`mailto:${MAILBOX}`}
          className="border-b hover:text-textColors-background-light dark:hover:text-textColors-background-dark text-textColors-secondary-light dark:text-text-textColors-secondary-dark"
        >
          write to us.
        </a>
      </p>
    </div>
  );
};

const FreePlanCard = ({
  planType,
}: {
  planType: Database["public"]["Tables"]["users"]["Row"]["plan"];
}) => {
  const { userId } = useAuthStore();
  const { data: notesResponse = [] } = useNotesList({
    userId,
  });

  const allNotes = transformHeirarchicalNotesInFlatList({
    notesResponse,
  });

  return (
    <Card className="w-full text-center">
      <CardContent className="h-72 p-0">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6 divide-x h-full">
          <div className="flex flex-col justify-center gap-6 p-6">
            <div className="flex flex-col gap-4">
              <LucideIcon icon={WalletCards} size={40} />
              <span className="text-left">
                You are currently on the Free plan
              </span>
            </div>
            <div className="flex flex-col gap-2 items-start">
              <Progress
                value={(allNotes?.length / FREE_PLAN_MAX_NOTES) * 100}
                max={100}
                className="h-1.5"
              />
              <p className="text-sm">
                {allNotes?.length} / {FREE_PLAN_MAX_NOTES} notes
              </p>
            </div>
          </div>
          <div className="text-left flex flex-col justify-between gap-6 p-6">
            <div>
              <p className="font-medium">Plan Features</p>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-sm">✅ Access to all templates</p>
                <p className="text-sm">✅ Create unlimited revisions</p>
                <p className="text-sm">
                  ✅ Access to all note taking features and tools
                </p>
                <p className="text-sm">❌ Create unlimited number of notes</p>
              </div>
            </div>
            <Button>Current plan</Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const ProPlanCard = ({
  planType,
}: {
  planType: Database["public"]["Tables"]["users"]["Row"]["plan"];
}) => {
  return (
    <Card className="w-full text-center">
      <CardContent className="h-72 p-0">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-6 divide-x h-full">
          <div className="flex flex-col justify-center gap-6 p-6">
            <div className="flex flex-col gap-4">
              <LucideIcon icon={WalletCards} size={40} />
              <span className="text-left">
                You are currently on the Pro plan
              </span>
            </div>
          </div>
          <div className="text-left flex flex-col justify-between gap-6 p-6">
            <div>
              <p className="font-medium">Plan Features</p>
              <div className="flex flex-col gap-2 mt-4">
                <p className="text-sm">✅ Access to all templates</p>
                <p className="text-sm">✅ Create unlimited revisions</p>
                <p className="text-sm">
                  ✅ Access to all note taking features and tools
                </p>
                <p className="text-sm">✅ Create unlimited number of notes</p>
              </div>
            </div>
            <Button>Current plan</Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
