import { useMutation, useQueryClient } from "react-query";
import { useAuthStore } from "../../../store/auth";
import { Json } from "../../../utils/database.types";
import { updateIntegration } from "../../api/updateIntegration";
import { getIntegrationsQueryKey } from "../query-keys";

type IntegrationPayload = {
  metadata: Json;
  secret_key: string;
  id: number;
  active: boolean;
};

export const useUpdateIntegration = () => {
  const { userId } = useAuthStore();
  const queryClient = useQueryClient();

  return useMutation(
    (
      payload: Partial<IntegrationPayload> & {
        id: number;
      }
    ) => {
      return updateIntegration({
        user_id: userId,
        ...payload,
      });
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(getIntegrationsQueryKey({ userId }));
      },
    }
  );
};
